import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { useConfetti } from '@/shared/ui/confetti';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import promoImage from './image/modal-offer@2x.png';

import styles from './modal-offer.module.scss';

export const ModalOffer = ({ onClose, isOpen }: ModalCommonProps) => {
  const { Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { fireConfettiAtCenter } = useConfetti();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClose(false);
    fireConfettiAtCenter();
  }, [onClose]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="offer-modal">
      <img src={promoImage} alt={t('WelcomeBonus.Title')} className={styles.img} />
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('WelcomeBonus.Title')}</Title>
          <Description>
            <Trans i18nKey={'WelcomeBonus.Descr'} components={{ b: <b /> }} />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={handleClick}>
            {t('WelcomeBonus.Btn')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
