import cn from 'classnames';

import { Icon } from '@/shared/ui/icons';

import styles from './error-text.module.scss';

export enum IconVariant {
  triangle = 'warning-triangle',
  circle = 'warning-outline',
}

export type ErrorTextType = {
  iconVariant?: IconVariant;
  text: string;
  align?: 'left' | 'center' | 'right';
};

export const ErrorText = ({ iconVariant = IconVariant.triangle, text, align = 'left' }: ErrorTextType) => {
  return (
    <div className={cn([styles.error_text, styles[`error_text--${align}`]])}>
      <Icon iconName={iconVariant} className={styles[`error_text__svg--${iconVariant}`]} />
      <span className={styles.error_text__text}>{text}</span>
    </div>
  );
};
