import { Text, RadioButton } from '@wcode/ui-kit';
import cx from 'classnames';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import { useCurrentPlan, useMiningDurations, setMiningDuration } from '@/page/cart/store';
import {
  checkPurchase,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
  useGoogleMiningDurations,
} from '@/page/cart/store/cart-google-store';
import { formatHashrate } from '@/page/cart/ui/hashrate';
import { formatPrice } from '@/page/cart/ui/total-price/total-price.util';
import { MiningPoolPlan, MiningPoolPlanGoogle } from '@/shared/lib/models';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { formatBalance } from '@/shared/lib/utils/units';
import { AppDispatch, RootState } from '@/shared/store/types';
import { CurrencyIcon } from '@/shared/ui/currency-icon';
import { Icon } from '@/shared/ui/icons';

import { Card } from '../card';
import { CurrencySwitcherType } from '../currency-switcher/currency-switcher';

import s from './duration.module.scss';

const getPlansTest = (t: TFunction<'translation', undefined>) => [
  t('Buy_scr.12Months.Description'),
  t('Buy_scr.6Months.Description'),
  t('Buy_scr.3Months.Description'),
  t('Buy_scr.3Months.Description'),
  t('Buy_scr.3Months.Description'),
  t('Buy_scr.3Months.Description'),
  t('Buy_scr.3Months.Description'),
  t('Buy_scr.3Months.Description'),
];

interface DurationProps {
  activeTab: CurrencySwitcherType;
}

interface ExtendedMiningPoolPlanGoogle extends MiningPoolPlanGoogle {
  productId: string;
  offerToken: string;
  basePlanId: string;
  trial: boolean;
}

export const Duration = ({ activeTab }: DurationProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const activeMonth = useSelector((state: RootState) => state.googleCart.miningDuration);
  const activeMonthCrypto = useSelector((state: RootState) => state.cart.miningDuration);
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const plansCrypto = useSelector((state: RootState) => state.cart.data?.plans);
  const { cryptoBtc2userCurrencyRate } = useSelector((store: RootState) => store.rate);
  const { mining_currencies } = useSelector((store: RootState) => store.balance);
  const { userCurrency } = useCurrentPlanPriceInfo();

  const currentCurrencyObj = useMemo(
    () => mining_currencies?.find((cur) => cur?.symbol === userCurrency),
    [mining_currencies, userCurrency]
  );

  const getFormattedMiningPrice = useCallback(
    (plan: MiningPoolPlan | ExtendedMiningPoolPlanGoogle | undefined) => {
      if (!plan) return '0.00';

      if (activeTab === 'crypto') {
        const cryptoPlan = plan as MiningPoolPlan;
        const currentBtcPrice = formatPrice(parseFloat(cryptoPlan.total_price) / cryptoPlan.month);
        const btc2UserCurrencyPrice = formatPrice(Number(currentBtcPrice) * cryptoBtc2userCurrencyRate);
        return currentCurrencyObj ? formatBalance(btc2UserCurrencyPrice, currentCurrencyObj?.price_unit) : '0.00';
      } else {
        const googlePlan = plan as ExtendedMiningPoolPlanGoogle;
        return getLocalAmount(googlePlan.month_price, googlePlan.currentCurrency);
      }
    },
    [cryptoBtc2userCurrencyRate, currentCurrencyObj, activeTab]
  );

  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const currentPlanCrypto = useSelector(useCurrentPlan);
  const miningDurationList = useSelector(useGoogleMiningDurations);
  const miningDurationsCrypto = useSelector(useMiningDurations);

  const handleButtonClick = useCallback(
    (month: number, plan: MiningPoolPlan | ExtendedMiningPoolPlanGoogle) => () => {
      if (activeTab === 'crypto') {
        dispatch(setMiningDuration({ miningDuration: month }));
      } else {
        const googlePlan = plan as ExtendedMiningPoolPlanGoogle;
        dispatch(checkPurchase(availablePurchase?.some((i) => i?.productId === googlePlan.productId)));
        dispatch(setGoogleMiningDuration({ miningDuration: month }));
        dispatch(
          setGoogleInfo({
            productId: googlePlan.productId,
            offerToken: googlePlan.offerToken,
            basePlanId: googlePlan.basePlanId,
            trial: googlePlan.trial,
          })
        );
      }
    },
    [dispatch, availablePurchase, activeTab]
  );

  const currentPlans = activeTab === 'crypto' ? plansCrypto : plans;
  const currentDurations = activeTab === 'crypto' ? miningDurationsCrypto : miningDurationList;
  const currentActiveMonth = activeTab === 'crypto' ? activeMonthCrypto : activeMonth;
  const sortedDurations = currentDurations ? [...currentDurations].sort((a, b) => b - a) : [];
  const currentActivePlan = activeTab === 'crypto' ? currentPlanCrypto : currentPlan;

  return (
    <Card gap={'var(--number-016)'}>
      <div className={s.header}>
        <Text type="heading" size="h5" text={t('choose mining duration')} />
        <Text type="body" size="s" text={t('Buy_scr.Duration.Description')} color="secondary" />
      </div>

      <div className={s.plans}>
        {sortedDurations?.map((month, index) => {
          const plan = currentPlans?.find((i) => i.hps === currentActivePlan?.hps && i.month === month);
          const pickedPlan = plan ? currentActiveMonth === month : undefined;
          // const localPriceMarket = `${getFormattedMiningPrice(plan)} / ${t('mo.')}`;
          const localPriceMarket = t('Buy_scr.Price_month', { Price: getFormattedMiningPrice(plan) });
          const localPriceCripto = `${getLocalAmount(getFormattedMiningPrice(plan))} ${userCurrency}`;
          const localPrice = activeTab === 'crypto' ? localPriceCripto : localPriceMarket;

          if (!plan) {
            return null;
          }

          const bonusHsh = plan.hsh_reward;
          const bonusHps = plan.hps_bonus;

          return (
            <div
              key={month}
              className={cx(s.plan, { [s.active]: pickedPlan })}
              onClick={() => handleButtonClick(month, plan)()}
            >
              <div className={s.duration}>
                <Text type="heading" size="h6" text={`${plan.month} ${t('mo.')}`} className={s.price__header} />
                <div className={s.price}>
                  <Text type="label" size="m" text={localPrice} className={s.price__text} />
                </div>
              </div>

              <div className={s.content}>
                {bonusHsh && bonusHsh > 0 && (
                  <div className={s.bonus}>
                    <CurrencyIcon currency="HSH" />
                    <Text
                      type="label"
                      size="s"
                      text={t('Buy_scr.Bonus_HSH', { amount: bonusHsh })}
                      className={s.bonus__text}
                    />
                  </div>
                )}
                {bonusHps > 0 && (
                  <div className={s.bonus}>
                    <Icon iconName="gift" width={20} height={20} color={'var(--static-color-blue)'} />
                    <Text type="label" size="s" text={t('hps_increment')} className={s.bonus__text} />
                    <Text type="label" size="s" text={`+${formatHashrate(bonusHps)}`} className={s.bonus__text} />
                  </div>
                )}
                <Text type="body" size="xs" text={getPlansTest(t)[index]} />
                <div className={s.bottom}>
                  <div className={s.labels}>
                    {/*//TODO Best*/}
                    {/*text={t('Buy_scr.12Months.Best')}*/}
                    {index === 0 && (
                      <Text type="label" size="s" text={t('BEST')} className={cx(s.label__text, s.best)} />
                    )}
                    {plan.discount > 0 && (
                      <Text
                        type="label"
                        size="s"
                        text={`–${plan.discount}%`}
                        className={cx(s.label__text, s.discount)}
                      />
                    )}
                  </div>
                  <RadioButton
                    checked={currentActiveMonth === plan.month}
                    onChange={() => handleButtonClick(month, plan)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
