import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AutoWithdrawalPublic,
  GetRewardResponse,
  NftMining,
  NftMiningAddressInfo,
} from '@/shared/lib/backend/JsonRpcApi';

export interface TNftMinerState {
  mining: NftMining[];
  miners: NftMiningAddressInfo[];
  autoWithdrawals: AutoWithdrawalPublic[];
  limitAlertShowed: boolean;
  autoPayment: boolean;
  rewards: GetRewardResponse[];
}

const initialState: TNftMinerState = {
  mining: [],
  miners: [],
  autoWithdrawals: [],
  rewards: [],
  limitAlertShowed: false,
  autoPayment: false,
};

export const nftMinerSlice = createSlice({
  name: 'nft-miner',
  initialState,
  reducers: {
    setMining(
      state,
      {
        payload,
      }: PayloadAction<{
        mining: NftMining[];
      }>
    ) {
      state.mining = payload.mining;
    },
    setMiners(
      state,
      {
        payload,
      }: PayloadAction<{
        miners: NftMiningAddressInfo[];
      }>
    ) {
      if (payload.miners.length > 0) {
        const editMiners = payload.miners.map((items, idx) => ({ ...items, id: idx }));
        editMiners[0].tokens = payload.miners?.[0]?.tokens.map((i) => ({ ...i, isUpdate: false }));

        state.miners = editMiners;
      } else {
        state.miners = payload.miners;
      }
    },
    setRewards(state, { payload }: PayloadAction<GetRewardResponse[]>) {
      state.rewards = payload;
    },
    setLimitAlertShowed(state) {
      state.limitAlertShowed = true;
    },
    setAutoWithdrawals(
      state,
      {
        payload,
      }: PayloadAction<{
        autoWithdrawals: AutoWithdrawalPublic[];
      }>
    ) {
      state.autoWithdrawals = payload.autoWithdrawals;
    },
  },
});

export const nftMiner = nftMinerSlice.actions;

export default nftMinerSlice.reducer;
