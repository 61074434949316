import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useVerifyEmailNotification } from '@/features/menu/hooks';
import { MINING } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { useLogout } from '@/shared/hooks';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useHasSubscriptionIos } from '@/shared/lib/hooks/useHasSubscriptionIos';
import {
  APPLE_SUBSCRIPTIONS,
  BROWSER_SETTINGS,
  GOOGLE_SUBSCRIPTIONS,
  HISTORY_PATH,
  SWITCH_BALANCE_PATH,
  WITHDRAW_PATH,
} from '@/shared/lib/utils/links';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { Icon } from '@/shared/ui/icons';

import styles from './menu-account.module.scss';

type TMenuAccount = {
  email: string;
};

export const MenuAccount = ({ email }: TMenuAccount) => {
  const withdrawals = useSelector((store: RootState) => store.withdrawal.withdrawals);
  const { mining_currencies } = useSelector((store: RootState) => store.balance);
  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { id } = useSelector(selectUser);
  const { isTelegramMiniApp, isIos } = useDevice();
  const [toastId, setToastId] = useState<string | number>('');
  const isSubscriptionIos = useHasSubscriptionIos();

  useVerifyEmailNotification();
  const { logout } = useLogout();

  const handleVerifyEmailClick = () => {
    navigation(`${WITHDRAW_PATH}/${MINING}`);
  };

  useEffect(() => {
    toast.onChange((payload) => {
      if (payload.status === 'removed' && payload.id === toastId) {
        setCopy(false);
      }
    });
    return () => {
      toast.onChange(() => {});
    };
  }, [toastId]);

  const handleCopy = useCallback(() => {
    if (!copy) {
      window.navigator.clipboard.writeText(`${id}`);
      setToastId(toaster.success(t('Successfully_Copied'), { toastProps: { autoClose: 5000 } }));
      setCopy(true);
    }
  }, [copy, id, t]);

  const list = useMemo(
    () => [
      {
        isShow: !isTelegramMiniApp,
        component: (
          <MenuAccountItem
            link={BROWSER_SETTINGS}
            iconName="settings"
            title={t('Login settings')}
            dataTestId="link_browser_settings"
          />
        ),
      },
      {
        isShow: mining_currencies?.length > 1,
        component: (
          <Button
            fullWidth
            variant="text"
            size="xs"
            className={styles.list__link}
            as={Link}
            to={SWITCH_BALANCE_PATH}
            data-test-id="link_switch_balance"
          >
            <Icon iconName="sendMoney" width="20" height="20" className={styles.list__icon} />
            {isIos ? t('BlncCurrency') : t('Balance_currency.Main_title')}
          </Button>
        ),
      },
      {
        isShow: !isTelegramMiniApp && (!isIos || isSubscriptionIos),
        component: (
          <MenuAccountItem
            title={t('Dashboard.Subscriptions.Btn')}
            iconName="gear-credit-card"
            link={isIos ? APPLE_SUBSCRIPTIONS : GOOGLE_SUBSCRIPTIONS}
            dataTestId="link_manage_subscriptions"
          />
        ),
      },
      {
        isShow: withdrawals?.length > 0,
        component: (
          <Button
            fullWidth
            variant="text"
            size="xs"
            className={styles.list__link}
            as={Link}
            to={HISTORY_PATH}
            data-test-id="link_history_page"
          >
            <Icon iconName="history" width="20" height="20" className={styles.list__icon} />
            {t('Payment History')}
          </Button>
        ),
      },
      {
        isShow: !isTelegramMiniApp,
        component: (
          <Button
            fullWidth
            variant="text"
            size="xs"
            className={styles.list__link}
            onClick={logout}
            data-test-id="btn_exit"
          >
            <Icon iconName="logout" width="20" height="20" className={styles.list__icon} />
            {t('Logout')}
          </Button>
        ),
      },
    ],
    [isTelegramMiniApp, mining_currencies, withdrawals, isIos, logout, t]
  );

  const isShowList = useMemo(() => list?.some((item) => item.isShow), [list]);

  return (
    <div className={styles.root}>
      <div className={styles.account}>
        <div className={styles.account__title}>
          <Icon iconName="user" width="20" height="20" className={styles.account__icon} />
          {t('Account')} / {id}
          <Button
            color="blue"
            variant="text"
            size="xs"
            onClick={() => {
              handleCopy();
            }}
            className={styles.account__container_copy}
            data-test-id="btn_copy_id"
          >
            <Icon
              iconName={copy ? 'checked' : 'copy'}
              width={20}
              height={20}
              className={cn(styles.account__copy, copy && styles.account__copy_active)}
              style={{ pointerEvents: copy ? 'none' : 'auto' }}
            />
          </Button>
        </div>

        {isShowList && (
          <Button
            onClick={() => setOpen(!open)}
            color="blue"
            variant="text"
            size="xs"
            className={cn(styles.account__open, !open && styles.active)}
            data-test-id="btn_open_menu_account"
          >
            <Icon iconName="arrow-circle" width="20" height="20" />
          </Button>
        )}
      </div>

      {email ? (
        <div className={styles.email} onClick={() => isShowList && setOpen(!open)}>
          {email}
        </div>
      ) : (
        <div className={cn(styles.email, styles.email__required)} onClick={handleVerifyEmailClick}>
          <Icon iconName={'email'} width={20} height={20} />
          {t('VerifyYourEmail')}
        </div>
      )}
      <ul className={cn(styles.list, isShowList && !open && styles.active)}>
        <div className={styles.list__row}>
          {list.map(
            (item, id) =>
              item.isShow && (
                <li className={styles.list__item} key={id}>
                  {item.component}
                </li>
              )
          )}
        </div>
      </ul>
    </div>
  );
};

type MenuAccountItem = {
  title: string;
  iconName: string;
  link: string;
  dataTestId: string;
};

const MenuAccountItem = ({ title, iconName, link, dataTestId }: MenuAccountItem) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className={styles.list__link} data-test-id={dataTestId}>
      <Icon iconName={iconName} width={20} height={20} className={styles.list__icon} />
      <p>{title}</p>
    </a>
  );
};
