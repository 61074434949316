import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import styles from './switch-balance.module.scss';

export type SwitchBalanceProps = {
  userCurrency: string;
  handleClick: () => void;
};

export const SwitchBalance = ({ userCurrency, handleClick }: SwitchBalanceProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="outline"
      as={'a'}
      className={styles.wrapper}
      onClick={handleClick}
      data-test-id="btn_switch_balance"
    >
      <span className={styles.wrapper__title}>
        <Icon iconName="sendMoney" height={20} width={20} />
        {t('Balance_currency.Main_title')}:
      </span>
      <span className={styles.wrapper__btn}>
        {userCurrency} <Icon iconName="circleWithArrow" height={20} width={20} />
      </span>
    </Button>
  );
};
