import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { verifyReceiptIos } from '@/page/cart/store/cart-google-store';
import { apiClient } from '@/shared/lib/api/app-client';
import {
  Account,
  PoolMinerMessage,
  PoolMiningMessage,
  WalletsPendingPaymentRequest,
  WalletsWithdrawalsChangeRequest,
} from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { TOKENS } from '@/shared/lib/constants/storage-key';
import { getFcmUrl } from '@/shared/lib/constants/url';
import { useAuth } from '@/shared/lib/hooks/useAuth';
import { debug } from '@/shared/lib/utils/log';
import { refreshBalanceThunk, setIncreasedHsh } from '@/shared/store/balance/balance.slice';
import { poolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { selectRpcAuthed, selectRpcInitiated, selectRpcStatus, setRpcStatus } from '@/shared/store/rpc/rpc.slice';
import { AppDispatch } from '@/shared/store/types';
import { selectUser, userAction } from '@/shared/store/user/user.slice';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';

import { MetricsService } from '../metrics/services';

const useJsonRpc = () => {
  const dispatch: AppDispatch = useDispatch();
  const rpcInitiated = useSelector(selectRpcInitiated);
  const isAuthed = useSelector(selectRpcAuthed);
  const rpcStatus = useSelector(selectRpcStatus);
  const { access_token, refresh_token } = useSelector(selectUser);

  const { auth, refreshAuth } = useAuth();

  useEffect(() => {
    if (rpcStatus === 'open') {
      if (access_token) {
        debug('rpc auth', { open: rpc.open, auth: rpc.auth });
        auth(access_token, refresh_token).then();
      } else if (refresh_token) {
        refreshAuth(refresh_token).then();
      } else {
        const d = localStorage.getItem(TOKENS);
        debug('get token', d);
        let tokens;
        try {
          tokens = d && JSON.parse(d);
        } catch (e) {
          console.error(e);
          // skip
        }
        if (tokens?.access_token || tokens?.refresh_token) {
          dispatch(userAction.setToken(tokens));
        } else {
          dispatch(userAction.clearToken());
          dispatch(userAction.checkAuth());
          dispatch(userAction.setLoading(false));
        }
      }
    }
  }, [dispatch, auth, refreshAuth, rpcStatus, access_token, refresh_token]);

  useEffect(() => {
    if (window.ct && window.ct?.getToken) {
      const platformType = window?.ct?.platform === 'ios' ? 'IOS' : 'ANDROID';
      const getFcm = async () => {
        const fcmToken = await window.ct.getToken();
        if (rpcStatus === 'open') {
          if (isAuthed) {
            await rpc.transmit('fcm.register', { token: fcmToken, device_type: platformType });
          }
        } else if (!isAuthed) {
          const did = await window.ct?.getDid();
          const fcmRegisterUrl = getFcmUrl(did);
          await new apiClient().post(fcmRegisterUrl, {}, { token: fcmToken, device_type: platformType });
        }
      };
      getFcm().then();
    }
  }, [rpcStatus, isAuthed, dispatch]);

  useEffect(() => {
    const fetchTimestamp = () => {
      rpc
        .transmit('timestamp', {})
        .then((result) => {
          dispatch(userAction.setTimestamp(result));
          MetricsService.compareTimestamp(result);
        })
        .catch((error) => {
          console.error('Timestamp error:', error);
        });
    };

    // Call the function for the first load
    if (rpcStatus === 'open') {
      fetchTimestamp();
    }
    const intervalId = setInterval(() => {
      if (rpcStatus === 'open') {
        fetchTimestamp();
      }
    }, 600000); // 10 minutes

    return () => clearInterval(intervalId);
  }, [dispatch, rpcStatus]);

  useEffect(() => {
    if (isAuthed) {
      dispatch(refreshBalanceThunk())
        .unwrap()
        .catch((error) => {
          if (error.message !== 'User banned') return;
          dispatch(userAction.setBanned(true));
        });
    }
  }, [dispatch, isAuthed]);

  useEffect(() => {
    if (!rpcInitiated) return;
    const status = async () => {
      debug('rpc status', { status: rpc.status, open: rpc.open, auth: rpc.auth });
      dispatch(setRpcStatus(rpc.status));
    };
    const update_mining = async (mining: PoolMiningMessage) => {
      dispatch(poolMiner.updateUsedNow(mining));
    };
    const update_withdrawal = async (withdrawal: WalletsWithdrawalsChangeRequest) => {
      dispatch(withdrawalAction.updateWithdrawalFromEvent(withdrawal));
      await dispatch(refreshBalanceThunk());
    };
    const add_increased_hsh = async (pendingPayment: WalletsPendingPaymentRequest) => {
      await dispatch(refreshBalanceThunk());
      dispatch(setIncreasedHsh(pendingPayment.amount));
    };
    const update_miner = async (miner: PoolMinerMessage) => {
      if (miner?.created) {
        dispatch(poolMiner.setNewMiner(miner));
        dispatch(poolMiner.updateList(miner));
        if (window?.ct?.platform === 'ios') {
          dispatch(verifyReceiptIos());
        }
      }
    };

    const update_user = async (r: Account) => {
      dispatch(userAction.checkUserNew(r));
    };

    const reload = async () => {
      window.location.reload();
    };

    const visibilitychange = async () => {
      if (document.visibilityState === 'visible') {
        await rpc.reopen();
      }
    };

    const init = async () => {
      debug('rpc init ', { open: rpc.open, auth: rpc.auth });
      rpc.on('open', status);
      rpc.on('close', status);
      await status();
      rpc.onmethod('app.reload', reload);
      rpc.onmethod('mining.pool.miner', update_miner);
      rpc.onmethod('mining.pool.mining', update_mining);
      rpc.onmethod('user', update_user);
      rpc.onmethod('wallets.withdrawals.change', update_withdrawal);
      rpc.onmethod('wallets.pending_payment', add_increased_hsh);
      document.addEventListener('visibilitychange', visibilitychange);
      window.addEventListener('focus', visibilitychange);
    };
    init().then();
    return () => {
      rpc.offmethod('app.reload', reload);
      rpc.offmethod('mining.pool.mining', update_mining);
      rpc.offmethod('mining.pool.miner', update_miner);
      rpc.offmethod('user', update_user);
      rpc.off('open', status);
      rpc.off('close', status);
      rpc.offmethod('wallets.withdrawals.change', update_withdrawal);
      document.removeEventListener('visibilitychange', visibilitychange);
      window.removeEventListener('focus', visibilitychange);
    };
  }, [dispatch, rpcInitiated]);
};

export default useJsonRpc;
