import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DISABLE_MEGA_SALE_MODAL, MEGA_SALE_MODAL_CLOSED_TIMESTAMP } from '@/shared/lib/constants/storage-key';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { selectTutorial } from '@/shared/store/tutorial/tutorial.slice';

// Hook for Mega Sale logic
export const useMegaSaleModal = ({
  isMegaSaleShow,
  shouldAskRating,
  isTimeOver,
}: {
  isMegaSaleShow: boolean;
  shouldAskRating: boolean;
  isTimeOver: boolean;
}) => {
  const { openModal } = useModalSet();
  const tutorial = useSelector(selectTutorial);
  const { isOnboardingSeen } = useTutorialStorage();

  useEffect(() => {
    const storedMegaSaleValue = localStorage.getItem(DISABLE_MEGA_SALE_MODAL);
    const storedMegaSaleValueTimestamp = localStorage.getItem(MEGA_SALE_MODAL_CLOSED_TIMESTAMP);
    const storedMegaSaleValueTimestampNumber = Number(storedMegaSaleValueTimestamp);
    const currentTime = Date.now();
    const isMegaSaleDisable = storedMegaSaleValue === 'true';
    const timeDifference = currentTime - storedMegaSaleValueTimestampNumber;
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    if (
      !isTimeOver &&
      isMegaSaleShow &&
      !shouldAskRating &&
      !isMegaSaleDisable &&
      timeDifference >= oneDayInMillis &&
      isOnboardingSeen() &&
      tutorial?.step === -1
    ) {
      const timer = setTimeout(() => {
        openModal('MODAL_MEGA_SALE');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [openModal, isMegaSaleShow, shouldAskRating, isTimeOver, tutorial?.step]);
};
