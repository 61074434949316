import { checkE } from '@/shared/lib/utils/checE';

export const getDecimalPlaces = (number: number | string) => {
  const numStr = number.toString();

  if (numStr.includes('e')) {
    return Math.abs(Number(numStr.split('e')[1]));
  }
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }

  return 0;
};

const toFixedDown = (x: number, digits: number) => {
  if (getDecimalPlaces(x) <= digits) return x.toFixed(digits);

  const factor = 10 ** digits;
  return (Math.round(x * factor - 0.5) / factor).toFixed(digits);
};

export const formatBalance = (balance: string | number, unit: number | string) => {
  if (+balance < +unit) return unit.toString().replace(/1$/, '0');

  const decimalPlaces = getDecimalPlaces(unit);
  const number = Number(balance);

  return checkE(parseFloat(toFixedDown(number, decimalPlaces)));
};

// Validations for email and BTC address
export const isValidEmail = (value: string | undefined | null) => {
  if (!value) return false;
  const pattern = /^\S+@\S+\.\S+$/;
  return pattern.test(value);
};

export const isValidWithdrawalAddress = (value: string): boolean => {
  return isValidEmail(value) || value.length >= 20;
};
