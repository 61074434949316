import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { ErrorText } from '@/shared/ui/error-text/error-text';
import ModalLayout from '@/shared/ui/modal/modal';

import styles from '@/features/rate-us/ui/rate-us-modal/rate-us-modal.module.scss';

type FeedbackProps = {
  onClose: () => Promise<void>;
  onClick: (text: string) => void;
  loading: boolean;
};

export const Feedback = ({ onClose, onClick, loading }: FeedbackProps) => {
  const { Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = useCallback((value: string) => {
    setValue(value);
    setIsError(false);
  }, []);

  const handleClick = useCallback(() => {
    if (value.length < 10) return setIsError(true);

    onClick(value);
  }, [value]);

  return (
    <>
      <Close onClick={onClose} />
      <Container>
        <Content>
          <Title>{t('MTU.Modal.Low_Rating.Title')}</Title>
          <Description>{t('MTU.Modal.Low_Rating.Description')}</Description>
        </Content>
        <div className={styles.container}>
          <textarea
            rows={3}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            placeholder={t('MTU.Modal.Low_Rating.Placeholder')}
            className={styles.textarea}
          />
          {isError && <ErrorText text={'oops'} />}
        </div>
        <Footer>
          <Button
            color="blue"
            fullWidth
            borderRadius={false}
            onClick={handleClick}
            loading={loading}
            data-test-id="btn_confirm_modal"
          >
            {t('MTU.Modal.Low_Rating.Btn')}
          </Button>
        </Footer>
      </Container>
    </>
  );
};
