import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LAST_SHOWN_DATE } from '@/shared/lib/constants/storage-key';
import { CART_PATH } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import promoImage from './image/promo-image.png';

import styles from './modal-add-pool-miner.module.scss';

export const ModalAddPoolMiner = ({ onClose, isOpen }: ModalCommonProps) => {
  const { Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const storeRecording = useCallback(() => {
    const currentDate = new Date();
    localStorage.setItem(LAST_SHOWN_DATE, currentDate.toISOString());
  }, []);

  const handleClose = useCallback(() => {
    storeRecording();
    onClose(false);
  }, [onClose]);

  return (
    <ModalLayout onClose={() => handleClose()} isOpen={isOpen} id="add-pool-miner">
      <img src={promoImage} alt="promo-image" className={styles.img} />
      <Close onClick={() => handleClose()} />
      <Container>
        <Content>
          <Title>{t('First_modal.Title')}</Title>
          <Description>{t('First_modal.Description')}</Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            onClick={() => {
              storeRecording();
              navigate(CART_PATH);
            }}
          >
            <Icon iconName="plus" width="20" height="20" />
            {t('ADD POOL MINER')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
