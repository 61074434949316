import { apiClient } from '@/shared/lib/api/app-client';
import { JsonRpc } from '@/shared/lib/backend';
import { JsonRpcApi, JsonRpcApiMethods } from '@/shared/lib/backend/JsonRpcApi';
import type { Backend } from '@/shared/lib/backend/Rpc';
import { rpc } from '@/shared/lib/backend/Rpc';
import { DID, DID_LOADING } from '@/shared/lib/constants/storage-key';
import { getTelegramDeviceRegisterPathname } from '@/shared/lib/constants/url';
import { build, version } from '@/shared/lib/constants/version';
import { MetricsService } from '@/shared/lib/metrics';
import { error } from '@/shared/lib/utils/log';
import { watchLocalStorage } from '@/shared/lib/utils/storage';
import { InitDeviceTypes } from '@/shared/store/rpc/rpc.slice';

export function searchParameters({ isReactNativeApp, isTelegramMiniApp }: InitDeviceTypes) {
  let platform: string;
  let appId: string;
  let v: string = '1.0.0';
  const av: string = `${version}.${build}`;

  if (isReactNativeApp) {
    platform = window?.ct?.platform;
    appId = window?.ct?.bundleId;
    v = window?.ct?.appVersion || v;
  } else if (isTelegramMiniApp) {
    platform = 'telegram';
    appId = 'pool.telegram.ct';
    v = window?.Telegram?.WebApp?.version || v;
  } else {
    // else if for other platforms
    throw new Error('Unsupported platform configuration');
  }
  return { v, av, platform, appId };
}

async function watchDeviceId(): Promise<string> {
  const did = await watchLocalStorage(DID, 3000);
  return did ?? '';
}
type DidResponseType = {
  did: string;
};

export async function getDeviceId({
  isReactNativeApp,
  isTelegramMiniApp,
  setItem,
  getItem,
  decodedStartParam,
}: InitDeviceTypes) {
  let did: string | null = '';
  if (isReactNativeApp) {
    did = localStorage.getItem(DID);
    if (did === 'undefined') {
      localStorage.removeItem(DID);
      did = null;
    }
    while (!did) {
      const did_ts = localStorage.getItem(DID_LOADING);
      if (!did_ts || isNaN(parseInt(did_ts)) || Date.now() - parseInt(did_ts) > 10000) {
        localStorage.setItem(DID_LOADING, '' + Date.now());
        try {
          const response = await window.ct.getDid();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (response) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            did = '' + response;
            localStorage.setItem(DID, did);
          }
        } catch (e) {
          error('register device fail', e);
        } finally {
          localStorage.removeItem(DID_LOADING);
        }
      } else {
        try {
          did = await watchDeviceId();
        } catch (e) {
          error('register device fail', e);
        }
      }
    }
  } else if (isTelegramMiniApp) {
    let cloudDid;
    try {
      cloudDid = await getItem('did');
    } catch (e) {
      error('get cloud did fail', e);
    }
    try {
      if (!cloudDid) {
        did = localStorage.getItem(DID);
        if (did === 'undefined') {
          localStorage.removeItem(DID);
          did = null;
        }
        while (!did) {
          const did_ts = localStorage.getItem(DID_LOADING);
          if (!did_ts || isNaN(parseInt(did_ts)) || Date.now() - parseInt(did_ts) > 10000) {
            localStorage.setItem(DID_LOADING, '' + Date.now());
            try {
              const telegramDidUrl = getTelegramDeviceRegisterPathname();
              const requestBody = decodedStartParam ? { ref: decodedStartParam } : {};
              const didResponse = (await new apiClient().assuredPost(
                telegramDidUrl,
                {},
                requestBody
              )) as DidResponseType;
              if (didResponse && didResponse?.did) {
                localStorage.setItem(DID, didResponse?.did);
                did = didResponse?.did;
                try {
                  await setItem('did', didResponse?.did);
                } catch (e) {
                  error('cannot set cloud did', e);
                }
              }
            } catch (e) {
              error('register device fail', e);
            } finally {
              localStorage.removeItem(DID_LOADING);
            }
          } else {
            try {
              did = await watchDeviceId();
            } catch (e) {
              error('register device fail', e);
            }
          }
        }
      } else {
        localStorage.setItem(DID, cloudDid);
        did = cloudDid;
      }
    } catch (e) {
      error('register device fail', e);
    }
  }

  return did ?? '';
}

async function createBackend({
  isReactNativeApp,
  isTelegramMiniApp,
  setItem,
  getItem,
  decodedStartParam,
}: InitDeviceTypes): Promise<Backend> {
  JsonRpc.debug = false;

  const did = await getDeviceId({ isReactNativeApp, isTelegramMiniApp, setItem, getItem, decodedStartParam });
  const params = searchParameters({ isReactNativeApp, isTelegramMiniApp, setItem, getItem });

  let URL = `${window.app_config.baseWebsocketURL}?av=${params.av}&v=${params.v}&app_id=${params.appId}&platform=${params.platform}&bid=${did}`;

  if (isReactNativeApp) {
    const gaid = await window.ct.getGaid();
    const gsid = await window.ct.getGsid();
    URL += `&gaid=${gaid}&gsid=${gsid}`;
  }

  const lauid = await MetricsService.getLauid();
  if (lauid) {
    URL += `&lauid=${lauid}`;
  }

  const backend = new JsonRpc<JsonRpcApi, JsonRpcApiMethods>(URL);
  window.rpc = backend;

  return backend;
}

export async function init({
  isReactNativeApp,
  isTelegramMiniApp,
  setItem,
  getItem,
  decodedStartParam,
}: InitDeviceTypes): Promise<void> {
  const backend = await createBackend({ isReactNativeApp, isTelegramMiniApp, setItem, getItem, decodedStartParam });
  await rpc.init(backend);
}
