import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { TsPage } from '@/shared/lib/backend/JsonRpcApi';
import { useTimerWithDeadline } from '@/shared/lib/hooks/useTimerWithDeadline';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import './modal-push-colors.scss';
import styles from './modal-push.module.scss';

interface Props extends ModalCommonProps {
  modal: TsPage;
}

export const ModalPush = ({ onClose, isOpen, modal }: Props) => {
  const { currency: userCurrency } = useSelector(selectUser);
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { timeRemaining, isTimeOver } = useTimerWithDeadline(`${new Date((modal?.expired_at || 0) * 1000)}`);
  const { t } = useTranslation();

  useEffect(() => {
    if (modal?.expired_at && isTimeOver && isOpen) {
      onClose(false);
    }
  }, [modal?.expired_at, isTimeOver, isOpen]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="modal-push">
      {modal.meta?.header_variant_icon ? (
        <IconContainer>
          <img src={modal.image} alt={modal.title} />
        </IconContainer>
      ) : (
        <img src={modal.image} alt={modal.title} className={styles.img} />
      )}
      {modal?.expired_at && (
        <div className={styles.timer}>
          <Icon iconName="timer" width={16} height={16} />
          <span className={styles.timer__text}>
            {`${timeRemaining?.hours}:${timeRemaining?.minutes}:${timeRemaining?.seconds}`}
          </span>
        </div>
      )}
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>
            <Trans
              i18nKey={modal.title}
              components={{ br: <span className={styles.indent} />, span: <span />, b: <strong /> }}
            />
          </Title>
          <Description>
            <Trans
              i18nKey={modal.body}
              values={{ currency: userCurrency }}
              components={{ br: <span className={styles.indent} />, span: <span />, b: <strong /> }}
            />
          </Description>
        </Content>
        <Footer>
          {modal.actions?.map((action) => {
            return (
              <Button
                key={action.title}
                color="blue"
                fullWidth
                as={action.url.startsWith('/') ? NavLink : 'a'}
                href={action.url}
                to={action.url}
                data-test-id={`link_modal_push_${modal.id}`}
              >
                {action.icon?.url && <img src={action.icon?.url} alt={action.icon?.value} />}
                {action.title}
              </Button>
            );
          })}
          {modal.meta?.show_cancel_button && (
            <Button
              color="grey"
              fullWidth
              onClick={() => onClose(false)}
              data-test-id={`btn_close_modal_push_${modal.id}`}
            >
              {t('Select_Reviews.Btn2')}
            </Button>
          )}
        </Footer>
      </Container>
    </ModalLayout>
  );
};
