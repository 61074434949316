import { configureStore } from '@reduxjs/toolkit';

import { themeSlice, ThemeSliceState } from '@/features/theme/model/slice';
import { googleCartReducer, googleCartStore } from '@/page/cart/store/cart-google-store';
import { cartReducer, cartStore } from '@/page/cart/store/cart-store';
import contentReducer, { rootContent } from '@/shared/store/content/content.slice';
import { rootRpc, rpcSlice } from '@/shared/store/rpc/rpc.slice';
import { tutorialReducer, TutorialState } from '@/shared/store/tutorial/tutorial.slice';
import { utmSlice, utmStore } from '@/shared/store/utm/utm.slice';
import { rootWithdrawal, withdrawalReducer } from '@/shared/store/withdrawal/withdrawal.slice';

import balanceReducer, { rootBalanceType } from './balance/balance.slice';
import landingsReducer, { LandingsStore } from './landings/landings.slice';
import nftPromocodesReducer, { NftPromocodesStore } from './nft-promocodes/nft-promocodes.slice';
import nftMinerReducer, { TNftMinerState } from './ntf-miner/nft-miner.slice';
import poolMinerReducer, { TPoolMiner } from './pool-miner/pool-miner.slice';
import promocodesReducer, { PromocodesStore } from './promocodes/promocodes.slice';
import rateReducer, { RateState } from './rate/rate.slice';
import userReducer from './user/user.slice';
import type { rootUser } from './user/user.slice';

export interface Store {
  user: rootUser;
  balance: rootBalanceType;
  rate: RateState;
  pool_miner: TPoolMiner;
  nft_miner: TNftMinerState;
  content: rootContent;
  landings: LandingsStore;
  promocodes: PromocodesStore;
  nftPromocodes: NftPromocodesStore;
  cart: cartStore;
  googleCart: googleCartStore;
  withdrawal: rootWithdrawal;
  rpc: rootRpc;
  theme: ThemeSliceState;
  tutorial: TutorialState;
  utm: utmStore;
}

export const store = configureStore<Store>({
  reducer: {
    user: userReducer,
    balance: balanceReducer,
    rate: rateReducer,
    pool_miner: poolMinerReducer,
    nft_miner: nftMinerReducer,
    content: contentReducer,
    landings: landingsReducer,
    promocodes: promocodesReducer,
    nftPromocodes: nftPromocodesReducer,
    cart: cartReducer,
    googleCart: googleCartReducer,
    withdrawal: withdrawalReducer,
    rpc: rpcSlice.reducer,
    theme: themeSlice.reducer,
    tutorial: tutorialReducer,
    utm: utmSlice.reducer,
  },
});
