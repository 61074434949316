import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';

import {
  DailyTransactionsHistory,
  CurrencyBonus,
  ProjectBalance,
  WalletsCurrenciesResponse,
  WalletsPendingBalanceResponse,
} from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { checkE } from '@/shared/lib/utils/checE';

export type LinkAcc = {
  id: number | string;
  name: string | null;
  comment: string | null;
  is_banned: boolean;
  type: string | null;
  url: string;
};

export const refreshBalanceThunk = createAsyncThunk<
  { pool_balance: ProjectBalance; hsh_balance: ProjectBalance & WalletsPendingBalanceResponse },
  void,
  object
>('balance/refresh', async () => {
  const response = await rpc.transmit('wallets.balance', {});
  const pendingResponse = await rpc.transmit('wallets.pending.balance', {});

  const groups = ['pool_mobile', 'pool_ios', 'pool_telegram'];
  const group_balance = response.find((g) => groups.includes(g.group));

  const group_hsh_balance = response.find((g) => g.group == 'hsh') || {
    group: 'hsh',
    accrued: '0',
    total: '0',
  };
  const group_hsh_pending_balance = pendingResponse || {
    income: 0,
    outgoing: 0,
    earliest_payment_at: null,
  };

  return {
    pool_balance: group_balance || {
      group: 'pool_mobile',
      accrued: '0',
      total: '0',
    },
    hsh_balance: { ...group_hsh_balance, ...group_hsh_pending_balance },
  };
});

export type rootBalanceType = {
  pool_balance: {
    pool: string;
    balance: string;
    locked: string;
    total: string;
    exact_total: string;
    affiliate: string;
    withdraw: string;
  };
  hsh_balance: {
    total: number;
    current: number;
    accrued: number;
    income: number;
    outgoing: number;
    nextPayment: {
      days: number;
      hours: number;
      mins: number;
    };
    increased: number;
  };
  links: LinkAcc[];
  balance_request: boolean;
  balance_error: boolean;
  get_balance: boolean;
  is_mining: boolean;
  currencies: CurrencyBonus[] | [];
  mining_currencies: WalletsCurrenciesResponse | [];
  bonusBalance: number;
  bonusTransactions: DailyTransactionsHistory[] | [];
};

const initialState: rootBalanceType = {
  pool_balance: {
    pool: '0',
    affiliate: '0',
    total: '0',
    exact_total: '0',
    balance: '0',
    locked: '0',
    withdraw: '0',
  },
  hsh_balance: {
    total: 0,
    current: 0,
    accrued: 0,
    income: 0,
    outgoing: 0,
    nextPayment: {
      days: 0,
      hours: 0,
      mins: 0,
    },
    increased: 0,
  },
  links: [],
  balance_request: false,
  balance_error: false,
  get_balance: false,
  is_mining: true,
  mining_currencies: [],
  currencies: [],
  bonusBalance: 0,
  bonusTransactions: [],
};

// Update the slice to handle hshBalance
const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    setLinks(state, action) {
      state.links = action.payload.filter((item: { is_banned: boolean }) => !item.is_banned);
    },
    setMiningCurrencies(state, action) {
      state.mining_currencies = action.payload;
    },
    setBonusTransactions(state, action) {
      const items: DailyTransactionsHistory[] = action.payload?.items || [];

      // Summarize transactions by date
      const summarizedByDate = items.reduce<Record<string, DailyTransactionsHistory>>((acc, transaction) => {
        const date = transaction.date;
        const amount = new Decimal(transaction.amount || 0);

        if (acc[date]) {
          // Update existing entry for the date
          acc[date].amount = new Decimal(acc[date].amount).plus(amount).toNumber();
          acc[date].count += transaction.count; // Summarize counts
        } else {
          // Add new entry for the date
          acc[date] = { ...transaction, amount: amount.toNumber() };
        }

        return acc;
      }, {});

      // Convert summarized transactions to an array
      const summarizedTransactions = Object.values(summarizedByDate);

      // Calculate total balance
      const totalBalance = summarizedTransactions.reduce(
        (sum, transaction) => sum.plus(new Decimal(transaction.amount)),
        new Decimal(0)
      );

      // Update state
      state.bonusTransactions = summarizedTransactions;
      state.bonusBalance = totalBalance.toNumber(); // Convert Decimal to number for storage
    },
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setIncreasedHsh(state, action) {
      state.hsh_balance.increased += +action.payload;
    },
    setIsMining(state, action) {
      state.is_mining = action.payload;
    },
    clearBalance(state) {
      state.pool_balance = {
        pool: '0',
        affiliate: '0',
        total: '0',
        exact_total: '0',
        balance: '0',
        locked: '0',
        withdraw: '0',
      };
      state.hsh_balance = {
        total: 0,
        current: 0,
        accrued: 0,
        income: 0,
        outgoing: 0,
        nextPayment: {
          days: 0,
          hours: 0,
          mins: 0,
        },
        increased: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshBalanceThunk.pending, () => {})
      .addCase(refreshBalanceThunk.fulfilled, (state, action) => {
        const pool = action.payload?.pool_balance;
        const hsh = action.payload?.hsh_balance;

        state.pool_balance.pool = checkE(pool.accrued || '0.00');
        state.pool_balance.total = checkE(pool.total || '0.00');
        state.pool_balance.exact_total = pool.total || '0';

        let nextPaymentPending;

        if (hsh.earliest_payment_at) {
          const start = +dayjs();
          const end = +dayjs(hsh.earliest_payment_at * 1000);
          const diff = end - start;

          const days = Math.floor(diff / 86400000);
          const hours = Math.floor((diff - days * 86400000) / 3600000);
          const mins = (diff - days * 86400000 - hours * 3600000) / 60000;

          nextPaymentPending = { days, hours, mins: mins < 1 ? Math.ceil(mins) : Math.floor(mins) };
        }

        state.hsh_balance.total = +(hsh.total || 0) + +(hsh.income || 0);
        state.hsh_balance.current = +(hsh.total || 0);
        state.hsh_balance.accrued = +(hsh.accrued || 0) + +(hsh.income || 0);
        state.hsh_balance.income = +(hsh.income || 0);
        state.hsh_balance.outgoing = +(hsh.outgoing || 0);
        state.hsh_balance.nextPayment = nextPaymentPending || {
          days: 0,
          hours: 0,
          mins: 0,
        };
      })
      .addCase(refreshBalanceThunk.rejected, () => {});
  },
});

export const {
  setLinks,
  clearBalance,
  setCurrencies,
  setMiningCurrencies,
  setBonusTransactions,
  setIsMining,
  setIncreasedHsh,
} = balanceSlice.actions;

export default balanceSlice.reducer;
