import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentUtmSource } from '@/features/crypto-tab-products/hooks/use-current-utm-source';
import { updateUtmSource, UtmPlace } from '@/features/crypto-tab-products/utils/update-utm-source';
import { selectCurrentTheme } from '@/features/theme';
import { AppTheme } from '@/features/theme/model/slice';
import {
  CT_FARM,
  CT_FARM_PRO,
  CT_LITE,
  CT_MAX,
  CT_PRO,
  CT_TAGS,
  CT_VPN,
  PLAY_GOOGLE_NC_WALLET,
} from '@/shared/lib/utils/links';
import ncwalletWhiteIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet-white.svg';
import ncwalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg';

import {
  ctBrowserLightSrc,
  ctBrowserLiteIconSrc,
  ctBrowserMaxIconSrc,
  ctBrowserMaxSrc,
  ctBrowserProIconSrc,
  ctBrowserProSrc,
  ctFarmIconSrc,
  ctFarmProIconSrc,
  ctFarmProSrc,
  ctFarmSrc,
  ctTagsIconSrc,
  ctTagsSrc,
  ctVpnIconSrc,
  ctVpnSrc,
  ncWalletSrc,
} from '../assets';

export interface CTProduct {
  name: string;
  dataTestId: string;
  description: string;
  promo: string | null;
  image: string;
  icon: string;
  link: string;
}

export const useProductsList = () => {
  const theme = useSelector(selectCurrentTheme);
  const currentUtmSource = useCurrentUtmSource();

  const products: CTProduct[] = useMemo(
    () => [
      {
        name: 'Products.CTB_Lite.Title',
        dataTestId: 'link_ctb_lite',
        description: 'Products.CTB_Lite.Description',
        promo: null,
        image: ctBrowserLightSrc,
        icon: ctBrowserLiteIconSrc,
        link: updateUtmSource({ link: CT_LITE, utmSource: currentUtmSource }),
      },
      {
        name: 'Products.CTB_Pro.Title',
        dataTestId: 'link_ctb_pro',
        description: 'Products.CTB_Pro.Description',
        promo: null,
        image: ctBrowserProSrc,
        icon: ctBrowserProIconSrc,
        link: updateUtmSource({ link: CT_PRO, utmSource: currentUtmSource }),
      },
      {
        name: 'Products.CTB_Max.Title',
        dataTestId: 'link_ctb_max',
        description: 'Products.CTB_Max.Description',
        promo: null,
        image: ctBrowserMaxSrc,
        icon: ctBrowserMaxIconSrc,
        link: updateUtmSource({ link: CT_MAX, utmSource: currentUtmSource }),
      },
      {
        name: 'Products.NCW.Title',
        dataTestId: 'link_ncw',
        description: 'Products.NCW.Description',
        promo: null,
        image: ncWalletSrc,
        icon: theme === AppTheme.LIGHT ? ncwalletIcon : ncwalletWhiteIcon,
        link: updateUtmSource({
          link: PLAY_GOOGLE_NC_WALLET,
          utmSource: currentUtmSource,
          utmPlace: UtmPlace.Link,
        }),
      },
      {
        name: 'Products.CTFarm.Title',
        dataTestId: 'link_ct_farm',
        description: 'Products.CTFarm.Description',
        promo: null,
        image: ctFarmSrc,
        icon: ctFarmIconSrc,
        link: updateUtmSource({ link: CT_FARM, utmSource: currentUtmSource, utmPlace: UtmPlace.Link }),
      },
      {
        name: 'Products.CTFarmPro.Title',
        dataTestId: 'link_ct_farm_pro',
        description: 'Products.CTFarmPro.Description',
        promo: null,
        image: ctFarmProSrc,
        icon: ctFarmProIconSrc,
        link: updateUtmSource({ link: CT_FARM_PRO, utmSource: currentUtmSource, utmPlace: UtmPlace.Link }),
      },
      {
        name: 'Products.CTVPN.Title',
        dataTestId: 'link_ct_vpn',
        description: 'Products.CTVPN.Description',
        promo: null,
        image: ctVpnSrc,
        icon: ctVpnIconSrc,
        link: updateUtmSource({ link: CT_VPN, utmSource: currentUtmSource, utmPlace: UtmPlace.Link }),
      },
      {
        name: 'Products.CTags.Title',
        dataTestId: 'link_ct_tags',
        description: 'Products.CTags.Description',
        promo: null,
        image: ctTagsSrc,
        icon: ctTagsIconSrc,
        link: updateUtmSource({ link: CT_TAGS, utmSource: currentUtmSource, utmPlace: UtmPlace.Referrer }),
      },
    ],
    [theme, currentUtmSource]
  );

  return {
    products,
  };
};
