import { Button, Text } from '@wcode/ui-kit';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import { useCurrentPlan } from '@/page/cart/store';
import {
  checkPurchase,
  purchaseSubscription,
  useGoogleCurrentPlan,
  useGoogleAvailablePurchase,
  useGoogleIsAvailable,
  useGooglePurchaseStatus,
} from '@/page/cart/store/cart-google-store';
import { formatHashrate } from '@/page/cart/ui/hashrate';
import { useMakeOrderMutation } from '@/page/cart/ui/make-order-button/use-make-order-mutation';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { MiningPoolPlan, MiningPoolPlanGoogle } from '@/shared/lib/models';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { CurrencySwitcherType } from '@/shared/ui/kit/currency-switcher/currency-switcher';

import { Card } from '../card';

import s from './order-details.module.scss';

interface OrderDetailsProps {
  activeTab: CurrencySwitcherType;
}

export const OrderDetails = ({ activeTab }: OrderDetailsProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModalSet();
  const isCryptoTab = activeTab === 'crypto';
  const { currentPriceFiat, userCurrency, fullSavePrice, formattedPrice, formattedPriceRegular } =
    useCurrentPlanPriceInfo();
  const { handleClick } = useMakeOrderMutation();

  const miningDurationGoogle = useSelector((state: RootState) => state.googleCart.miningDuration);
  const miningDurationCrypto = useSelector((state: RootState) => state.cart.miningDuration);
  const miningDuration = isCryptoTab ? miningDurationCrypto : miningDurationGoogle;

  const currentPlanGoogle = useSelector(useGoogleCurrentPlan);
  const currentPlanCrypto = useSelector(useCurrentPlan) as MiningPoolPlan;
  const currentPlan = isCryptoTab ? currentPlanCrypto : currentPlanGoogle;
  const status = useSelector(useGooglePurchaseStatus);
  const isAvailable = useSelector(useGoogleIsAvailable);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const activeId = useSelector((state: RootState) => state.googleCart?.productId);

  const { defaultFiatCurrencyApproximately } = useSelector(selectUser);

  const isLoading = status === 'loading';

  useEffect(() => {
    dispatch(checkPurchase(availablePurchase?.some((i) => i?.productId === activeId)));
  }, [activeId, availablePurchase, dispatch]);

  const openHshModal = useCallback(() => {
    openModal('MODAl_INFO_BONUS_HSH', { isServices: true });
  }, [openModal]);

  if (!currentPlan) {
    return null;
  }

  let regularPrice: string, finalPrice: string, savedAmount: string, discount: number;
  const currency = defaultFiatCurrencyApproximately?.code;

  if (isCryptoTab) {
    // Crypto plan
    finalPrice = `${t('BUY')} / ${getLocalAmount(formattedPrice)} ${userCurrency}`;
    discount = currentPlan.discount;
    regularPrice = `${getLocalAmount(+formattedPriceRegular)} ${userCurrency}`;
    savedAmount = `${getLocalAmount(+fullSavePrice)} ${userCurrency}`;
  } else {
    // Google plan
    const googlePlan = currentPlan as MiningPoolPlanGoogle;
    regularPrice = getLocalAmount(googlePlan.old_price || '0', currency);
    finalPrice = isAvailable
      ? t('workerDetails.activated')
      : `${t('Subscribe')} ${getLocalAmount(googlePlan.total_price, googlePlan.currentCurrency)}`;
    discount = googlePlan.discount;
    savedAmount = `${getLocalAmount(googlePlan?.save_price.toString(), currency)}`;
  }

  const bonusHsh = currentPlan.hsh_reward;
  const bonusHps = currentPlan.hps_bonus;

  const onSubscribe = () => {
    if (isCryptoTab) {
      handleClick();
    } else {
      dispatch(purchaseSubscription({ openModal, navigate }));
    }
  };

  return (
    <Card gap={'var(--number-016)'}>
      <Text type="heading" size="h5" text={t('OrdDetails.Title')} />
      <div className={s.list}>
        <div className={s.list__item}>
          <Text type="label" size="s" text={t('Regular Price')} />
          <Text type="label" size="m" text={regularPrice} />
        </div>
        {isCryptoTab ? (
          <div className={s.list__item}>
            <div></div>
            <Text
              type="label"
              size="m"
              text={`~ ${getLocalAmount(+currentPriceFiat, defaultFiatCurrencyApproximately?.code)}`}
            />
          </div>
        ) : null}
        {discount > 0 && (
          <div className={s.list__item}>
            <Text type="label" size="s" text={t('N.YourDiscount.Title')} />
            <Text type="label" size="m" text={`-${discount}%`} className={s.text__green} />
          </div>
        )}
        {savedAmount ? (
          <div className={s.list__item}>
            <Text type="label" size="s" text={t('Purchase_scr.Total_Discount.Title')} />
            <Text type="label" size="m" text={savedAmount} className={s.text__green} />
          </div>
        ) : null}
        {bonusHsh && bonusHsh > 0 ? (
          <div className={s.list__item}>
            <Text type="label" size="s" text={`${t('BB_Hsh.Bonus')} HSH`} />
            <Text
              type="label"
              size="m"
              text={`+ ${getLocalAmount(bonusHsh.toString())}`}
              className={s.text__yellow}
              onClick={openHshModal}
            />
          </div>
        ) : null}
        {bonusHps && bonusHps > 0 ? (
          <div className={s.list__item}>
            <Text type="label" size="s" text={t('hps_increment')} />
            <Text type="label" size="m" text={`+ ${formatHashrate(bonusHps)}`} className={s.text__blue} />
          </div>
        ) : null}
      </div>
      <div className={s.button__block}>
        <Button
          size="l"
          variant="primary"
          disabled={isLoading || (!isCryptoTab && isAvailable)}
          loading={isLoading}
          label={<Text type="label" size="m" text={finalPrice} className={s.button__text} />}
          onClick={onSubscribe}
          className={s.button}
        />
        <Text
          type="label"
          size="xs"
          text={t('Subscription will be automatically renewed and charged every {{duration}} mo.', {
            duration: miningDuration,
          })}
          color="secondary"
          className={s.description}
        />
      </div>
    </Card>
  );
};
