import cn from 'classnames';
import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { formatHashrate } from '@/page/cart/ui/hashrate';
import useMobileDetect from '@/shared/lib/hooks/useMobileDetect';
import { formatDate } from '@/shared/lib/utils/getFormatDate';
import { getMonths } from '@/shared/lib/utils/getMonths';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { Icon } from '@/shared/ui/icons';

import { useScrollIssueFix } from '../hooks';

import styles from '../mining-block.module.scss';

const PoolMinersListComponent = ({ miners }: { miners: TMiner[] }) => {
  const { isMobile } = useMobileDetect();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useScrollIssueFix(containerRef);

  return (
    <div className={cn(styles.flexContainer, styles.flexContainer_last)} ref={containerRef}>
      {miners?.map((item: TMiner, id) => {
        const duration = getMonths(item.create_time, item.end_time);
        return (
          <div
            className={styles.flexTable}
            key={item.id}
            data-test-id={`miner${id === miners.length - 1 ? '_last' : ''}`}
          >
            <p className={cn(styles.flexRow, styles.minerItem)}>
              <Icon iconName="poolMining" className={styles.flexTable__icon} width={64} height={40} />{' '}
              {/* Here we await duration from back */}
              {formatHashrate(item.hps, true)}{' '}
              <span style={{ fontWeight: '400', color: '#757575' }}>
                {`/ ${duration} `}
                <span style={{ textTransform: 'capitalize' }}>{t('mo.')}</span>
              </span>
            </p>
            <p className={cn(styles.flexRow, styles.flexRowSecond, styles.flexTable__date)}>
              {isMobile() ? formatDate(item.end_time, { month: 'short' }) : formatDate(item.end_time)}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export const PoolMinersList = memo(PoolMinersListComponent);
