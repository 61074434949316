import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

export const WaitTransitionModal = ({ onClose, isOpen }: ModalCommonProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="wait-transition-modal">
      <IconContainer>
        <Icon iconName="attention" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Modal.ConfirmWithdr')}</Title>
          <Description>{t('Modal.ConfirmWithdrDescr')}</Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            borderRadius={false}
            fullWidth
            onClick={() => onClose(false)}
            data-test-id="btn_confirm_modal"
          >
            {t('BTN_OK')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
