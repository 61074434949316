import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

interface ModalSpeedUpWithCryptoProps extends ModalCommonProps {
  onSwitchToCrypto: () => void;
}

export const ModalSpeedUpWithCrypto = ({ onClose, isOpen, onSwitchToCrypto }: ModalSpeedUpWithCryptoProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  const onSwitchToCryptoClick = useCallback(() => {
    onSwitchToCrypto();
    onClose(false);
  }, [onClose, onSwitchToCrypto]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="go-to-payment-modal">
      <IconContainer>
        <Icon iconName="boosts-info" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t(' Speed up with Crypto')}</Title>
          <Description>
            {t('Switch to crypto payments to increase your mining speed. Enjoy faster mining and bigger rewards.')}
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={onSwitchToCryptoClick}>
            {t('Switch to Crypto')}
          </Button>
          <Button color="grey" fullWidth onClick={() => onClose(false)}>
            {t('Cancel')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
