import { useState, useEffect } from 'react';

export interface TimeRemaining {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}
//deadline like 2024-11-17T23:59:00Z
export const useTimerWithDeadline = (deadline: string) => {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining | null>(null);
  const [showColon, setShowColon] = useState(true);
  const [isTimeOver, setIsTimeOver] = useState(false);

  useEffect(() => {
    const deadlineTime = new Date(deadline).getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const distance = deadlineTime - now;

      if (distance < 0) {
        setIsTimeOver(true);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        .toString()
        .padStart(2, '0');
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, '0');
      const sec = Math.ceil((distance % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, '0');

      const seconds = sec === '60' ? '00' : sec;

      setTimeRemaining({ days, hours, minutes, seconds });
      if (isTimeOver) setIsTimeOver(false);
    };

    updateTimer(); // Initial calculation before interval starts

    const timerInterval = setInterval(updateTimer, 1000);
    const colonInterval = setInterval(() => setShowColon((prev) => !prev), 1000);

    return () => {
      clearInterval(timerInterval);
      clearInterval(colonInterval);
    };
  }, [deadline]);

  return { timeRemaining, showColon, isTimeOver };
};
