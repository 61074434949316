import React from 'react';

import { ProductCard, useProductsList } from '@/features/crypto-tab-products';

import styles from './products-list.module.scss';

export const ProductsList = () => {
  const { products } = useProductsList();

  return (
    <div className={styles.container}>
      {products.map((product) => (
        <ProductCard product={product} key={product.name} shadow />
      ))}
    </div>
  );
};
