import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useBonusBanner } from '@/page/cart/hooks/use-bonus-banner';
import { setMiningDuration, useCurrentPlan, useMiningDurations } from '@/page/cart/store';
import { HpsAlert } from '@/page/cart/ui/bonus-alerts/hps-alert';
import { HshAlert } from '@/page/cart/ui/bonus-alerts/hsh-alert';
import { BonusNames, usePlansBonusName } from '@/shared/hooks/use-plans-bonus-name';
import { Tag } from '@/shared/lib/constants/tags';
import { MiningPoolPlan } from '@/shared/lib/models';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import HshFebX2 from '@/shared/ui/assets/unsupport-icons/hsh-feb-x2.svg?react';
import HshFeb from '@/shared/ui/assets/unsupport-icons/hsh-feb.svg?react';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { Typography } from '@/shared/ui/typography';

import { formatHashrate } from '../hashrate';

import s from './mining-duration.module.scss';

type MiningDurationProps = {
  getFormatedMiningPrice: (plan: MiningPoolPlan | undefined) => string;
  userCurrency: string;
};

export const MiningDuration = ({ getFormatedMiningPrice, userCurrency }: MiningDurationProps) => {
  const activeMonth = useSelector((state: RootState) => state.cart.miningDuration);
  const plans = useSelector((state: RootState) => state.cart.data?.plans);
  const miningDurations = useSelector(useMiningDurations);
  const currentPlan = useSelector(useCurrentPlan);
  const { tags } = useSelector(selectUser);
  const plansBonusName = usePlansBonusName();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { monthsBonus, isShowBanner } = useBonusBanner({
    plans,
    sliceTo: miningDurations?.length,
    currentPlanMonth: currentPlan?.month,
  });

  const handleButtonClick = useCallback(
    (month: number) => () => dispatch(setMiningDuration({ miningDuration: month })),
    [dispatch]
  );

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h5" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Buy_scr.Price_month')}</p>
      </div>

      <div className={s.buttonsContainer}>
        {miningDurations &&
          miningDurations.map((month, i) => {
            const discount = plans && (plans.find((i) => i.month === month) as MiningPoolPlan)?.discount;
            const plan = plans && plans.find((i) => i.hps === currentPlan?.hps && i.month === month);
            const formattedPrice = getFormatedMiningPrice(plan);
            const pickedPlan = plan ? activeMonth === month : undefined;
            const isDoubleHpsIcon = !plan?.hsh_reward && plan?.tags?.includes(Tag.BONUS_TAG_20) && plan?.hps_bonus;
            const isSomeHasDoubleHpsBonus = plans?.some((plan) => plan?.tags?.includes(Tag.BONUS_TAG_20));
            const localPrice = `${getLocalAmount(formattedPrice)} ${userCurrency}`;

            if (!plan) {
              return <></>;
            }

            return (
              <Button
                borderRadius={false}
                fullWidth
                key={month}
                variant="outline"
                onClick={handleButtonClick(month)}
                color="black"
                className={cn(
                  s.button,
                  activeMonth !== month && s.inactive,
                  localPrice.length >= 20 && s.button_medium,
                  localPrice.length >= 23 && s.button_small,
                  !pickedPlan && i === miningDurations.length - 1 && s.button_indent
                )}
                data-test-id={`btn_crypto_telegram_plan_${month}`}
              >
                <div className={s.button__container}>
                  <span className={s.button__text}>
                    {month} {t('mo.')}
                  </span>
                  <div
                    className={cn(
                      s.button__container_discount,
                      isSomeHasDoubleHpsBonus && s.button__container_discount_wide,
                      isDoubleHpsIcon && s.button__container_discount_background
                    )}
                  >
                    {discount && discount > 1 ? <span className={s.button__discount}>-{discount}%</span> : null}
                    {plan?.hsh_reward && (
                      <>
                        {tags?.includes(Tag.FEB_25) ? (
                          <>
                            {plan?.tags?.includes(Tag.BONUS_TAG_20) ? (
                              <HshFebX2 width={24} height={24} className={s.button__icon} />
                            ) : (
                              <HshFeb width={24} height={24} className={s.button__icon} />
                            )}
                          </>
                        ) : (
                          <>
                            {plan?.tags?.includes(Tag.BONUS_TAG_20) && plan?.hsh_reward && (
                              <Icon iconName="gift-yellow" width={24} height={24} className={s.button__icon} />
                            )}
                          </>
                        )}
                      </>
                    )}
                    {isDoubleHpsIcon && (
                      <Icon iconName="white-gift-x2" width={15} height={16} className={s.button__icon} />
                    )}
                  </div>
                  <span className={s.button__plan} data-test-id="text_local_price_for_month">
                    {localPrice}
                  </span>

                  {!pickedPlan && i === miningDurations.length - 1 && (
                    <span className={cn(s.button__tag, plan?.tags?.includes(Tag.BONUS_TAG_20) && s.button__tagPurple)}>
                      {t('Buy_scr.Profitable')}
                    </span>
                  )}
                </div>
                {activeMonth === month &&
                  (plan?.hsh_reward || plan?.hps_bonus) &&
                  [Tag.BONUS_TAG_20, Tag.BONUS_TAG].some((tag) => plan?.tags?.includes(tag)) && (
                    <div className={s.button__bonus}>
                      <Icon iconName="gift" width={20} height={20} />
                      {plan?.hsh_reward ? (
                        <>
                          {t('BB_Hsh.Bonus')}:
                          <span className={s.button__right} data-test-id="text_mining_bonus">
                            +{getLocalAmount(plan?.hsh_reward)} HSH{' '}
                            {plan?.tags?.includes(Tag.BONUS_TAG_20) && !tags?.includes(Tag.FEB_25) && '(x2)'}
                          </span>
                        </>
                      ) : (
                        <>
                          {t('hps_increment')}:
                          <span className={s.button__right} data-test-id="text_mining_bonus">
                            +{formatHashrate(plan?.hps_bonus)} {plan?.tags?.includes(Tag.BONUS_TAG_20) && '(x2)'}
                          </span>
                        </>
                      )}
                    </div>
                  )}
              </Button>
            );
          })}
      </div>
      {plansBonusName === BonusNames.HSH && isShowBanner ? <HshAlert months={monthsBonus} /> : null}
      {plansBonusName === BonusNames.HPS && isShowBanner ? <HpsAlert months={monthsBonus} /> : null}
    </div>
  );
};
