import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import { useCurrentPlan } from '@/page/cart/store';
import { TotalPrice } from '@/page/cart/ui/total-price';
import { formatPrice } from '@/page/cart/ui/total-price/total-price.util';
import { MiningPoolPlan } from '@/shared/lib/models';
import { formatBalance } from '@/shared/lib/utils/units';
import { RootState } from '@/shared/store/types';

import { Hashrate } from '../hashrate';
import { MiningDuration } from '../mining-duration';

import s from './add-miner.module.scss';

export const AddMiner = () => {
  const currentPlan = useSelector(useCurrentPlan);
  const { cryptoBtc2userCurrencyRate } = useSelector((store: RootState) => store.rate);
  const { mining_currencies } = useSelector((store: RootState) => store.balance);
  const { formattedPrice, currentPriceFiat, formattedPriceRegular, userCurrency, fullSavePrice } =
    useCurrentPlanPriceInfo();

  const currentCurrencyObj = useMemo(
    () => mining_currencies?.find((cur) => cur?.symbol === userCurrency),
    [mining_currencies, userCurrency]
  );

  const getFormatedMiningPrice = useCallback(
    (plan: MiningPoolPlan | undefined) => {
      const currentBtcPrice = (plan && formatPrice(parseFloat(plan?.total_price) / plan?.month)) || 0;
      const btc2UserCurrencyPrice = formatPrice(Number(currentBtcPrice) * cryptoBtc2userCurrencyRate);
      return currentCurrencyObj ? formatBalance(btc2UserCurrencyPrice, currentCurrencyObj?.price_unit) : '0.00';
    },
    [cryptoBtc2userCurrencyRate, currentCurrencyObj]
  );

  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <div className={s.hashrateContainer}>
          {currentPlan && <Hashrate />}
          <MiningDuration getFormatedMiningPrice={getFormatedMiningPrice} userCurrency={userCurrency} />
        </div>

        {currentPlan && (
          <TotalPrice
            formattedPrice={formattedPrice}
            currentPriceFiat={currentPriceFiat}
            formattedPriceRegular={formattedPriceRegular}
            userCurrency={userCurrency}
            fullSavePrice={fullSavePrice}
          />
        )}

        {/*<AvailablePaymentMethods />*/}
      </div>
    </div>
  );
};
