import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDevice } from '@/shared/lib/hooks/useDevice';
import { MODAL_DEBUG } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-debug.module.scss';

export const ModalDebug = ({ onClose, isOpen }: ModalCommonProps) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [build, setBuild] = useState(window.app_config.basename?.replace(/^\/d\//, '') || '');
  const [disabled, setDisabled] = useState(false);
  const { isTelegramMiniApp } = useDevice();
  const { Container, Title, Type } = ModalLayout;
  const navigate = useNavigate();

  const gaidClick = async () => {
    if (window.ct?.getGaid) {
      const gaid = await window.ct.getGaid();
      window.navigator.clipboard.writeText(gaid);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  const buildChange = (val: string) => {
    setBuild(val);
    if (val && val[0] != 'p' && val[0] != 'c' && val[0] != 'd') {
      setDisabled(true);
      return;
    }
    if (isTelegramMiniApp) {
      if (window.location.hostname.indexOf('dvlt') > 0 || val[0] == 'd') {
        setDisabled(true);
        return;
      }
    }
    setDisabled(false);
  };

  const buildClick = async () => {
    let domain = 'ctpool.net';
    if (isTelegramMiniApp) {
      if (window.location.hostname.indexOf('canary') > 0) {
        domain = 'canary.ctpool.net';
      }
    }
    fetch(`https://api.${domain}/api/v2/mining/pool/set?build=${build}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((r) => {
        if (r.ok) {
          window.location.href = build ? `https://mobile.${domain}/d/${build}` : `https://mobile.${domain}/`;
        }
      })
      .catch(() => {});
  };

  return (
    <ModalLayout type={Type.Page} onClose={() => onClose(false)} isOpen={isOpen} id="Modal-debug">
      <div>
        <Title>
          <Icon iconName="rocket" width={20} height={20} className={styles.icon} /> {t('Debug')}
        </Title>
      </div>
      <Container>
        <div className={styles.block}>
          <Button
            size="m"
            color="blue"
            as="button"
            fullWidth
            onClick={gaidClick}
            disabled={copied}
            data-test-id="btn_copy_gaid"
          >
            <Icon iconName={copied ? 'checked' : 'copy'} width={20} height={20} /> Copy Gaid
          </Button>
          <Button
            size="m"
            color="blue"
            as="button"
            fullWidth
            onClick={() => navigate(MODAL_DEBUG)}
            data-test-id="link_debug"
          >
            <Icon iconName="help-fill" width={20} height={20} /> Modal Debug
          </Button>
          {!isTelegramMiniApp && (
            <>
              <Button
                size="m"
                color="blue"
                as="a"
                href="https://mobile.canary.ctpool.net"
                fullWidth
                data-test-id="link_canary"
              >
                Go to Canary <Icon iconName="arrow" width={20} height={20} />
              </Button>
              <Button
                size="m"
                color="blue"
                as="a"
                href="https://ct-pool-mobile.dvlt.net"
                fullWidth
                data-test-id="link_dev"
              >
                Go to Dev <Icon iconName="arrow" width={20} height={20} />
              </Button>
            </>
          )}
          <div className={styles.build}>
            <input
              type="text"
              placeholder="Build number"
              className={styles.version}
              value={build}
              onChange={(e) => buildChange(e.target.value)}
              data-test-id="input_build"
            />
            <Button
              size="m"
              color="blue"
              onClick={buildClick}
              className={styles.set}
              disabled={disabled}
              data-test-id="btn_set_build"
            >
              Set Build
            </Button>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
