import { getLanguage } from '@/shared/store/language';

export const formatHashrate = (value: number, isPoolList: boolean = false): string => {
  const lang = getLanguage();

  let hpsCount;
  let hpsCurrency;

  const numLastMoreTwo = /[1-9]{2,}$/.test(`${value}`);

  if (value >= 1000000) {
    hpsCount = value / 1000000;
    hpsCurrency = isPoolList ? 'M' : 'MH/s';
  } else if (numLastMoreTwo) {
    hpsCount = value;
    hpsCurrency = isPoolList ? 'K' : 'kH/s';
  } else {
    hpsCount = value / 1000;
    hpsCurrency = isPoolList ? 'K' : 'kH/s';
  }

  return `${hpsCount.toLocaleString(lang)} ${hpsCurrency}`;
};
