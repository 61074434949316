import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Code, languages } from '@/shared/lib/constants/languages';
import { getLanguage, setLanguage } from '@/shared/store/language';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-language.module.scss';

export const ModalLanguage = ({ onClose, isOpen }: ModalCommonProps) => {
  const activeCode = getLanguage();
  const [choice, setChoice] = useState(activeCode);
  const [change, setChange] = useState(false);
  const { t } = useTranslation();
  const { Container, Title, Footer } = ModalLayout;

  const handleClick = (code: Code) => {
    setChoice(code);
    if (code === activeCode) {
      setChange(false);
    } else {
      setChange(true);
    }
  };

  const handleChange = () => {
    setLanguage(choice);
    setChange(false);
    onClose(false);
  };

  const handleClose = () => {
    setChoice(activeCode);
    setChange(false);
    onClose(false);
  };

  return (
    <ModalLayout onClose={handleClose} isOpen={isOpen} id="language-modal">
      <Container>
        <Title size="s" align="left">
          {t('Modal.Language.Title')}
        </Title>
        <ul
          className={cn(styles.list, {
            [styles.active]: change,
          })}
        >
          {Object.values(languages).map((language) => (
            <li
              key={language.code}
              className={cn(styles.item, {
                [styles.choice]: language.code === choice,
              })}
              onClick={() => handleClick(language.code)}
              data-test-id={`btn_select_${language.code}`}
            >
              <img src={language.icon} alt={`${language.code}-language-icon`} className={styles.item__icon} />
              <div className={styles.item__content}>
                <span className={styles.item__text}>{language.title}</span>
                <Icon
                  iconName="check"
                  width="18"
                  height="13"
                  className={cn(styles.item__check, language.code === choice && styles.item__check_show)}
                />
              </div>
            </li>
          ))}
        </ul>

        <Footer>
          <Button
            color="blue"
            size="m"
            fullWidth
            onClick={handleChange}
            spinnerTheme="static"
            data-test-id="btn_confirm"
          >
            {t('Modal.Save.BTN')}
          </Button>
          <Button variant="filled" color="grey" size="m" fullWidth onClick={handleClose} data-test-id="btn_cancel">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
