import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCurrentTheme } from '@/features/theme';
import { getEmailServiceUrl } from '@/shared/lib/utils/emailServices';
import { HOME_PATH } from '@/shared/lib/utils/links';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './confirm-withdrawal-modal.module.scss';

export const ConfirmWithdrawalModal = ({ onClose, isOpen }: ModalCommonProps) => {
  const theme = useSelector(selectCurrentTheme);
  const { email } = useSelector(selectUser);
  const { Container, IconContainer, Close, Content, Title, Description, Footer } = ModalLayout;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenInbox = useCallback(() => {
    onClose(false);
    navigate(HOME_PATH);
    const serviceUrl = getEmailServiceUrl(email);
    if (serviceUrl) {
      window.open(serviceUrl, '_blank');
    }
  }, [email, navigate, onClose]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="confirm-withdrawal">
      <Close onClick={() => onClose(false)} />
      <IconContainer>
        <Icon
          iconName={theme === 'light' ? 'email-verify-connect' : 'email-verify-connect-dark'}
          width={80}
          height={80}
        />
      </IconContainer>
      <Container>
        <Content>
          <Title>{t('Confirm Withdrawal')}</Title>
          <Description>
            <Trans
              i18nKey={t('Mail_conf.Check.Descr')}
              components={{ b: <strong className={styles.text_break} /> }}
              values={{ email: email }}
            />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={handleOpenInbox} data-test-id="btn_mailbox_modal">
            {t('Modal.ConfirmWithdr.BTN')}
          </Button>
          <Button variant="filled" color="grey" fullWidth onClick={() => onClose(false)} data-test-id="btn_close_modal">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
