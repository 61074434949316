import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getTransactionBorderColor, getTransactionChipColor, mapStatus } from '@/features/balance';
import { WalletsCurrenciesResponse, Withdrawal } from '@/shared/lib/backend/JsonRpcApi';
import { formatBtcAddress } from '@/shared/lib/utils/format-btc-address';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { HISTORY_PATH } from '@/shared/lib/utils/links';
import { formatBalance } from '@/shared/lib/utils/units';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Icon } from '@/shared/ui/icons';

import styles from './balance-history-item.module.scss';

type BalanceHistoryItemProps = {
  withdrawal: Withdrawal;
  currencies: [] | WalletsCurrenciesResponse;
  variant?: 'rounded' | 'default';
};

export const BalanceHistoryItem = ({ withdrawal, currencies, variant = 'default' }: BalanceHistoryItemProps) => {
  const { t } = useTranslation();
  const [label] = mapStatus(withdrawal?.status, t);
  const labelBorderColor = getTransactionBorderColor(withdrawal?.status);
  const labelBgColor = getTransactionChipColor(withdrawal?.status);
  const addressInfo = getWithdrawalAddressInfo(withdrawal?.meta);
  const cost = withdrawal?.amount;
  const currency = withdrawal?.currency;
  const id = withdrawal?.id;

  const formattedBalance = useMemo(() => {
    const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === currency);

    if (currency === 'HSH') {
      return getLocalAmount(cost);
    }

    const res = currentCurrencyObj ? formatBalance(cost, currentCurrencyObj?.unit) : '0.00';
    return getLocalAmount(res);
  }, [cost, currency, currencies]);

  return (
    <Link className={cn(styles.item, styles[variant])} to={`${HISTORY_PATH}/${id}`} data-test-id="link_item_history">
      <div className={styles.item__row}>
        <div className={styles.item__wallet}>
          {addressInfo.isNcw ? (
            <NCWalletIcon width={20} height={20} />
          ) : (
            <Icon iconName={'wallet'} width={20} height={20} className={styles.item__icon} />
          )}
          <span className={styles.item__address}>
            {addressInfo.isNcw ? addressInfo.email || addressInfo.account_id : formatBtcAddress(addressInfo.address)}
          </span>
        </div>
      </div>
      <div className={styles.item__row}>
        <div className={cn(styles.item__color, styles[labelBorderColor], styles[`${labelBgColor}Bg`])}>{label}</div>
        <div className={styles.item__cost}>
          {formattedBalance} {currency}
        </div>
      </div>
    </Link>
  );
};
