import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentTheme } from '@/features/theme';
import { useModalVerify } from '@/shared/lib/hooks/useModalVerify';
import { AppDispatch } from '@/shared/store/types';
import { confirmEmail } from '@/shared/store/withdrawal/withdrawal.slice';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-email-check.module.scss';

export const ModalEmailCheck = ({ onClose, isOpen }: ModalCommonProps) => {
  const theme = useSelector(selectCurrentTheme);
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toastsContainer = useRef(null);
  const { countdown, newCountdown, toastId, newToastId, value } = useModalVerify();

  const handleClick = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  const handleClickResend = () => {
    setLoading(true);
    dispatch(confirmEmail({ email: value, t, newToastId, toastId, newCountdown }))
      .then(() => {
        toaster.success(t('Successfully resent'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!toastsContainer.current) return;
    toaster.success(t('Successfully sent'), { toastProps: { containerId: 'modalsContainer2' } });
  }, [toastsContainer.current]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="mail-check">
      <IconContainer>
        <Icon
          iconName={theme === 'light' ? 'email-verify-connect-done' : 'email-verify-connect-done-dark'}
          width={80}
          height={80}
        />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Mail_conf.Check.Title')}</Title>
          <Description className={styles.description}>
            <span>
              <Trans i18nKey={t('Mail_conf.Check.Descr')} values={{ email: value }} components={{ b: <strong /> }} />
            </span>
            <span>
              <Trans i18nKey={t('Mail_conf.Check.Spam_mb')} components={{ br: <br /> }} />
            </span>
            <span>{t('Mail_conf.Check.StillNotRcvd')}</span>
          </Description>
        </Content>
        <Footer>
          <Button color="grey" fullWidth onClick={handleClickResend} loading={loading} disabled={countdown !== null}>
            {t('Mail_conf.Check.Resend')} {countdown && `(${countdown % 61})`}
          </Button>
          <Button color="blue" fullWidth onClick={handleClick}>
            {t('Mail_conf.Check.Mail.Btn')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
