import { useTranslation } from 'react-i18next';

import { BalanceHistoryItem } from '@/features/balance';
import { WalletsCurrenciesResponse, Withdrawal } from '@/shared/lib/backend/JsonRpcApi';
import { Alert } from '@/shared/ui/alert';
import { Icon } from '@/shared/ui/icons';
import { Typography } from '@/shared/ui/typography';

import styles from './unconfirmed-transaction-info.module.scss';

type UnconfirmedTransactionInfoProps = {
  withdrawal: Withdrawal;
  currencies: [] | WalletsCurrenciesResponse;
};

export const UnconfirmedTransactionInfo = ({ withdrawal, currencies }: UnconfirmedTransactionInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Alert variant="danger">
        <Typography variant="body2" weight="bold" className={styles.wrapper}>
          <Icon iconName={'warning-outline'} width={40} height={40} />
          <span className={styles.wrapper__text}>{t('Conf_withdr.Popup.Description')}</span>
        </Typography>
      </Alert>
      <BalanceHistoryItem withdrawal={withdrawal} currencies={currencies} variant="rounded" />
    </>
  );
};
