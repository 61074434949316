import cn from 'classnames';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  checkPurchase,
  setGoogleHashrate,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleHashrate,
  useGoogleHashrates,
  useGoogleMiningDuration,
  useGooglePlans,
  useLastReceiptIOS,
  useGoogleMiningDurations,
} from '@/page/cart/store/cart-google-store';
import { formatHashrate } from '@/page/cart/ui/hashrate';
import { MegaSaleBanner } from '@/page/cart/ui/mega-sale-banner/mega-sale-banner';
import { getCurrencyFormat, getMonthPrice } from '@/page/cart/ui/total-price/total-price.util';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { getLanguage } from '@/shared/store/language';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { InputRange } from '@/shared/ui/input-range';

import s from './subscription-hashrate.module.scss';

export const SubscriptionHashrate = () => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { isTelegramMiniApp } = useDevice();
  const hashrates = useSelector(useGoogleHashrates);
  const dispatch: AppDispatch = useDispatch();
  const activeMonth = useSelector(useGoogleMiningDuration);
  const plans = useSelector(useGooglePlans);
  const miningDurationList = useSelector(useGoogleMiningDurations);
  const hasTiral = useSelector((state: RootState) => state.googleCart.trial);
  const lastReceiptIOS = useSelector(useLastReceiptIOS);
  const hashrate = useSelector(useGoogleHashrate);
  const { tags: userTags } = useSelector(selectUser);
  const formattedHashrate = formatHashrate(hashrate);
  const lang = getLanguage();
  const currentValue = hashrates?.indexOf(hashrate);
  const minValue = 0;
  const maxValue = hashrates?.length - 1;
  const isMegaSaleShow = userTags?.includes('promo_11_11_24');

  useEffect(() => {
    const currentPlan = plans?.find((i) => i?.hps === hashrate && i?.month === activeMonth);

    if (currentPlan) {
      dispatch(
        setGoogleInfo({
          productId: currentPlan?.productId,
          offerToken: currentPlan?.offerToken,
          basePlanId: currentPlan?.basePlanId,
          trial: currentPlan?.trial,
        })
      );
      dispatch(
        checkPurchase(
          lastReceiptIOS?.latest_receipt_info?.some(
            (i) => i?.product_id === currentPlan?.productId && +i.expires_date_ms > Date.now()
          )
        )
      );
    } else {
      const currentId = miningDurationList?.findIndex((month) => month === activeMonth);

      let nearestId = currentId;
      let smallDiff = Infinity;

      for (let i = 0; i < miningDurationList?.length; i++) {
        const currentPlan = plans?.find((plan) => plan.hps === hashrate && plan.month === miningDurationList[i]);

        if (!currentPlan) continue;

        const diff = Math.abs(currentId - i);

        if (diff < smallDiff) {
          nearestId = i;
          smallDiff = diff;
        }
      }

      dispatch(setGoogleMiningDuration({ miningDuration: miningDurationList[nearestId] }));
    }
  }, [activeMonth, hashrate, lastReceiptIOS, miningDurationList]);

  const handleChange = (value: number) => {
    dispatch(setGoogleHashrate({ hashrate: hashrates?.[value] }));
  };

  const plan = plans && plans?.find((i) => i?.hps === hashrate && i?.month === activeMonth);

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.top__item}>
          <Icon iconName="speed" className={s.top__icon} width={20} height={20} />
          {t('choose hashrate')}
        </div>
        {isTelegramMiniApp ? (
          <div>
            <Button className={s.btnInfo} variant="text" color="blue" onClick={() => openModal('MODAL_INFO_POOL')}>
              <Icon iconName="info" width="20" height="20" />
            </Button>
          </div>
        ) : null}
      </div>
      <div className={s.hashrateContainer}>
        <span className={cn(s.hashrateValue)} data-test-id="text_hashrate">
          {formattedHashrate}
        </span>
        <div className={s.hashrateRangeContainer}>
          <InputRange
            value={currentValue}
            onChange={handleChange}
            min={minValue}
            max={maxValue}
            dataTestId="slider_hashrate"
          />
        </div>
      </div>
      {isMegaSaleShow ? <MegaSaleBanner /> : null}
      {hasTiral && (
        <div className={s.trial}>
          <div className={s.trial__icon}>
            <Icon iconName="timer" width={20} height={20} />
          </div>
          <div className={s.trial__desc}>
            <h5 className={s.trial__title}>{t('Trial.Title')}</h5>
            <p className={s.trial__text}>
              <Trans
                i18nKey={t('Trial.Description')}
                values={{
                  price: plan && getCurrencyFormat(getMonthPrice(plan), lang, plan.currentCurrency),
                  duration: activeMonth,
                }}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
