import { Trans } from 'react-i18next';

import { Alert } from '@/shared/ui/alert';
import { Icon } from '@/shared/ui/icons';

import s from './bonus-alert.module.scss';

export const HpsAlert = ({ months = '' }: { months?: string }) => {
  return (
    <Alert variant="purple" className={s.alert}>
      <div className={s.alert__content}>
        <Icon iconName="purple-gift-x2" width={30} height={30} className={s.alert__icon} />
        <span className={s.alert__text}>
          <Trans i18nKey="BonusHps.X2" values={{ months }} />
        </span>
      </div>
    </Alert>
  );
};
