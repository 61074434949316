import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  availablePurchaseIos,
  setAvailablePurchaseIos,
  setStatus,
  useAppleAvailablePurchase,
} from '@/page/cart/store/cart-google-store';
import { PlatformIosAppstorePurchasesRequest } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { Icon } from '@/shared/ui/icons';

import s from './restore-subs-button.module.scss';

export const RestoreSubsButton = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const availablePurchaseIos = useSelector(useAppleAvailablePurchase);
  const lastReceipt = useSelector((state: RootState) => state.googleCart.lastReceiptIOS?.latest_receipt);

  const handleClick = async () => {
    let receipt = lastReceipt;

    if (!receipt && window?.ct?.getReceipt) {
      receipt = await window.ct.getReceipt();
      console.warn('receiptIOS', receipt);
    }

    if (!receipt) {
      let availablePurchase: availablePurchaseIos[] | GetAvailablePurchasesResponse | undefined;
      dispatch(setStatus('loading'));
      if (availablePurchaseIos) {
        availablePurchase = availablePurchaseIos;
      } else {
        try {
          availablePurchase = await window?.ct?.getAvailablePurchases();
          console.warn('availablePurchaseIOS', availablePurchase);
          dispatch(setAvailablePurchaseIos(availablePurchase));
        } catch (e) {
          console.error('availablePurchaseIOS error', e);
        }
      }

      try {
        // Get the latest purchase by date
        const lastPurchase = availablePurchase?.reduce((latest, purchase) => {
          return purchase.transactionDate > latest.transactionDate ? purchase : latest;
        }, availablePurchase[0]);

        if (lastPurchase && lastPurchase?.transactionReceipt) {
          const dataRequestAppStore: PlatformIosAppstorePurchasesRequest = {
            receipt: lastPurchase?.transactionReceipt,
            restore: true,
          };
          console.warn('dataRequestAppStore', dataRequestAppStore);
          try {
            await rpc.transmit('platform.ios.appstore.purchases', dataRequestAppStore).then(() => {
              toaster.success(t('RestorePurchases.Description'));
            });
          } catch (e) {
            console.error('purchaseSubscriptionIosRestoreRequest error:', e);
          }
        }
      } catch (e) {
        console.error('Error restoring subscriptions:', e);
      } finally {
        dispatch(setStatus('idle'));
      }
    }
  };

  return (
    <div className={s.restore}>
      <Button size="xs" onClick={handleClick} className={s.button}>
        <Icon iconName={'credit'} width={20} height={20} className={s.icon} />
        <span className={s.text}>{t('RestorePurchases.Btn')}</span>
      </Button>
    </div>
  );
};
