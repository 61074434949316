import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLogout } from '@/shared/hooks';
import { rpc } from '@/shared/lib/backend/Rpc';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

export const ModalDeleteAccount = ({ onClose, isOpen }: ModalCommonProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const { logout } = useLogout();

  const handleDelete = async () => {
    try {
      await rpc.transmit('users.me.delete', {});
    } catch (e) {
      console.error(e);
    }
    await logout();
    onClose(false);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={() => onClose(false)} id="delete-account-modal">
      <IconContainer>
        <Icon iconName="delete-circle" width="80" height="80" />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Delete_acc.Modal.Title')}</Title>
          <Description>{t('Delete_acc.Modal.Description')}</Description>
        </Content>
        <Footer>
          <Button color="red" fullWidth onClick={handleDelete} data-test-id="btn_delete_account">
            {t('confirm')}
          </Button>
          <Button color="grey" fullWidth onClick={handleClose} data-test-id="btn_back">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
