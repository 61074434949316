import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContentFindResponse, TsPage } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { Code } from '@/shared/lib/constants/languages';
import { ModalName } from '@/shared/lib/context/modal/modal-render';
import { RootState } from '@/shared/store/types';

export type rootContent = {
  news: TsPage[] | [];
  modal: TsPage | null;
};

const initialState: rootContent = {
  news: [],
  modal: null,
};

export const getNews = createAsyncThunk<
  void,
  { lang: Code },
  {
    state: RootState;
  }
>('content/getNews', async function ({ lang }, { dispatch }) {
  try {
    await rpc
      .transmit('content.find', {
        categories: ['news'],
        language: lang,
        placing: ['cryptobrowser', 'ctnft', 'ctfarm_pro', 'ctfarm'],
        limit: 3,
      })
      .then((r) => {
        dispatch(setNews(r));
      });

    // eslint-disable-next-line
  } catch (e: any) {
    console.log(e.message);
  }
});

export const getModal = createAsyncThunk<
  void,
  // eslint-disable-next-line
  { openModal: (name: ModalName, options?: any) => void; lang: Code; id: string },
  {
    state: RootState;
  }
>('content/getModal', async function ({ openModal, lang, id }, { dispatch }) {
  try {
    await rpc
      .transmit('content.find', { categories: ['modals'], language: lang, placing: ['pool-push-modal'] })
      .then((modals) => {
        const modal = modals?.items?.filter((modal: TsPage) => modal.id === +id)[0];

        if (!modal) {
          throw new Error('Modal not found.');
        }

        const isModalActive = !modal?.expired_at || new Date(modal.expired_at * 1000).getTime() > Date.now();

        if (isModalActive) {
          dispatch(setModal(modal));
          openModal('MODAL_PUSH', { modal });
        }
      });

    // eslint-disable-next-line
  } catch (e: any) {
    console.log(e.message);
  }
});

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setNews(state, action: PayloadAction<ContentFindResponse>) {
      state.news = action.payload.items.sort(
        (a, b) => new Date(b.published_at * 1000).getTime() - new Date(a.published_at * 1000).getTime()
      );
    },
    setModal(state, action: PayloadAction<TsPage>) {
      state.modal = action.payload as TsPage;
    },
  },
});

export const { setNews, setModal } = contentSlice.actions;

export const selectNews = (state: RootState) => state.content.news;
export const selectModal = (state: RootState) => state.content.modal;

export default contentSlice.reducer;
