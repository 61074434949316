import cn from 'classnames';
import React from 'react';

import { Icon } from '@/shared/ui/icons';

import styles from './rating.module.scss';

const COUNT_STARS = 5;

type RatingProps = {
  onChange?: (value: number) => void;
  size?: number;
  color?: 'grey' | 'white';
  count?: number;
  value: number;
  noNumbers?: boolean;
};

export const Rating = (props: RatingProps) => {
  const { size = 44, color = 'grey', onChange, count = COUNT_STARS, value, noNumbers } = props;

  const handleChange = (value: number) => {
    onChange && onChange(value);
  };

  return (
    <>
      {new Array(count).fill(0).map((_, i) => (
        <div key={i} className={cn(styles.star, onChange && styles.star_pointer)} onClick={() => handleChange(i + 1)}>
          <Icon
            iconName="rating-star"
            width={size}
            height={size}
            className={cn([styles.color, (value > i || value === i + 1) && styles.active], styles[color])}
          />
          {noNumbers ? null : (
            <div className={cn([styles.color, (value > i || value === i + 1) && styles.active])}>{i + 1}</div>
          )}
        </div>
      ))}
    </>
  );
};
