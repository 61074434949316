export enum Tag {
  TEST_OLD_EF1_A = 'test_old_ef1_A',
  TEST_OLD_EF1_B = 'test_old_ef1_B',
  TEST_OLD_EF1_C = 'test_old_ef1_C',
  TEST_NEW_EF1_A = 'test_new_ef1_A',
  TEST_NEW_EF1_B = 'test_new_ef1_B',
  TEST_NEW_EF1_C = 'test_new_ef1_C',
  BONUS_TAG = 'bonus_10',
  BONUS_TAG_20 = 'bonus_20',
  WITHDRAW_ACCESS = 'withdraw_access',
  WITHDRAW_SUSPEND = 'withdraw_suspend',
  PROMO_11_11_24 = 'promo_11_11_24',
  XTR_ON = 'xtr_on',
  FEB_25 = 'feb_25',
  TEST_CART_1_A = 'test_cart_1_a',
  TEST_CART_1_B = 'test_cart_1_b',
}
