import cn from 'classnames';
import React, { ReactNode } from 'react';
import Modal from 'react-modal';

import common from '../modal.module.scss';
import uniq from './modal-bottom-sheet.module.scss';

interface ModalBottomSheetProps {
  onClose: () => void;
  isOpen?: boolean;
  id: string;
  children: ReactNode;
  className?: string;
}

export const ModalBottomSheet = ({ children, onClose, isOpen, id, className }: ModalBottomSheetProps) => (
  <Modal
    className={{
      base: cn(uniq.modal, className),
      afterOpen: uniq.modal__after,
      beforeClose: uniq.modal__before,
    }}
    isOpen={isOpen}
    overlayClassName={common.overlay}
    onRequestClose={onClose}
    closeTimeoutMS={500}
    id={id}
  >
    {children}
  </Modal>
);
