import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

import styles from './balance-additional-info.module.scss';

type BalanceAdditionalInfoProps = {
  className?: string;
};

export const BalanceAdditionalInfo = ({ className }: BalanceAdditionalInfoProps) => {
  const { formattedPoolBalance, usdPoolBalance, userCurrency } = useBalanceInfo();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={cn(styles.info__item, className)}>
        <div className={styles.col}>
          <div>
            <div className={styles.info__label}>{t('Total Earnings:')}</div>
            <div className={styles.info__mob_row}>
              <div className={styles.info__btc}>
                {getLocalAmount(formattedPoolBalance)} {userCurrency}
              </div>
              <div className={styles.info__usd}>{getLocalAmount(usdPoolBalance, 'usd')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
