import cn from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentTheme } from '@/features/theme';
import { AppTheme } from '@/features/theme/model/slice';
import { social } from '@/shared/lib/constants/socials';

import DiscordSvg from './icons/discord.svg';
import FacebookSvg from './icons/facebook.svg';
import InstagramSvg from './icons/instagram.svg';
import TelegramSvg from './icons/telegram.svg';
import TwitterDarkSvg from './icons/twitter-dark.svg';
import TwitterSvg from './icons/twitter.svg';
import YoutubeSvg from './icons/youtube.svg';

import styles from './social.module.scss';

interface SocialProps {
  isDarkTheme?: boolean;
}

interface SocialItemProps {
  href: string;
  data: string;
  icon: string;
  style?: string;
}

const SocialItem = ({ href, data, icon, style }: SocialItemProps) => {
  return (
    <li>
      <a
        href={href}
        data-test-id={data}
        className={cn(styles.link, style && styles[style])}
        target="_blank"
        rel="noreferrer"
      >
        <img src={icon} alt="" />
      </a>
    </li>
  );
};

const Social = ({ isDarkTheme }: SocialProps) => {
  const socialList = useMemo(
    () => [
      {
        href: social.telegram,
        data: 'link_telegram',
        icon: TelegramSvg,
        style: 'telegram',
      },
      {
        href: social.instagram,
        data: 'link_instagram',
        icon: InstagramSvg,
        style: 'inst',
      },
      {
        href: social.twitter,
        data: 'link_twitter',
        icon: isDarkTheme ? TwitterDarkSvg : TwitterSvg,
        style: isDarkTheme ? 'tw_dark' : 'tw',
      },
      {
        href: social.facebook,
        data: 'link_facebook',
        icon: FacebookSvg,
        style: 'fb',
      },
      {
        href: social.discord,
        data: 'link_discord',
        icon: DiscordSvg,
        style: 'discord',
      },
      {
        href: social.youtube,
        data: 'link_youtube',
        icon: YoutubeSvg,
        style: 'youtube',
      },
    ],
    [isDarkTheme]
  );
  return (
    <ul className={styles.social}>
      {socialList.map((item, index) => (
        <SocialItem {...item} key={index} />
      ))}
    </ul>
  );
};

const SocialContainer = () => {
  const currentTheme = useSelector(selectCurrentTheme);

  return <Social isDarkTheme={currentTheme === AppTheme.DARK} />;
};

export { SocialContainer as Social };
