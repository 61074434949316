import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUtmSource } from '@/features/crypto-tab-products/hooks/use-current-utm-source';
import { CURRENT_PROMO_BANNER_ID, getIsCurrentPromo } from '@/page/home/ui/promo/helpers';
import { getLanguage } from '@/shared/store/language';
import CloseSvg from '@/shared/ui/assets/unsupport-icons/close.svg?react';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import showBackgroundSrc from '../images/snow.png';

import styles from './promo-banner.module.scss';

export const CurrentPromoBanner = () => {
  const { t } = useTranslation();
  const [showCurrentPromoBanner, setCurrentPromoBanner] = useState(() => getIsCurrentPromo());
  const utm_source = useCurrentUtmSource();
  const lang = getLanguage();

  const onClickCloseBanner = () => {
    const timestamp = Date.now();
    localStorage.setItem(CURRENT_PROMO_BANNER_ID, timestamp.toString());
    setCurrentPromoBanner(false);
  };

  const onLinkClick = () => {
    window.open(
      `https://cryptobrowser.site/${lang}/promo/campfire/?utm_source=${utm_source}&utm_medium=bnrfes`,
      '_blank'
    );
  };

  if (!showCurrentPromoBanner) {
    return null;
  }

  return (
    <div className="container">
      <div className={styles.root}>
        <img src={showBackgroundSrc} alt="banner background" className={styles.background} />
        <span className={styles.close} onClick={onClickCloseBanner}>
          <CloseSvg width={12} height={12} data-test-id="btn_close_banner" />
        </span>
        <div className={styles.content}>
          <div className={styles.title}>{t('Camp.Modal.Title')}</div>
          <div className={styles.text}>{t('Camp.Modal.Descr')}</div>
          <div className={styles.button}>
            <Button color="blue" fullWidth onClick={onLinkClick}>
              {t('Camp.Modal.Btn')}
              <Icon iconName="arrow" width="20" height="20" className={styles.icon} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
