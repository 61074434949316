import { useSelector } from 'react-redux';

import { useHasSubscriptionIos } from '@/shared/lib/hooks/useHasSubscriptionIos';
import { RootState } from '@/shared/store/types';

import { PoolMinersList, PoolMiningInfo, PoolMiningFooter } from './ui';

import styles from './pool-mining-page.module.scss';

export const PoolMiningPage = () => {
  const miners = useSelector((store: RootState) => store.pool_miner.list);
  const isSubscriptionIos = useHasSubscriptionIos();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <PoolMiningInfo />
        <PoolMinersList miners={miners} isSubscriptionIos={isSubscriptionIos} />
      </div>
      <PoolMiningFooter />
    </div>
  );
};
