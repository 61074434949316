import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BonusOfferBanner, variantBonus } from '@/page/cart/ui/special-offer/bonus-offer-banner';
import { useMegaSaleModal } from '@/page/home/hooks/useMegaSaleModal';
import { JoinUs } from '@/page/home/ui/join-us/join-us';
import { MiningBlock } from '@/page/home/ui/mining-block/mining-block';
import { BALANCE_HSH } from '@/page/home/ui/modal-increasing-hsh/modal-increasing-hsh';
import { ONE_WEEK, PURCHASE_HSH } from '@/page/home/ui/modal-info-bonus-hsh/modal-info-bonus-hsh';
import { PixelBtc } from '@/page/home/ui/pixel-btc/pixel-btc';
import { CurrentPromoBanner } from '@/page/home/ui/promo/promo-banner/promo-banner';
import { BonusNames, usePlansBonusName } from '@/shared/hooks/use-plans-bonus-name';
import { OFFER_ACCEPTED_BY_USER } from '@/shared/lib/constants/storage-key';
import { Tag } from '@/shared/lib/constants/tags';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useRating } from '@/shared/lib/hooks/useRating';
import { useTimerWithDeadline } from '@/shared/lib/hooks/useTimerWithDeadline';
import { CART_PATH, CRYPTO_TAB_REVIEW } from '@/shared/lib/utils/links';
import { formatBalance } from '@/shared/lib/utils/units';
import { poolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser, userAction } from '@/shared/store/user/user.slice';
import { FrameNews } from '@/shared/ui/frame-news/frame-news';

import { ProductsSlider } from './ui/products-slider';
import { BonusBalanceCard } from '../bonus-program/ui/bonus-balance-card';

export function Home() {
  const miners = useSelector((store: RootState) => store.pool_miner.list);
  const { new_miner } = useSelector((store: RootState) => store.pool_miner);
  const { id, tags } = useSelector(selectUser);
  const { hsh_balance, currencies } = useSelector((store: RootState) => store.balance);
  const plansBonusName = usePlansBonusName();
  const { checkRatingPermission, shouldAskRating, shouldReviewRating, isInitialized } = useRating();
  const { isTimeOver } = useTimerWithDeadline('2024-11-17T23:59:00Z');
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal } = useModalSet();
  const { isReactNativeApp, isIos } = useDevice();

  // Determine if Mega Sale should be shown
  const isMegaSaleShow = tags?.includes(Tag.PROMO_11_11_24);
  // Use custom hook for Mega Sale logic
  useMegaSaleModal({ isMegaSaleShow, shouldAskRating, isTimeOver });

  const handleRatingModal = useCallback(() => {
    if (shouldAskRating) {
      openModal('MODAL_RATE_US');
    }
  }, [shouldAskRating, openModal]);

  const hsh_currency = useMemo(() => currencies?.find((cur) => cur?.symbol === 'HSH'), [currencies]);

  useEffect(() => {
    if (isReactNativeApp && !isInitialized) {
      checkRatingPermission();
    }
  }, [checkRatingPermission, isInitialized, isReactNativeApp]);

  useEffect(() => {
    handleRatingModal();
  }, [shouldAskRating, handleRatingModal]);

  useEffect(() => {
    const localDate = localStorage.getItem(PURCHASE_HSH) || '{}';
    const lastVisit = JSON.parse(localDate);
    const isHasVisit = lastVisit.date;

    if ((isHasVisit && !lastVisit.isShow) || plansBonusName != BonusNames.HSH) {
      return;
    } else if (!isHasVisit || Date.now() - lastVisit.date >= ONE_WEEK) {
      openModal('MODAl_INFO_BONUS_HSH');
    }

    const visit = { date: Date.now(), isShow: true };
    localStorage.setItem(PURCHASE_HSH, JSON.stringify(visit));
  }, [openModal, plansBonusName]);

  useEffect(() => {
    if (!shouldAskRating && shouldReviewRating === 'page') {
      navigate(CRYPTO_TAB_REVIEW);
      dispatch(userAction.setForceDisableRating(true));
    } else if (!shouldAskRating && shouldReviewRating === 'modal') {
      openModal('MODAL_RATE_COMPANY');
      userAction.setForceDisableRating(true);
    }
  }, [dispatch, navigate, openModal, shouldAskRating, shouldReviewRating]);

  useEffect(() => {
    if (!miners.length || localStorage.getItem(`${OFFER_ACCEPTED_BY_USER}${id}`)) {
      return;
    }

    const offerMiner = miners.find(({ product_id, deleted }) => !deleted && product_id === '1x1.pool.promo');

    if (offerMiner) {
      const daysLimit = 27 * 24 * 60 * 60 * 1000;
      const timeRemaining = +offerMiner.end_time * 1000 - Date.now();

      if (timeRemaining > daysLimit && !shouldAskRating && !isTimeOver) {
        const currentDate: Date = new Date();
        localStorage.setItem(`${OFFER_ACCEPTED_BY_USER}${id}`, currentDate.toISOString());
        openModal('MODAL_OFFER');
      }
    }
  }, [miners, id, openModal, shouldAskRating]);

  useEffect(() => {
    if (hsh_balance.current > 0) {
      const localDate = localStorage.getItem(BALANCE_HSH) || '{}';
      const lastBalance = JSON.parse(localDate);

      if (lastBalance.balance >= 0 && !lastBalance.isShow) {
        return;
      } else if (+hsh_balance.current - +lastBalance.balance >= 1) {
        openModal('MODAL_INCREASING_HSH');
      }

      const formattedLastBalance = hsh_currency
        ? formatBalance(hsh_balance.current - lastBalance.balance, hsh_currency?.unit)
        : '0.00';

      const balanceHSH = {
        balance: hsh_balance.current,
        lastBalance: formattedLastBalance,
        isShow: true,
      };
      localStorage.setItem(BALANCE_HSH, JSON.stringify(balanceHSH));
    }
  }, [openModal]);

  useEffect(() => {
    const isPromo = new_miner?.product_id?.includes('promo');

    if (new_miner && !isPromo) {
      openModal('MODAL_POOL_MINER_ACTIVE', { miner: new_miner });
    }

    return () => {
      dispatch(poolMiner.setNewMiner(null));
    };
  }, [dispatch, new_miner, openModal]);

  return (
    <>
      <PixelBtc />
      <Helmet>
        <title>{t('ShortDescription')}</title>
        <meta name="og:description" content={t('LongDescription')!} />
      </Helmet>
      <div className="home-block">
        {plansBonusName === BonusNames.HPS && <BonusOfferBanner main onClick={() => navigate(CART_PATH)} />}
        {plansBonusName === BonusNames.HSH && (
          <BonusOfferBanner main onClick={() => navigate(CART_PATH)} variant={variantBonus.hsh} />
        )}
        <MiningBlock />
        <CurrentPromoBanner />
        <BonusBalanceCard balance={hsh_balance?.total} increased={hsh_balance?.increased} />
        {!isIos && <ProductsSlider />}
        {/*{!isIos && <AffiliateSlider />}*/}
        <JoinUs />
        <FrameNews />
      </div>
    </>
  );
}
