import { ReactElement } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

import { AppRouter } from '@/app/app-router';
import { EffectAppContainer } from '@/app/effect-app-container';
import { Providers } from '@/app/providers';

function App(): ReactElement {
  return (
    <Providers>
      <EffectAppContainer>
        <AppRouter />
        <ToastContainer
          enableMultiContainer={true}
          closeButton={false}
          hideProgressBar
          closeOnClick={true}
          pauseOnHover={false}
          autoClose={5000}
          position="top-right"
          transition={Slide}
        />
      </EffectAppContainer>
    </Providers>
  );
}

export default App;
