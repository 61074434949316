import React from 'react';
import { useTranslation } from 'react-i18next';

import { CTProduct } from '../../hooks';

import styles from './product-tile.module.scss';

type ProductTileProps = {
  product: CTProduct;
};

export const ProductTile = ({ product }: ProductTileProps) => {
  const { t } = useTranslation();

  return (
    <a
      target="_blank"
      href={product.link}
      className={styles.container}
      data-test-id={`link_${product.dataTestId}`}
      rel="noreferrer"
    >
      <img className={styles.logo} src={product.icon} alt={product.name} />
      <div className={styles.name}>{t(product.name)}</div>
    </a>
  );
};
