import { TFunction } from 'i18next';

import { WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';

export function mapStatus(status: WithdrawalStatus, t: TFunction): [string, string] {
  switch (status) {
    case WithdrawalStatus.Created:
      return [t('NotConfirmed.Confirm'), t('created')];
    case WithdrawalStatus.Confirmed:
      return [t('Processing.Processing'), t('confirmed')];
    case WithdrawalStatus.Canceled:
      return [t('canceled.canceled'), t('canceled')];
    case WithdrawalStatus.Rejected:
    case WithdrawalStatus.RejectedNull:
      return [t('rejected.rejected'), t('rejected')];
    case WithdrawalStatus.Completed:
      return [t('complete.complete'), t('complete')];
    case WithdrawalStatus.Error:
      return [t('error.error'), t('error')];
    case WithdrawalStatus.Pending:
    case WithdrawalStatus.PendingFunds:
      return [t('Processing.Processing'), t('pending')];
    case WithdrawalStatus.Sent:
      return [t('Processing.Processing'), t('sent')];
    default:
      return ['', ''];
  }
}
