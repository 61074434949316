import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/shared/store/types';

export const useHasSubscriptionIos = () => {
  const lastReceiptIOS = useSelector((state: RootState) => state.googleCart.lastReceiptIOS);

  return useMemo(
    () =>
      !!lastReceiptIOS?.latest_receipt_info?.some((item) => {
        return item.subscription_group_identifier && +item.expires_date_ms < Date.now();
      }),
    [lastReceiptIOS]
  );
};
