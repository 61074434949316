import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BalanceHSH } from '@/page/bonus-program/ui/bonus-balance-card/ui/balance-hsh';
import { DailyTransactionsHistory } from '@/shared/lib/backend/JsonRpcApi';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { formatBalance } from '@/shared/lib/utils/units';
import { getLanguage } from '@/shared/store/language';
import { RootState } from '@/shared/store/types';
import { CustomTippy } from '@/shared/ui/custom-tippy/custom-tippy';
import { Icon } from '@/shared/ui/icons';

import styles from './bonus-balance.module.scss';

interface BonusBalanceProps {
  items?: DailyTransactionsHistory[];
  balance?: number;
  increased?: number;
  accrued?: number;
  income?: number;
  nextPayment?: {
    days: number;
    hours: number;
    mins: number;
  };
}

export const BonusBalance = ({ items, balance, increased, accrued, income, nextPayment }: BonusBalanceProps) => {
  const { currencies } = useSelector((store: RootState) => store.balance);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguage();

  // Format the date using Intl.DateTimeFormat
  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat(lang, options).format(new Date(date));
  };

  const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === 'HSH') || { unit: '1' };

  return (
    <div className={styles.bonus}>
      <div className={styles.bonus__title}>
        {t('BB_Hsh.BBalance')}
        {increased && increased >= 1 ? (
          <span className={styles.bonus__increased}>
            +{getLocalAmount(formatBalance(increased, currentCurrencyObj?.unit))}
          </span>
        ) : null}
      </div>
      <div className={cn(styles.bonus__balance, { [styles.bonus__balance_border]: items?.length })}>
        <BalanceHSH balance={getLocalAmount(formatBalance(balance || '0', currentCurrencyObj?.unit))} />
      </div>
      {!!income || !!accrued ? (
        <div className={styles.bonus__container}>
          {!!income && income >= 1 && (
            <div className={styles.bonus__pending}>
              <span className={styles.bonus__title_min}>{t('BB_Hsh.Pending')}</span>
              <div className={styles.pending__container}>
                <span className={styles.bonus__amount}>
                  <b>{getLocalAmount(formatBalance(income, currentCurrencyObj?.unit))}</b> HSH
                </span>
                {nextPayment && (
                  <div className={styles.pending__tooltip}>
                    <CustomTippy
                      content={
                        <div className={styles.tooltip__content}>
                          <Icon
                            iconName="close"
                            width={14.4}
                            height={14.4}
                            className={styles.tooltip__close}
                            onClick={() => setTooltipVisible(false)}
                          />
                          <span>
                            <b>{t('BB_Hsh.Next_conf')} </b>
                            {nextPayment?.days > 0 && t('BB_Hsh.Next_conf.Days', { days: nextPayment?.days })}{' '}
                            {nextPayment?.hours > 0 && t('BB_Hsh.Next_conf.Hours', { hours: nextPayment?.hours })}{' '}
                            {nextPayment?.mins > 0 && t('BB_Hsh.Next_conf.Min', { minutes: nextPayment?.mins })}
                          </span>
                        </div>
                      }
                      visibleTooltip={tooltipVisible}
                      hideTooltip={() => setTooltipVisible(false)}
                    >
                      <div className={styles.tooltip} onClick={() => setTooltipVisible((prev) => !prev)}>
                        <Icon iconName="qa" width={14} height={14} />
                      </div>
                    </CustomTippy>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!accrued && (
            <div className={styles.bonus__accrued}>
              <span className={styles.bonus__title_min}>{t('BB_Hsh.All_time')}</span>
              <span className={styles.bonus__amount}>
                <b>{getLocalAmount(formatBalance(accrued, currentCurrencyObj?.unit))}</b> HSH
              </span>
            </div>
          )}
        </div>
      ) : null}
      {items?.length ? (
        <div className={styles.bonus__list}>
          {items?.map((item, index) => {
            return (
              +item?.amount >= 1 && (
                <div key={index} className={styles.bonus__item}>
                  <div className={styles.bonus__item__details}>
                    <div>
                      <BalanceHSH
                        balance={getLocalAmount(formatBalance(item.amount, currentCurrencyObj?.unit))}
                        small
                      />
                    </div>
                    <div className={styles.bonus__item__date}>{formatDate(item.date)}</div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
