import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDevice } from '@/shared/lib/hooks/useDevice';
import { POOL_FEEDBACK } from '@/shared/lib/utils/links';
import { selectUser } from '@/shared/store/user/user.slice';

export const useSupportLink = (): string => {
  const { id } = useSelector(selectUser);
  const { platformCt, isTelegramMiniApp, appId, envVer } = useDevice();

  return useMemo(
    () => `${POOL_FEEDBACK}?user_id=${id}&platform=${isTelegramMiniApp ? 'telegram' : platformCt}&app_id=${appId}`,
    [platformCt, isTelegramMiniApp, appId, envVer]
  );
};
