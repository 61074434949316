import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  purchaseSubscriptionIos,
  useGoogleCurrentPlan,
  useGoogleIsAvailable,
  useGooglePlans,
  useGooglePurchaseStatus,
  useLastReceiptIOS,
} from '@/page/cart/store/cart-google-store';
import { ReceiptInfo } from '@/shared/lib/backend/JsonRpcApi';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { FixedLoader } from '@/shared/ui/loader/loader';

export const PurchaseSubsButtonIos = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { platformCt } = useDevice();
  const navigate = useNavigate();
  const status = useSelector(useGooglePurchaseStatus);
  const isAvailable = useSelector(useGoogleIsAvailable);
  const lastReceiptIOS = useSelector(useLastReceiptIOS);
  const plans = useSelector(useGooglePlans);
  const isSubscription = useSelector((state: RootState) => state.googleCart.isSubscriptionPurchase);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const { openModal } = useModalSet();
  const isLoading = status === 'loading';

  const [changeSubscription, setChangeSubscription] = useState<boolean>(false);
  const [subscriptionsIos, setSubscriptionsIos] = useState<ReceiptInfo[]>([]);

  useEffect(() => {
    if (lastReceiptIOS) {
      setSubscriptionsIos(lastReceiptIOS.latest_receipt_info);
    }
  }, [lastReceiptIOS]);

  useEffect(() => {
    if (subscriptionsIos?.length) {
      const group = plans && plans.filter((i) => i?.hps === currentPlan?.hps);
      const change =
        group?.reduce((v, plan) => {
          return v || !!subscriptionsIos.find((i) => i?.product_id === plan?.productId);
        }, false) || false;
      setChangeSubscription(change);
    }
  }, [currentPlan, plans, subscriptionsIos]);

  const handleClick = () => {
    dispatch(purchaseSubscriptionIos({ openModal, navigate }));
  };

  if (platformCt !== 'ios') {
    return null;
  }

  return (
    <>
      {isLoading && <FixedLoader />}
      <Button
        fullWidth
        color="green"
        onClick={handleClick}
        disabled={isLoading || isAvailable}
        loading={isLoading}
        data-test-id="btn_subscribe"
      >
        {isSubscription ? (
          isAvailable ? (
            <>
              <Icon iconName="checked" width={20} height={20} />
              {t('workerDetails.activated')}
            </>
          ) : (
            <>
              {changeSubscription ? (
                <>
                  <Icon iconName="change" width={20} height={20} />
                  {t('Subscription_upgrade.Btn')}
                </>
              ) : (
                `${t('SUBSCRIBE')} ${getLocalAmount(
                  currentPlan?.total_price,
                  currentPlan?.currentCurrency
                )} / ${currentPlan?.month} ${t('Months_abrv.Subtitle')}`
              )}
            </>
          )
        ) : (
          `${t('BUY')} ${getLocalAmount(currentPlan?.total_price, currentPlan?.currentCurrency)}`
        )}
      </Button>
    </>
  );
};
