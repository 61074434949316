import { utmSource } from '@/features/crypto-tab-products/hooks/use-current-utm-source';

export enum UtmPlace {
  Default = 'Default',
  Link = 'Link',
  Referrer = 'Referrer',
}

type updateUtmSource = {
  link: string;
  utmSource: utmSource;
  utmPlace?: UtmPlace;
};

export const updateUtmSource = ({ link, utmSource, utmPlace = UtmPlace.Default }: updateUtmSource) => {
  const url = new URL(link);

  if (utmPlace === UtmPlace.Link) {
    const urlLink = new URL(url.searchParams.get('link') || link);
    const ref = (urlLink.searchParams.get('ref') || '').split('.');

    ref[0] = utmSource;

    urlLink.searchParams.set('ref', ref.join('.'));
    url.searchParams.set('link', urlLink.toString());
  } else if (utmPlace === UtmPlace.Referrer) {
    const ref = new URLSearchParams(url.searchParams.get('referrer') || '');

    ref.set('utm_source', utmSource);
    url.searchParams.set('referrer', ref.toString());
  } else {
    url.searchParams.set('utm_source', utmSource);
  }

  return url.toString();
};
