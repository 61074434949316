import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ShuffleMachine } from '@/features/shuffleMachine/shuffleMachine';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { MINING } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { splitCurrencyValue } from '@/shared/lib/utils/balance';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { Tutorial } from '@/shared/ui/tutorial/tutorial';

import { useMiningBlock } from '../../mining-block';

import styles from './balance.module.scss';
import theme from './reel-theme.module.scss';
import theme2 from './reel-theme2.module.scss';

export const Balance = () => {
  const { currentCurrencyObj, formattedTotalBalance, usdBalance, userCurrency, exact_total } = useBalanceInfo();

  const splitedAmount = splitCurrencyValue(exact_total, userCurrency);

  const balanceToView = useMemo(() => {
    return Number(formattedTotalBalance) < +(currentCurrencyObj?.unit || 0.001) ? (
      <>
        <ShuffleMachine text={getLocalAmount(`${splitedAmount[0]}.${splitedAmount[1]}`)} extendedClasses={theme} />
        <ShuffleMachine text={splitedAmount[2]} extendedClasses={theme2} />
      </>
    ) : (
      <ShuffleMachine text={getLocalAmount(formattedTotalBalance)} extendedClasses={theme} />
    );
  }, [formattedTotalBalance, splitedAmount, userCurrency, currentCurrencyObj]);

  const miningBlockRef = useMiningBlock();

  return (
    <div className={styles.container}>
      <Tutorial step="balance" arrow="left" position="top" viewBlock={miningBlockRef.current}>
        <div className={styles.balanceInfoContainer}>
          <img src={currentCurrencyObj?.img} alt="ico" width={40} height={40} className={styles.balanceIcon} />
          <div className={styles.balanceCrypto}>
            {balanceToView}
            <span>{userCurrency}</span>
          </div>
          <div className={styles.balanceUsd}>
            <span>{getLocalAmount(usdBalance, 'usd')}</span>
          </div>
        </div>
      </Tutorial>
      <Tutorial step="withdraw" arrow="right" position="top" viewBlock={miningBlockRef.current}>
        <Button
          color="blue"
          variant="outline"
          className={styles.walletButton}
          to={`${WITHDRAW_PATH}/${MINING}`}
          as={Link}
          data-test-id="btn_wallet"
        >
          <Icon iconName="wallet" width={20} height={20} />
        </Button>
      </Tutorial>
    </div>
  );
};
