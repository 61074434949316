import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WalletsWithdrawalsLastAddressesResponseUnion } from '@/shared/lib/backend/JsonRpcApi';
import { formatBtcAddress } from '@/shared/lib/utils/format-btc-address';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { NC_WALLET_CONNECT } from '@/shared/lib/utils/links';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './address-modal.module.scss';

interface AddressModalProps extends ModalCommonProps {
  addresses: WalletsWithdrawalsLastAddressesResponseUnion[];
  currentAddress: string;
  userAccountEmail: string;
  setIsNcw: (val: boolean) => void;
  onClick: (addr: string) => void;
}

export const AddressModal = ({
  onClose,
  isOpen,
  addresses,
  currentAddress,
  userAccountEmail,
  setIsNcw,
  onClick,
}: AddressModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Container, Title, Footer } = ModalLayout;

  const handleClick = useCallback(
    (addr: string, isNcw: boolean) => {
      onClick(addr);
      setIsNcw(isNcw);
      onClose(false);
    },
    [onClick, setIsNcw, onClose]
  );

  const handleClickAddAddress = useCallback(() => {
    navigate(NC_WALLET_CONNECT, { state: { email: userAccountEmail } });
    onClose(false);
  }, [userAccountEmail, onClose]);

  return (
    <ModalLayout isOpen={isOpen} onClose={() => onClose(false)} id="address">
      <Container>
        <Title size="s" align="left">
          {t('Select_Address')}
        </Title>
        <div className={styles.list}>
          {addresses?.map((address, idx) => (
            <AddressItem key={idx} address={address} currentAddress={currentAddress} idx={idx} onClick={handleClick} />
          ))}
        </div>
        <Footer>
          <Button
            fullWidth
            borderRadius={false}
            variant="filled"
            color="grey"
            onClick={handleClickAddAddress}
            data-test-id="btn_add_address_modal"
          >
            <Icon iconName="plus" width="20" height="20" />
            {t('Add New Address')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};

type AddressItemProps = {
  address: WalletsWithdrawalsLastAddressesResponseUnion;
  currentAddress: string;
  idx: number;
  onClick: (addr: string, isNcw: boolean) => void;
};

const AddressItem = ({ address, currentAddress, idx, onClick }: AddressItemProps) => {
  const addressInfo = getWithdrawalAddressInfo(address);
  const addressValue = addressInfo.isNcw ? addressInfo.email || addressInfo.account_id : addressInfo.address;
  const addressName = addressInfo.isNcw
    ? addressInfo.email || addressInfo.account_id
    : formatBtcAddress(addressInfo.address);
  const isActive = currentAddress === addressValue;

  return (
    <Button
      fullWidth
      borderRadius={false}
      variant="filled"
      key={idx}
      className={styles.item}
      onClick={() => onClick(addressValue, addressInfo.isNcw)}
      color={isActive ? 'blue' : 'grey'}
      data-test-id="btn_select_address"
    >
      <div className={styles.item__content}>
        {addressInfo.isNcw ? (
          <NCWalletIcon width={20} height={20} />
        ) : (
          <Icon iconName={'wallet'} width={20} height={20} />
        )}
        <p className={styles.content__text}> {addressName}</p>
      </div>
    </Button>
  );
};
