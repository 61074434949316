import cn from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

import { useTabsContext } from '@/shared/ui/tabs/tabs-use-context';

import { TabsContext } from './tabs-context';

import styles from './tabs.module.scss';

interface ChildrenProps {
  children?: ReactNode;
  className?: string;
}

interface ITabsProps extends ChildrenProps {
  tab?: string;
  variant?: 'default' | 'filled' | 'reset';
  keepMounted?: boolean;
  onChange?: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
}

export const Tabs = ({
  children,
  tab,
  variant = 'default',
  keepMounted = true,
  onChange,
  orientation = 'horizontal',
}: ITabsProps) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(tab);

  useEffect(() => {
    setActiveTab(tab); // Sync activeTab with currentTab
  }, [tab, setActiveTab]);

  const handleChange = (value: string) => {
    if (value === activeTab) return;
    setActiveTab(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <TabsContext.Provider value={{ activeTab, onChange: handleChange, variant, keepMounted, orientation }}>
      <div className={cn(styles.root, styles[variant])}>{children}</div>
    </TabsContext.Provider>
  );
};

export const TabsList = ({ children, className }: ChildrenProps) => {
  const context = useTabsContext();
  const variant = context.variant;
  const orientation = context.orientation;
  return <div className={cn(styles.list, styles[variant], styles[orientation], className)}>{children}</div>;
};

interface ITabsTab extends ChildrenProps {
  value: string;
  dataTestId?: string;
}

export const TabsTab = ({ children, value, className, dataTestId }: ITabsTab) => {
  const context = useTabsContext();
  const variant = context.variant;
  const active = context.activeTab === value;

  const handleActiveTab = () => {
    context.onChange(value);
  };

  return (
    <button
      data-active={active}
      onClick={handleActiveTab}
      className={cn(className, styles.tab, styles[variant], active && styles.active)}
      data-test-id={dataTestId}
    >
      {children}
    </button>
  );
};

export const TabsPanel = ({ children, value, className }: ITabsTab) => {
  const context = useTabsContext();
  const active = context.activeTab === value;
  const content = context.keepMounted ? children : active ? children : null;
  return (
    <div
      className={cn(className, styles.panel, active && styles.active)}
      style={!active ? { display: 'none' } : undefined}
    >
      {content}
    </div>
  );
};

Tabs.Tab = TabsTab;
Tabs.Panel = TabsPanel;
Tabs.List = TabsList;
