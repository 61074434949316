import { Trans, useTranslation } from 'react-i18next';

import { NC_WALLET } from '@/shared/lib/utils/links';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Button } from '@/shared/ui/button';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './reject-ncw-modal.module.scss';

interface RejectNcwModalProps extends ModalCommonProps {
  email: string;
}

export const RejectNcwModal = ({ onClose, isOpen, email }: RejectNcwModalProps) => {
  const { t } = useTranslation();
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="reject-ncw">
      <Close onClick={() => onClose(false)} />
      <IconContainer>
        <NCWalletIcon width={80} height={80} />
      </IconContainer>
      <Container>
        <Content>
          <Title>{t('Connect_NCW.Not_found.Title')}</Title>
          <Description>
            <Trans
              i18nKey={t('Connect_NCW.Not_found.Description')}
              components={{ b: <strong className={styles.text_break} /> }}
              values={{ email }}
            />
          </Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            onClick={() => onClose(false)}
            as="a"
            href={NC_WALLET}
            target="_blank"
            data-test-id="link_ncw_modal"
          >
            {t('Connect_NCW.Create.Btn')}
          </Button>
          <Button color="grey" fullWidth onClick={() => onClose(false)} data-test-id="btn_close_footer_modal">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
