import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGoogleCurrentPlan, useGoogleHashrate, useGoogleMiningDuration } from '@/page/cart/store/cart-google-store';
import { Discount } from '@/page/cart/ui/total-price/discount';
import { Tag } from '@/shared/lib/constants/tags';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { formatSpeed } from '@/shared/lib/utils/getFormatSpeed';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { selectUser } from '@/shared/store/user/user.slice';
import { Icon } from '@/shared/ui/icons';
import { Typography } from '@/shared/ui/typography';

import s from './subscription-total-price.module.scss';

export const SubscriptionTotalPrice = () => {
  const { t } = useTranslation();
  const miningDuration = useSelector(useGoogleMiningDuration);
  const hashrate = useSelector(useGoogleHashrate);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const { tags } = useSelector(selectUser);
  const { openModal } = useModalSet();

  const openHshModal = useCallback(() => {
    openModal('MODAl_INFO_BONUS_HSH', { isServices: true });
  }, [openModal]);

  return (
    <div className={s.container}>
      <div className={s.totalPriceRows}>
        <div className={s.row}>
          <Typography variant="h6" weight="bold" className={s.row__typography}>
            <Icon iconName="cartSell" height={20} width={20} />
            {t('OrdDetails.Title')}
          </Typography>
        </div>
        <div className={s.row}>
          <div className={s.priceRow}>
            <Typography variant="h5" weight="bold">
              <span className={s.currentMiner} data-test-id="text_total_price_info">
                Pool Miner {hashrate && formatSpeed(hashrate)} ({miningDuration} {t('mo.')})
              </span>
            </Typography>
            <div className={s.priceContainer} data-test-id="text_total_price_now">
              <Typography variant="h3">
                {currentPlan && getLocalAmount(currentPlan?.total_price, currentPlan?.currentCurrency)}
              </Typography>
            </div>
          </div>
          <div className={s.priceContainer}>
            <Icon iconName="offer" height={20} width={20} />
            {t('Regular Price')}
            <div className={s.oldPrice} data-test-id="text_total_price_old">
              {currentPlan && getLocalAmount(currentPlan?.old_price, currentPlan?.currentCurrency)}
            </div>
          </div>
        </div>

        {currentPlan && currentPlan?.discount > 0 && (
          <div className={s.flex}>
            <Icon iconName="percent" width={20} height={20} className={s.green} />
            <span className={s.discounts}>{t('N.YourDiscount.Title')}</span>
            <Discount
              discount={currentPlan?.discount}
              promoDiscount={currentPlan?.promo_discount}
              data-test-id="text_total_price_discount"
            />
          </div>
        )}

        {currentPlan && currentPlan?.discount > 0 && (
          <div className={s.flex}>
            <Icon iconName="check-blue" width={20} height={20} />
            <span className={s.discounts}>{t('Purchase_scr.Total_Discount.Title')}</span>
            <div className={s.savePrice}>
              <p data-test-id="text_total_price_save">{`${currentPlan && getLocalAmount(currentPlan?.save_price, currentPlan?.currentCurrency)}`}</p>
            </div>
          </div>
        )}
      </div>
      {(currentPlan?.hsh_reward || currentPlan?.hps_bonus) && (
        <div className={s.bonuses}>
          <div className={s.bonus}>
            {currentPlan?.hsh_reward ? (
              <>
                <Icon iconName="hsh" width={20} height={20} />
                <div>
                  <span className={cn(s.bonus__title, s[`bonus__title--hsh`])} onClick={openHshModal}>
                    {t('HashCoins')}
                  </span>
                  {currentPlan?.tags?.includes(Tag.BONUS_TAG_20) && !tags?.includes(Tag.FEB_25) && (
                    <span className={s[`bonus__double--hsh`]}> (x2)</span>
                  )}
                </div>
                <Discount
                  promoDiscount={0}
                  discount={0}
                  bonusHSH={currentPlan?.hsh_reward}
                  data-test-id="text_total_price_bonus"
                />
                <Icon
                  iconName={'question'}
                  width={20}
                  height={20}
                  className={s.bonus__title_question}
                  onClick={openHshModal}
                />
              </>
            ) : (
              <>
                {currentPlan?.tags?.includes(Tag.BONUS_TAG_20) ? (
                  <>
                    <Icon iconName={'purple-gift-x2'} width={20} height={20} />
                    <span className={s.bonus__title}>
                      {t('hps_increment')} <span className={s[`bonus__double--hps`]}> (x2)</span>
                    </span>
                    <Discount
                      promoDiscount={0}
                      discount={0}
                      double={currentPlan?.hps_bonus}
                      data-test-id="text_total_price_bonus"
                    />
                  </>
                ) : (
                  <>
                    <Icon iconName={'blue-gift'} width={20} height={20} />
                    <span className={s.bonus__title}>{t('hps_increment')}</span>
                    <Discount
                      promoDiscount={0}
                      discount={0}
                      bonus={currentPlan?.hps_bonus}
                      data-test-id="text_total_price_bonus"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
