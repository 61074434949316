import { useCallback, useState } from 'react';

import { EXPLORE_PRODUCTS_NOTIFICATION } from '@/shared/lib/constants/storage-key';

export const useExploreProductsNotification = () => {
  const [visible, setVisible] = useState(localStorage.getItem(EXPLORE_PRODUCTS_NOTIFICATION) !== 'true');

  const close = useCallback(() => {
    setVisible(false);
    localStorage.setItem(EXPLORE_PRODUCTS_NOTIFICATION, 'true');
  }, []);

  return {
    visible,
    close,
  };
};
