import React, { ReactNode, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Header } from '@/entities/header/header';
import { selectIsMobile } from '@/features/theme/model/slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useScrollRestoration } from '@/shared/lib/hooks/useScrollRestoration';
import { MetricsService } from '@/shared/lib/metrics';
import { log } from '@/shared/lib/utils/log';
import { getModal } from '@/shared/store/content/content.slice';
import { getLanguage } from '@/shared/store/language';
import { selectRpcStatus } from '@/shared/store/rpc/rpc.slice';
import { selectTutorial } from '@/shared/store/tutorial/tutorial.slice';
import { AppDispatch } from '@/shared/store/types';
import AnimLoader from '@/shared/ui/loader/images/loader-anim.svg?react';

import styles from './layout.module.scss';

type TProps = {
  children?: ReactNode;
  navigation?: ReactNode;
  title?: string;
};

export function Layout({ children, navigation, title }: TProps) {
  useScrollRestoration();
  const rpcStatus = useSelector(selectRpcStatus);
  const isMobileState = useSelector(selectIsMobile);
  const { isActive: isActiveTutorial } = useSelector(selectTutorial);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowLayoutLocker, setShouldShowLayoutLocker] = useState(false);
  const navigate = useNavigate();
  const { isTelegramMiniApp } = useDevice();
  const { pathname } = useLocation();
  const offlineTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errorTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [deviceInfoReady, setDeviceInfoReady] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = getLanguage();
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();

  useEffect(() => {
    if (isTelegramMiniApp !== undefined && isMobileState !== undefined) {
      setDeviceInfoReady(true);
    }
  }, [isTelegramMiniApp, isMobileState]);

  useEffect(() => {
    const handleOnline = () => {
      log('App is back online');
      rpc.reopen().then();
    };

    const handleOffline = () => {
      setShouldShowLayoutLocker(true);
      log('App is going offline');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup function
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (offlineTimerRef.current) {
        clearTimeout(offlineTimerRef?.current);
        offlineTimerRef.current = null;
      }
    };
  }, [navigate]);

  useEffect(() => {
    // Start or clear the timer based on isLoading
    if (isLoading) {
      if (!offlineTimerRef.current) {
        offlineTimerRef.current = setTimeout(() => {
          offlineTimerRef.current = null;
          log('App is offline after delay');
          MetricsService.sendEvent('socket_unreachable', { url: pathname }).then();
          openModal('MODAL_ERROR_CONNECTION', { isServices: true });
        }, 10 * 1000); // 1 min
      }
    } else {
      if (offlineTimerRef.current) {
        clearTimeout(offlineTimerRef.current);
        offlineTimerRef.current = null;
      }
    }

    // Cleanup function to clear the timer when the component unmounts or isLoading changes
    return () => {
      if (offlineTimerRef.current) {
        clearTimeout(offlineTimerRef.current);
        offlineTimerRef.current = null;
      }
    };
  }, [isLoading, navigate]);

  useEffect(() => {
    const statusCheck = rpcStatus !== 'open' && rpcStatus !== '';

    setShouldShowLayoutLocker(statusCheck);

    if (errorTimerRef.current) {
      clearTimeout(errorTimerRef.current);
      errorTimerRef.current = null;
    }
    if (statusCheck) {
      errorTimerRef.current = setTimeout(() => {
        errorTimerRef.current = null;
        MetricsService.sendEvent('socket_unreachable', { url: pathname }).then();
        openModal('MODAL_ERROR_CONNECTION', { isServices: true });
      }, 60 * 1000); // 1 min
    } else {
      setIsLoading(false);
    }

    return () => {
      if (errorTimerRef.current) {
        clearTimeout(errorTimerRef.current);
        errorTimerRef.current = null;
      }
    }; // Clear the timeout if the component unmounts or the status changes
  }, [rpcStatus, navigate, pathname]);

  const handleLockerClick = () => {
    setIsLoading(true);
    rpc.reopen().then();
  };

  useEffect(() => {
    if (!isTelegramMiniApp) return;

    if (+Telegram?.WebApp?.version >= 8) {
      Telegram?.WebApp?.lockOrientation();
    }
  }, [isTelegramMiniApp]);

  useEffect(() => {
    if (!isTelegramMiniApp) return;

    if (pathname !== '/') {
      Telegram?.WebApp?.BackButton.show();
    } else {
      Telegram?.WebApp?.BackButton.hide();
    }

    const handleBackButtonClick = () => {
      navigate(-1);
    };

    Telegram?.WebApp?.BackButton.onClick(handleBackButtonClick);

    return () => {
      Telegram?.WebApp?.BackButton.offClick(handleBackButtonClick);
    };
  }, [isTelegramMiniApp, navigate, pathname]);

  // tutorial scroll lock
  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault();
    };

    if (isActiveTutorial) {
      window.addEventListener('wheel', preventDefault, { passive: false });
      window.addEventListener('mousewheel', preventDefault, { passive: false });
      window.addEventListener('touchmove', preventDefault, { passive: false });
      document.documentElement.classList.add('tutorial');
    }

    return () => {
      window.removeEventListener('wheel', preventDefault);
      window.removeEventListener('mousewheel', preventDefault);
      window.removeEventListener('touchmove', preventDefault);
      document.documentElement.classList.remove('tutorial');
    };
  }, [isActiveTutorial]);

  useEffect(() => {
    (async () => {
      if (searchParams.has('modal')) {
        const modalId = searchParams?.get('modal');

        searchParams.delete('modal');
        setSearchParams(searchParams);

        if (modalId) {
          dispatch(getModal({ openModal, lang, id: modalId }));
        }
      }
    })();
  }, [dispatch, lang]);

  const handleClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const elemWithData = target?.closest('[data-test-id]') as HTMLElement;
    const dataTestId = elemWithData?.dataset?.testId;

    if (dataTestId) {
      MetricsService.sendEvent('screen_tap', {
        data_test_id: dataTestId,
        page_x: e.pageX,
        page_y: e.pageY,
        domain: 'activity',
        url: pathname,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <>
      {deviceInfoReady && (
        <div className={styles.root} style={{ zIndex: shouldShowLayoutLocker ? 9999 : 'auto' }}>
          {!isMobileState && !isTelegramMiniApp ? <Header headerTitle={title} /> : null}
          {shouldShowLayoutLocker ? (
            <div
              className={`${styles.locker} ${isLoading ? styles.loading : ''}`}
              onClick={handleLockerClick}
              style={{
                backgroundColor: isLoading ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0)',
              }}
            >
              {isLoading && <AnimLoader />}
            </div>
          ) : null}
          <main className={styles.main} style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
            {children}
          </main>
          {navigation}
        </div>
      )}
    </>
  );
}
