import { ReactNode } from 'react';

import s from './card.module.scss';

interface CardProps {
  gap?: string;
  children: ReactNode;
}

export const Card = ({ children, gap }: CardProps) => {
  return (
    <div className={s.card} style={{ gap }}>
      {children}
    </div>
  );
};
