import cn from 'classnames';
import type { ComponentPropsWithoutRef, HTMLAttributes } from 'react';

import s from './typography.module.scss';

// All valid HTML tags
type ValidTags = 'div' | 'span' | 'strong' | 'a' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'subtitle1' | 'subtitle2';

type DefaultVariant = 'body1';

const VARIANT_MAPPING: Record<Variant, ValidTags> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'p',
  subtitle2: 'p',
  body1: 'span',
  body2: 'span',
};

// Generic type to generate HTML props based on its tag
type CustomTagProps<T extends ValidTags> = {
  tag?: T | ValidTags;
  variant?: Variant;
  weight?: 'bold' | 'normal';
} & (ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLOrSVGElement>);

export function Typography<T extends ValidTags = (typeof VARIANT_MAPPING)[DefaultVariant]>({
  variant = 'body1' as DefaultVariant,
  tag = VARIANT_MAPPING[variant],
  children,
  className,
  weight,
  ...rest
}: CustomTagProps<T>) {
  const Tag: ValidTags = tag;

  return (
    <Tag {...rest} className={cn(className, s[variant], weight && s[weight])}>
      {children}
    </Tag>
  );
}
