import cn from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setHashrate, useHashrate, useHashrates } from '@/page/cart/store';
import { MegaSaleBanner } from '@/page/cart/ui/mega-sale-banner/mega-sale-banner';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AppDispatch } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { InputRange } from '@/shared/ui/input-range';

import { formatHashrate } from './hashrate.util';

import s from './hashrate.module.scss';

type HashrateProps = {
  maxHashrate?: number;
};

export const Hashrate = ({ maxHashrate }: HashrateProps) => {
  const { tags: userTags } = useSelector(selectUser);
  const hashrates = useSelector(useHashrates);
  const hashrate = useSelector(useHashrate);
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { isTelegramMiniApp } = useDevice();
  const dispatch: AppDispatch = useDispatch();
  const formattedHashrate = formatHashrate(hashrate);
  const currentValue = hashrates?.indexOf(hashrate);
  const minValue = 0;
  const maxValue = maxHashrate || hashrates?.length - 1;
  const isMegaSaleShow = userTags?.includes('promo_11_11_24');

  const handleChange = (value: number) => {
    dispatch(setHashrate({ hashrate: hashrates[value] }));
  };

  useEffect(() => {
    if (hashrate > hashrates[maxValue]) dispatch(setHashrate({ hashrate: hashrates[maxValue] }));
  }, [dispatch, maxValue]);

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.top__item}>
          <Icon iconName="speed" className={s.top__icon} width={20} height={20} />
          {t('choose hashrate')}
        </div>
        {isTelegramMiniApp ? (
          <div>
            <Button
              className={s.btnInfo}
              variant="text"
              color="blue"
              onClick={() => openModal('MODAL_INFO_POOL', { isServices: true })}
            >
              <Icon iconName="info" width="20" height="20" />
            </Button>
          </div>
        ) : null}
      </div>
      <div className={s.hashrateContainer}>
        <span className={cn(s.hashrateValue)} data-test-id="text_hashrate">
          {formattedHashrate}
        </span>
        <div className={s.hashrateRangeContainer}>
          <InputRange
            value={currentValue}
            onChange={handleChange}
            min={minValue}
            max={maxValue}
            dataTestId="slider_hashrate"
          />
        </div>
      </div>
      {isMegaSaleShow ? <MegaSaleBanner /> : null}
    </div>
  );
};
