import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UrlCartTab } from '@/page/cart/hooks/use-init-url-params';
import { modals, ModalName } from '@/shared/lib/context/modal/modal-render';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { CART_PATH } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';

import styles from './modal-debug.module.scss';

interface modalsProps {
  // eslint-disable-next-line
  [key: string]: any;
}

export const ModalDebug = () => {
  const { openModal } = useModalSet();
  const { isTelegramMiniApp } = useDevice();
  const navigate = useNavigate();

  const modalsProps: modalsProps = useMemo(
    () => ({
      MODAL_ADDRESS_INFO: {
        addresses: [{ address: 'qe' }, { email: 'brownale@gmail.com' }, { account_id: 'qwe', email: '' }],
        currentAddress: 'fdsf',
        onClick: () => toaster.error('Это замоканая модалка - функционал недоступен'),
        setIsNcw: () => console.log('Это замоканая модалка - функционал недоступен'),
        userAccountEmail: 'brownale@gmail.com',
      },
      MODAL_ERROR_FUNDS_WITHDRAWAL: {
        isMining: true,
      },
      MODAL_ERROR: {
        error: { code: 404, message: 'пупупу' },
      },
      MODAL_CANCEL_WITHDRAWAL: {
        amount: 100,
        currency: 'HSH',
        onCancel: () => toaster.error('Это замоканая модалка - функционал недоступен'),
      },
      MODAL_SUCCESS_CANCEL_WITHDRAWAL: {
        amount: 100,
        currency: 'HSH',
      },
      MODAL_SWITCH_BALANCE_CONFIRM: {
        activeCurrency: 'BTC',
        conversionData: {
          fernet:
            'gAAAAABnybHHEdw7yCnP062WcTY7QRm0CJi8hqLaTR0bXWDVqx1Zv87qt9o6gKOij6mgJDn11VG6XNoVfv8uRlS_7vsOulzfsznVB5ONIQNqBYiWoPLa6g6cQyQgBj6GKS01lqchInAAyrEufWDh_C96wSZByB5-BA==',
          from_currency: 'BTC',
          rate: '40.0320256204963971',
          to_currency: 'ETH',
        },
        currencyBalance: getLocalAmount(100),
      },
      MODAL_NC_WALLET_CONFIRM: {
        email: 'brownale@gmail.com',
        userId: 4238948209384,
      },
      MODAL_NC_WALLET_REJECT: {
        email: 'brownale@gmail.com',
      },
      MODAL_POOL_MINER_ACTIVE: {
        miner: {
          id: 'qertuierutiouq',
          hps: 50000,
          hsh_reward: 100,
          hps_bonus: 100,
          create_time: new Date().getTime(),
          created: false,
          end_time: new Date(new Date().getTime() + 5798393875).getTime(),
          deleted: false,
          is_google_subscription: false,
          product_id: 'ooooooo',
        },
      },
    }),
    []
  );

  const modalsWithProps = useMemo(() => {
    // eslint-disable-next-line
    const res: Array<{ name: ModalName; props: { [key: string]: any } }> = [];

    for (const modalName in modals) {
      if (isTelegramMiniApp && modalName === 'MODAL_RATE_US') break;

      const props = modalsProps[modalName] || {};

      res.push({
        name: modalName as ModalName,
        props,
      });
    }

    return res;
  }, []);

  return (
    <div className={styles.container}>
      {modalsWithProps?.map((modal) => (
        <Button
          color="blue"
          fullWidth
          key={modal.name}
          onClick={() => openModal(modal.name, { isServices: true, ...modal.props })}
        >
          {modal.name}
        </Button>
      ))}
      <div className={styles.container}>
        <h6>Cart actions</h6>
        <Button
          color="grey"
          variant="filled"
          fullWidth
          onClick={() => navigate(`${CART_PATH}?p=${UrlCartTab.Telegram}&m=18&r=500`)}
        >
          To cart Telegram
        </Button>
        <Button
          color="grey"
          variant="filled"
          fullWidth
          onClick={() => navigate(`${CART_PATH}?p=${UrlCartTab.Fiat}&m=12&r=300`)}
        >
          To cart Fiat
        </Button>
        <Button
          color="grey"
          variant="filled"
          fullWidth
          onClick={() => navigate(`${CART_PATH}?p=${UrlCartTab.Crypto}&m=18&r=500`)}
        >
          To cart Crypto
        </Button>
      </div>
    </div>
  );
};
