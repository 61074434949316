import Tippy from '@tippyjs/react';
import cn from 'classnames';
import { TFunction } from 'i18next';
import React, {
  DetailedReactHTMLElement,
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getScrollToCallback, getScrollOffset } from '@/shared/lib/hooks/useScrollTo';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { useWindowSize } from '@/shared/lib/hooks/useWindowWidth';
import { TPoolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { endTutorial, nextTutorialStep, selectTutorial, setClientRect } from '@/shared/store/tutorial/tutorial.slice';
import { RootState } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import styles from './tutorial.module.scss';

const getStepTitle = (t: TFunction, step: string) => {
  const stepTitleMap = {
    hashrate: <Trans i18nKey="CTP.Tutor.Step_1" components={{ b: <strong /> }} />,
    balance: <Trans i18nKey="CTP.Tutor.Step_2" components={{ b: <strong /> }} />,
    withdraw: <Trans i18nKey="CTP.Tutor.Step_3" components={{ b: <strong /> }} />,
    currency: <Trans i18nKey="CTP.Tutor.Step_4" components={{ b: <strong /> }} />,
    addPoolButton: <Trans i18nKey="CTP.Tutor.Step_5" components={{ b: <strong /> }} />,
    startButton: <Trans i18nKey="CTP.Tutor.Step_6" components={{ b: <strong /> }} />,
  }[step];

  return stepTitleMap || `[${step}]`;
};

export type TutorialProps = PropsWithChildren<{
  steps?: string[];
  step?: string;
  fixed?: boolean;
  arrow?: 'top' | 'left' | 'right' | 'bot' | 'middle' | 'middle-left';
  position?: 'top-end' | 'top' | 'top-start' | 'bottom';
  right?: boolean;
  viewBlock: HTMLDivElement | null;
  needFix?: boolean;
}>;

export function Tutorial({ children, step, steps, arrow, right, position, fixed, viewBlock, needFix }: TutorialProps) {
  steps = step ? [step] : steps || [];
  const { t } = useTranslation();
  const { mining_currencies } = useSelector((store: RootState) => store.balance);
  const current = useSelector(selectTutorial);
  const currentStep = current.step;
  const clientRect = current.clientRect;
  const [count, setCount] = useState(0);
  const ref = useRef<Element>(null);
  const [, setForceUpdate] = useState(0);
  const { markOnboardingSeen } = useTutorialStorage();
  const miners = useSelector((store: { pool_miner: TPoolMiner }) => store.pool_miner.list);
  const checked = useSelector((store: { pool_miner: TPoolMiner }) => store.pool_miner.checked);
  const tutorialSteps = [
    'hashrate',
    'balance',
    'withdraw',
    ...(mining_currencies?.length > 1 ? ['currency'] : []),
    'addPoolButton',
    ...(miners.length > 0 && !checked ? ['startButton'] : []),
  ];

  const customChildren = useMemo(() => {
    const child = React.Children.only(children) as DetailedReactHTMLElement<HTMLAttributes<HTMLElement>, HTMLElement>;
    return React.cloneElement(child, {
      style: {
        position: fixed ? 'fixed' : 'relative',
      },
      className: child.props.className + ` ${styles.background}`,
    });
  }, [children, fixed]);

  const dispatch = useDispatch();
  const mobileSize = useWindowSize(768);
  const currentStepName = tutorialSteps[current.step];
  const show = steps.includes(currentStepName);
  const stepTitle = getStepTitle(t, currentStepName);

  useEffect(() => {
    if (count === 0) {
      setCount((prev) => prev + 1);
    }
  }, [currentStep, count]);

  const initPosition = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();

    if (rect) {
      const scrollOffset = getScrollOffset(viewBlock, 10, currentStepName);

      getScrollToCallback(scrollOffset, () => {
        const updatedRect = ref.current?.getBoundingClientRect();
        if (updatedRect) {
          dispatch(setClientRect(updatedRect.toJSON()));
        }
      });
    }
  }, [ref, dispatch, viewBlock, currentStepName]);

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', initPosition);

      return () => {
        window.removeEventListener('resize', initPosition);
      };
    }
  }, [ref.current]);

  useEffect(() => {
    if (!show) return;

    // The timer is used to wait for the initialization of styles and other rendering effects to ensure that getBoundingClientRect returns accurate values
    const timer = setTimeout(initPosition, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [show, initPosition]);

  useEffect(() => {
    if (!show) return;

    // workaround for tippyjs glitch
    const interval = setInterval(() => setForceUpdate((prev) => prev + 1), 500);

    return () => {
      clearInterval(interval);
    };
  }, [show]);

  if (!show) return <>{children}</>;

  return (
    <>
      <Tippy
        zIndex={10}
        render={() => (
          <div
            className={styles.tippy}
            onClick={(e) => {
              const target = e.target as HTMLElement;
              if (!target?.className?.includes('_button_')) {
                e.stopPropagation();
              }
            }}
          >
            <p className={styles.text}>{stepTitle}</p>
            <div className={styles.bottom}>
              {tutorialSteps.length > 1 && (
                <div className={styles.count}>
                  <strong>{current.step + 1}</strong> / {tutorialSteps.length}
                </div>
              )}
              <div className={styles.btnWrap}>
                {current.step + 1 === tutorialSteps.length ? (
                  <Button
                    className={cn(styles.btn)}
                    variant="text"
                    color="blue"
                    onClick={() => {
                      markOnboardingSeen();
                      dispatch(endTutorial());
                    }}
                    data-test-id="btn_close_tutorial"
                  >
                    {t('TutorialTknAdded.Btn3')}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="text"
                      onClick={() => {
                        markOnboardingSeen();
                        dispatch(endTutorial());
                      }}
                      className={cn(styles.skip, styles.btn)}
                      data-test-id="btn_skip_tutorial"
                    >
                      {t('TutorialTknAdded.Btn1')}
                    </Button>
                    <Button
                      color="blue"
                      variant="text"
                      onClick={() => dispatch(nextTutorialStep())}
                      className={styles.btn}
                      data-test-id="btn_next_tutorial"
                    >
                      {t('TutorialTknAdded.Btn2')}
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div
              className={cn(
                styles.arrow,
                {
                  [styles.bot]: arrow === 'bot',
                  [styles.top]: arrow === 'top',
                  [styles.left]: arrow === 'left',
                  [styles.right]: arrow === 'right',
                  [styles.middle]: arrow === 'middle',
                  [styles.middleLeft]: arrow === 'middle-left',
                },
                needFix && styles.right_fix
              )}
            >
              {mobileSize && <Icon iconName="arrow-tutorial" width={17} height={57} />}
            </div>
          </div>
        )}
        visible={true}
        interactive={true}
        offset={({ popper }) => {
          if (mobileSize) return [0, 80];
          return [right ? popper?.width + 30 : -popper?.width + 30, popper?.height / 2];
        }}
        popperOptions={{
          modifiers: [{ name: 'flip', enabled: false }],
        }}
        ref={ref}
        placement={position}
        key={currentStepName}
      >
        {customChildren}
      </Tippy>
      <div className={styles.overlay} onClick={(e) => e.stopPropagation()}>
        <svg
          style={{ width: '100%', height: '100%' }}
          className={styles.svg}
          key={`${currentStepName}-${clientRect?.x}-${clientRect?.y}`}
        >
          <defs>
            <mask id="ant-tour-mask-:r1:">
              <rect x="0" y="0" width="100vw" height="100vh" fill="white"></rect>
              <rect
                x={clientRect ? clientRect.x - 5 : 0}
                y={clientRect ? clientRect.y - 5 : 0}
                rx="15"
                width={clientRect ? clientRect.width + 10 : 0}
                height={clientRect ? clientRect.height + 10 : 0}
                fill="black"
                className={styles.tutorial__window}
              ></rect>
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="100%" fill="rgba(0,0,0,0.7)" mask="url(#ant-tour-mask-:r1:)"></rect>
        </svg>
      </div>
    </>
  );
}
