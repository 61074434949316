import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './locale/de.json';
import en from './locale/en.json';
import es from './locale/es.json';
import fr from './locale/fr.json';
import it from './locale/it.json';
import pt from './locale/pt.json';
import ru from './locale/ru.json';

const resources = {
  en: { translation: en },
  ru: { translation: ru },
  de: { translation: de },
  es: { translation: es },
  fr: { translation: fr },
  it: { translation: it },
  pt: { translation: pt },
} as const;

i18next
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    compatibilityJSON: 'v3',
    supportedLngs: Object.keys(resources),
    fallbackLng: 'en',
    detection: {
      caches: ['localStorage'],
    },
    resources,
  })
  .then();
