import { useTranslation } from 'react-i18next';

import { Social } from '@/shared/ui/social/social';

import styles from './join-us.module.scss';

export const JoinUs = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.block}>
          <h6 className={styles.title}>{t('join us')}</h6>
          <div className={styles.social}>
            <p className={styles.text}>{t('Subscribe.text')}</p>
            <div>
              <Social />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
