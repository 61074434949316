import { build, version } from '@/shared/lib/constants/version';

import {
  EventDetails,
  EventPayload,
  LauidResponse,
  MetricsStorageKeys,
  RequestParams,
  SessionMetadata,
  EventName,
} from './metrics.models';

export class MetricsService {
  // params это объект, который содержит параметры, которые требуется для отправки на сервер
  // параметры по умолчанию, после инициализации будут перезаписаны
  private static defaultParams: RequestParams = {
    app_id: 'net.ctpool',
    platform: window?.ct?.platform,
    v: window?.ct?.appVersion || '1.0.0',
    av: `${version}.${build}`,
  };

  // Сделаем промис, который никогда не будет разрешен, пока мы явно не сделаем это
  private static initializedResolve: () => void;
  private static initialized = new Promise<void>((resolve) => {
    MetricsService.initializedResolve = resolve;
  });

  private static lauid: Promise<string | null> | null = null;
  private static deltaTimestamp: number = 0;

  // sessionMetadata это объект, который содержит информацию о текущей сессии пользователя (например, user_id и т.д.)
  private static sessionMetadata: SessionMetadata = {};

  // initialize это статический метод, который инициализирует сервис метрик
  // перед вызовом этого метода необходимо установить необходимые параметры и метаданные сессии
  static initialize(): void {
    this.initializedResolve();
    this.sendQueuedEvents();
  }

  static compareTimestamp(serverTimestamp: number) {
    this.deltaTimestamp = serverTimestamp - Date.now() / 1000;
  }

  static setParam(param: keyof RequestParams, value: string): void {
    this.defaultParams[param] = value;
  }

  static setSessionMeta<K extends keyof SessionMetadata>(key: K, value: SessionMetadata[K]): void {
    this.sessionMetadata[key] = value;
  }

  static async getLauid(): Promise<string | null> {
    await this.initialized;

    if (!this.lauid) {
      const queryParams = new URLSearchParams(this.defaultParams);
      this.lauid = fetch(`${window.app_config.baseAPIURL}app_stats?${queryParams}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ meta: this.sessionMetadata }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return null;
        })
        .then((data: LauidResponse) => {
          if (data?.timestamp) {
            this.compareTimestamp(data.timestamp);
          }
          return data.lauid;
        })
        .catch(() => {
          return null;
        });
    }

    return this.lauid;
  }

  private static retrieveQueuedEvents(): EventDetails[] {
    const storedEvents = localStorage.getItem(MetricsStorageKeys.QUEUE);
    return storedEvents ? JSON.parse(storedEvents) : [];
  }

  private static queueEvent(event: EventDetails): void {
    const queuedEvents = this.retrieveQueuedEvents();
    const isDuplicate = queuedEvents.some(
      (existingEvent) =>
        existingEvent.name === event.name && JSON.stringify(existingEvent.meta) === JSON.stringify(event.meta)
    );

    if (!isDuplicate) {
      queuedEvents.push(event);
      localStorage.setItem(MetricsStorageKeys.QUEUE, JSON.stringify(queuedEvents));
    }
  }

  private static async sendEvents(events: EventDetails[]): Promise<void> {
    const lauid = await this.getLauid();
    if (!lauid || events.length === 0) return;

    try {
      const payloads: EventPayload[] = events.map((event) => ({
        lauid,
        name: event.name,
        meta: event.meta,
        client_created_at: event.createdAt + this.deltaTimestamp,
      }));

      const queryParams = new URLSearchParams(this.defaultParams);
      const apiUrl = `${window.app_config.baseAPIURL}app_stats/events?${queryParams.toString()}`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-lauid': lauid },
        body: JSON.stringify({ events: payloads }),
      });

      if (!response.ok) {
        throw new Error(`Failed to send errors: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private static async sendQueuedEvents(): Promise<void> {
    const queuedEvents = this.retrieveQueuedEvents();
    try {
      await this.sendEvents(queuedEvents);
      localStorage.removeItem(MetricsStorageKeys.QUEUE);
    } catch {
      // Do nothing, events remain queued
    }
  }

  static async sendEvent(eventName: EventName, metadata: Record<string, unknown>): Promise<void> {
    const eventDetails: EventDetails = {
      name: eventName,
      createdAt: Date.now() / 1000,
      meta: { ...metadata, url: metadata?.url || window.location.href },
    };

    try {
      await this.sendEvents([eventDetails]);
    } catch {
      this.queueEvent(eventDetails);
    }
  }
}
