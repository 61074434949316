import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import semver from 'semver';

import useCloudStorage from '@/shared/lib/hooks/useCloudStorage';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { HOME_PATH, POOL_PRIVACY_POLICY, POOL_TERMS, TUTORIAL_PATH } from '@/shared/lib/utils/links';
import { initApp, selectRpcAuthed } from '@/shared/store/rpc/rpc.slice';
import { AppDispatch } from '@/shared/store/types';
import { getAccessTokens } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import topImage from './img/top.png';

import styles from './login-page.module.scss';

const IS_DEMO_ENABLED = true;
const MIN_IOS_APP_VERSION_WITH_DEMO = '1.0.37';

function isAppVersionGte(version: string): boolean {
  const appVersion = window.ct?.appVersion || '1.0.0';
  return semver.gte(appVersion, version);
}

export const LoginPage = () => {
  const [loading, setLoading] = useState<'' | 'apple' | 'google' | 'demo'>('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isTutorialSeen } = useTutorialStorage();
  const hideTutorial = isTutorialSeen();
  const { isReactNativeApp, isTelegramMiniApp, isIos } = useDevice();
  const pathAfterLogin = hideTutorial === 'hide' || isTelegramMiniApp ? HOME_PATH : TUTORIAL_PATH;
  const { setItem, getItem } = useCloudStorage();
  const isAuth = useSelector(selectRpcAuthed);

  useEffect(() => {
    if (isAuth) {
      navigate(pathAfterLogin);
    }
  }, [navigate, isAuth]);

  const handleClick = async (platform: 'apple' | 'google' | 'demo') => {
    if (isReactNativeApp || isTelegramMiniApp) {
      try {
        setLoading(platform);

        // Dispatch getAccessTokens for the respective platform
        const result = await dispatch(getAccessTokens({ platform }));

        if (getAccessTokens.fulfilled.match(result)) {
          // If login is successful, initialize the app
          dispatch(
            initApp({
              isTelegramMiniApp,
              isReactNativeApp,
              setItem,
              getItem,
            })
          );
        } else if (getAccessTokens.rejected.match(result)) {
          if (result.error.message !== 'Auth cancelled') {
            // Log to Sentry if the error is not caused by user cancellation
            Sentry.captureException(new Error(result.error.message), {
              extra: { platform, reason: result.error.message },
            });
          } else {
            // Handle user cancellation
            console.log('Login was cancelled by the user');
          }
          setLoading('');
        }
        // eslint-disable-next-line
      } catch (e: any) {
        console.error('Unexpected error during login:', e.message);
        setLoading('');
      }
    }
  };

  return (
    <div className={styles.root}>
      <img src={topImage} className={cn(styles.image, styles.top)} alt="" />
      <div className={styles.block}>
        <div className={styles.block__top}>
          <Icon iconName="user-plus" width={80} height={80} className={styles.icon} />
          <h6 className={styles.title}>{t('Log In to CryptoTab')}</h6>
          <p className={styles.text}>{t('MTU.Log_in.Description')}</p>
        </div>
        <div className={styles.block__footer}>
          {isIos && (
            <Button
              onClick={() => handleClick('apple')}
              disabled={loading !== ''}
              loading={loading === 'apple'}
              variant="filled"
              className={styles.btn__apple}
              fullWidth
              justify="space-between"
              borderRadius={false}
              spinnerTheme="static"
              data-test-id="btn_login_apple"
            >
              <Icon iconName="apple-ios" width={20} height={20} />
              {t('SignIn.Apple.Btn')}
              <span className={styles.btn__empty} />
            </Button>
          )}
          <Button
            onClick={() => handleClick('google')}
            disabled={loading !== ''}
            loading={loading === 'google'}
            variant="filled"
            className={styles.btn}
            fullWidth
            justify="space-between"
            borderRadius={false}
            spinnerTheme="static"
            color="white"
            data-test-id="btn_login_google"
          >
            <Icon iconName="google-icon" width={20} height={20} />
            {t('MTU.Log_in.BTN')}
            <span className={styles.btn__empty} />
          </Button>
          {IS_DEMO_ENABLED && isIos && isAppVersionGte(MIN_IOS_APP_VERSION_WITH_DEMO) && (
            <Button
              onClick={() => handleClick('demo')}
              disabled={loading !== ''}
              loading={loading === 'demo'}
              variant="outline"
              className={styles.demoButton}
              fullWidth
              justify="space-between"
              borderRadius={false}
              data-test-id="btn_login_demo"
            >
              <Icon iconName="login-demo" width={20} height={20} />
              {t('Demo')}
              <span className={styles.btn__empty} />
            </Button>
          )}
        </div>
      </div>
      <p className={styles.terms}>
        <Trans
          i18nKey={t('MTU.Log_in.Terms&Policy')}
          components={{
            a: <a href={POOL_TERMS} target="_blank" rel="noreferrer" />,
            a2: <a href={POOL_PRIVACY_POLICY} target="_blank" rel="noreferrer" />,
          }}
        />
      </p>
    </div>
  );
};
