import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import { cloud_boost } from '@/shared/ui/assets/animations/lottie';
import { Button } from '@/shared/ui/button';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import placeholder from './images/placeholder.svg';

import styles from './modal-mining-info.module.scss';

export const ModalMiningInfo = ({ onClose, isOpen }: ModalCommonProps) => {
  const { t } = useTranslation();
  const { Container, Content, Title, Description, Close } = ModalLayout;

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="mining-info-modal">
      <div className={styles.header}>
        <Lottie animationData={cloud_boost} placeholder={placeholder} loop={true} className={styles.header__img} />
        <Close onClick={() => onClose(false)} />
      </div>
      <Container>
        <Content>
          <Title>{t('Pool.What.Title')}</Title>
          <Description className={styles.texts}>
            <span>{t('Pool.What.Description')}</span>
            <span>{t('Pool.What.DescriptionSimple')}</span>
          </Description>
        </Content>
        <Button
          color="blue"
          borderRadius={false}
          fullWidth
          onClick={() => onClose(false)}
          data-test-id="btn_confirm_modal"
        >
          OK
        </Button>
      </Container>
    </ModalLayout>
  );
};
