import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReviewAction } from '@/shared/lib/backend/JsonRpcApi';
import { useRating } from '@/shared/lib/hooks/useRating';
import { CRYPTO_COMPANY_GOOGLE_RATE } from '@/shared/lib/utils/links';
import { AppDispatch } from '@/shared/store/types';
import { selectUser, userAction } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';
import { Rating } from '@/shared/ui/rating/rating';

import styles from './rate-us-company.module.scss';

export const RateUsCompany = ({ onClose, isOpen }: ModalCommonProps) => {
  const { reviewRatingId, ratingMeta, ratingUrl } = useSelector(selectUser);
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { sendReviewDetails } = useRating();
  const { t } = useTranslation();

  const handleToGoogleClick = useCallback(async () => {
    setLoading(true);
    try {
      await sendReviewDetails({
        action: ReviewAction.Click,
        meta: ratingMeta,
        review_id: reviewRatingId,
      });
      dispatch(userAction.setForceDisableRating(true));
    } catch (error) {
      console.error('Failed to send review details:', error);
    } finally {
      setLoading(false);
      window.open(ratingUrl || CRYPTO_COMPANY_GOOGLE_RATE);
      onClose(false);
    }
  }, [dispatch, onClose, ratingMeta, ratingUrl, reviewRatingId, sendReviewDetails]);

  const onCloseModal = useCallback(async () => {
    setLoading(true);
    try {
      await sendReviewDetails({
        action: ReviewAction.Close,
        meta: ratingMeta,
        review_id: reviewRatingId,
      });
      dispatch(userAction.setForceDisableRating(true));
    } catch (error) {
      console.error('Failed to send review details:', error);
    } finally {
      setLoading(false);
      onClose(false);
    }
  }, [dispatch, onClose, sendReviewDetails, ratingMeta, reviewRatingId]);

  return (
    <ModalLayout onClose={onCloseModal} isOpen={isOpen} id="rate-us-company-modal">
      <IconContainer>
        <Icon iconName="company" width={80} height={80} />
        <div className={styles.marketRating}>
          <Rating noNumbers onChange={() => {}} value={5} size={24} />
        </div>
      </IconContainer>
      <Close onClick={onCloseModal} />
      <Container>
        <Content>
          <Title>{t('RateCompany.Title')}</Title>
          <Description>{t('RateCompany.Description')}</Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            onClick={handleToGoogleClick}
            loading={loading}
            data-test-id="btn_confirm_modal"
          >
            {t('RateCompany.Btn')}
          </Button>
          <Button color="grey" fullWidth onClick={onCloseModal} data-test-id="btn_close_footer_modal">
            {t('Select_Reviews.Btn2')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
