import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BONUS, MINING } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { CART_PATH, CRYPTO_TAB_PRODUCTS_PATH, HOME_PATH, MENU_PATH, WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';

import styles from './naavigation.module.scss';

export const Navigation = () => {
  const { is_mining } = useSelector((store: RootState) => store.balance);
  const { isIos } = useDevice();

  return (
    <div className={styles.root}>
      <div className={styles.menuContainer}>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
          state={{ name: 'dashboard' }}
          to={HOME_PATH}
          data-test-id="link_dashboard"
        >
          <Icon iconName="dashboard" width="20" height="20" />
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
          state={{ name: 'Add Pool Miner' }}
          to={CART_PATH}
          data-test-id="link_mining"
        >
          <Icon iconName="cartPlus" width="20" height="20" />
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
          state={{ name: 'Withdraw' }}
          to={`${WITHDRAW_PATH}/${is_mining ? MINING : BONUS}`}
          data-test-id="link_withdraw"
        >
          <Icon iconName="wallet" width="20" height="20" />
        </NavLink>
        {!isIos && (
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : styles.link)}
            state={{ name: 'CryptoTab Products' }}
            to={CRYPTO_TAB_PRODUCTS_PATH}
            data-test-id="link_products"
          >
            <Icon iconName="ct-products" width="20" height="20" />
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
          to={MENU_PATH}
          data-test-id="link_menu"
        >
          <Icon iconName="menu" width="20" height="20" />
        </NavLink>
      </div>
    </div>
  );
};
