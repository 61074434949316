import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CONFIRMED_EMAIL } from '@/shared/lib/constants/storage-key';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { AppDispatch } from '@/shared/store/types';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './confirm-ncw-modal.module.scss';

interface ConfirmNcwModalProps extends ModalCommonProps {
  email: string;
  userId: string;
}

export const ConfirmNcwModal = ({ onClose, isOpen, email, userId }: ConfirmNcwModalProps) => {
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const handleConfirm = () => {
    localStorage.setItem(CONFIRMED_EMAIL, JSON.stringify({ account_id: userId, email: email }));
    dispatch(withdrawalAction.addNewAddress({ account_id: userId, email: email }));
    toaster.success(t('Successfully_Connected'));
    navigate(WITHDRAW_PATH);
  };

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="confirm-ncw">
      <IconContainer>
        <NCWalletIcon width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Connect_NCW.Confirmation.Title')}</Title>
          <Description>
            <Trans
              i18nKey={t('Connect_NCW.Confirmation.Descr')}
              components={{ b: <strong />, b2: <strong className={styles.text_break} /> }}
              values={{ email, accountID: userId }}
            />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={handleConfirm} data-test-id="btn_confirm_modal">
            {t('Balance_currency.Conversion.Confirm.Btn')}
          </Button>
          <Button color="grey" fullWidth onClick={() => onClose(false)} data-test-id="btn_close_footer_modal">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
