import React from 'react';

import { CurrencyButton } from '@/page/switch-balance/ui/currency-button/currency-button';
import { WalletsCurrenciesResponse } from '@/shared/lib/backend/JsonRpcApi';
import { Currencies } from '@/shared/ui/currency-icon';

import styles from './switch-list.module.scss';

interface SwitchListProps {
  error: boolean;
  userCurrency: Currencies;
  currencies: [] | WalletsCurrenciesResponse;
  activeCurrency: string;
  setActiveCurrency: (currency: Currencies | string) => void;
}

export const SwitchList = ({ activeCurrency, setActiveCurrency, error, userCurrency, currencies }: SwitchListProps) => {
  return (
    <ul className={styles.list}>
      {currencies?.map((currency) => (
        <CurrencyButton
          key={currency?.symbol}
          currentCurrency={currency?.symbol === userCurrency}
          active={activeCurrency === currency.symbol}
          iconUrl={currency.img}
          onClick={() => setActiveCurrency(currency.symbol)}
          name={currency.name}
          symbol={currency.symbol}
          disabled={error}
        />
      ))}
    </ul>
  );
};
