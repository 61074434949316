import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RatingIcons } from '@/shared/lib/backend/JsonRpcApi';
import { LoadSpinner } from '@/shared/ui/loader/loader';
import ModalLayout from '@/shared/ui/modal/modal';
import { Rating } from '@/shared/ui/rating/rating';
import { RatingEmoji } from '@/shared/ui/ratingEmoji/ratingEmoji';

import styles from './rating-block.module.scss';

type RatingBlockProps = {
  onClose: () => Promise<void>;
  handleOnRate: (e: number) => void;
  rating: number;
  loading: boolean;
  ratingIcons?: RatingIcons;
};

export const RatingBlock = ({
  onClose,
  handleOnRate,
  rating,
  loading,
  ratingIcons = RatingIcons.Stars,
}: RatingBlockProps) => {
  const { Close, Container, Content, Title, Description } = ModalLayout;
  const { t } = useTranslation();

  const getArrayIconsType = useCallback((value: RatingIcons) => {
    return value === 'emoji' ? (
      <RatingEmoji value={rating} onChange={handleOnRate} />
    ) : (
      <Rating value={rating} onChange={handleOnRate} noNumbers />
    );
  }, []);

  return (
    <>
      <Close onClick={onClose} />
      <Container>
        <Content>
          <Title>
            <Trans i18nKey="MTU.Modal.Feedback.Title" components={{ br: <br /> }} />
          </Title>
          <Description>{t('MTU.Modal.Feedback.Descr')}</Description>
        </Content>
        <div className={styles.rating}>
          {loading ? <LoadSpinner className={styles.loader} /> : getArrayIconsType(ratingIcons)}
        </div>
      </Container>
    </>
  );
};
