export type RequestParams = Partial<{
  did: string;
  gaid: string;
  gsid: string;
  platform: string;
  app_id: string;
  v: string;
  av: string;
}>;

export type LauidResponse = {
  lauid: string;
  timestamp: number;
};

export type SessionMetadata = Partial<{
  theme: string;
  bg_color: string;
  window: {
    width: number;
    height: number;
    pixelDepth: number;
  };
}>;

export type EventPayload = {
  lauid: string;
  client_created_at: number;
  name: string;
  meta: Record<string, unknown>;
};

export type EventDetails = {
  name: string;
  createdAt: number;
  meta: Record<string, unknown>;
};

export enum MetricsStorageKeys {
  QUEUE = 'MetricsStorage.metrics_queue',
}

export type EventName =
  | 'frontend_error'
  | 'socket_unreachable'
  | 'preference'
  | 'page_open'
  | 'page_leave'
  | 'screen_tap'
  | 'modal_open'
  | 'modal_close';
