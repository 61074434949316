import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getValidationRules } from '@/features/balance/balance-withdraw/utils/getValidationRules';
import { BonusBalance } from '@/page/bonus-program/ui/bonus-balance-card/ui/bonus-balance';
import { BalanceInfo } from '@/page/home/ui/balance-info/balance-info';
import { BalanceAdditionalInfo } from '@/page/withdraw/ui/create-withdraw/balance-additional-info';
import { NcwalletBanner } from '@/page/withdraw/ui/ncwallet-banner/ncwallet-banner';
import { Currency, CurrencyBonus, WithdrawalCreateType, WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { LAST_SHOW_TOAST_NCW } from '@/shared/lib/constants/storage-key';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { checkE } from '@/shared/lib/utils/checE';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { HISTORY_PATH, SWITCH_BALANCE_PATH } from '@/shared/lib/utils/links';
import { formatBalance, isValidEmail } from '@/shared/lib/utils/units';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { createWithdrawal, selectWithdrawals } from '@/shared/store/withdrawal/withdrawal.slice';
import { Button } from '@/shared/ui/button';
import { Currencies } from '@/shared/ui/currency-icon';
import { CustomTippy } from '@/shared/ui/custom-tippy/custom-tippy';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { ErrorText, IconVariant } from '@/shared/ui/error-text/error-text';
import { Icon } from '@/shared/ui/icons';
import { SwitchBalance } from '@/shared/ui/switch-balance/switch-balance';

import { UnconfirmedTransactionInfo } from './unconfirmed-transaction-info';
import { EmailVerify } from '../email-check/email-verify/email-verify';

import styles from './create-withdraw.module.scss';

const SHORT_CUTS = [
  { value: 0, text: 'min' },
  { value: 25, text: '25%' },
  { value: 50, text: '50%' },
  { value: 75, text: '75%' },
  { value: 100, text: 'Max.' },
];

type CreateWithdrawProps = {
  isMining: boolean;
  currentCurrencyObj?: Currency;
  hsh_currency?: CurrencyBonus;
  formattedBalance: string;
  userCurrency: Currencies;
};

export const CreateWithdraw = ({
  isMining,
  currentCurrencyObj,
  hsh_currency,
  formattedBalance,
  userCurrency,
}: CreateWithdrawProps) => {
  const { mining_currencies, currencies, pool_balance, hsh_balance } = useSelector((store: RootState) => store.balance);
  const { min_withdrawal_amount, email, email_verified, ncw_min_withdrawal_amount } = useSelector(selectUser);
  const withdrawals = useSelector(selectWithdrawals);
  const status = useSelector((store: RootState) => store.withdrawal.createWithdrawalStatus);
  const allAddresses = useSelector((store: RootState) => store.withdrawal.withdrawalsAddresses);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');
  const [errorAmount, setErrorAmount] = useState('');
  const [errorTextValues, setErrorTextValues] = useState({});
  const [errorAddress, setErrorAddress] = useState('');
  const [isNcw, setIsNcw] = useState(true);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const navigate = useNavigate();
  const amountInputRef = useRef<HTMLInputElement>(null);
  const [activeShortcut, setActiveShortcut] = useState<number | null>(null);

  const waitTransactions = useMemo(
    () =>
      withdrawals.filter(
        (item) =>
          (item.status === WithdrawalStatus.Created ||
            item.status === WithdrawalStatus.PendingFunds ||
            item.status === WithdrawalStatus.Pending) &&
          item.currency !== 'HSH'
      ),
    [withdrawals]
  );

  const filteredAddresses = useMemo(() => {
    return allAddresses.filter((item) => {
      return userCurrency === 'BTC' && isMining ? true : getWithdrawalAddressInfo(item).isNcw;
    });
  }, [allAddresses, userCurrency, isMining]);

  const hasSavedAddresses = filteredAddresses.length > 0;
  const hasWaitTransaction = waitTransactions.length > 0 && isMining;
  const limitTransaction = waitTransactions.length >= 10 && isMining;

  useEffect(() => {
    const isConnectNcwAddress = filteredAddresses.some((address) => 'email' in address);

    const lastShowToast = localStorage.getItem(LAST_SHOW_TOAST_NCW);
    const lastShowToastDate = new Date(lastShowToast || 0);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // 1 day
    const diff = now.getTime() - lastShowToastDate.getTime() > oneDay;

    if (!isConnectNcwAddress && (diff || !lastShowToast) && email_verified) {
      toaster.info('Connect NC Wallet');
      localStorage.setItem(LAST_SHOW_TOAST_NCW, new Date().toString());
    }
  }, [filteredAddresses, email_verified]);

  useEffect(() => {
    if (!hasSavedAddresses) {
      return setAddress('');
    }

    const lastAddress = filteredAddresses[0];
    if (!lastAddress) return;

    const addressInfo = getWithdrawalAddressInfo(lastAddress);

    if (addressInfo.isNcw) {
      setAddress(addressInfo.email || addressInfo.account_id);
      setIsNcw(true);
      return;
    }

    setAddress(addressInfo.address);
    setIsNcw(false);
  }, [filteredAddresses, hasSavedAddresses]);

  const currentBalance = useMemo(
    () => (isMining ? formattedBalance : hsh_balance.current),
    [isMining, formattedBalance, hsh_balance]
  );

  const currentMinimumAmount = useMemo(() => {
    if (!isMining) return checkE(hsh_currency?.min_withdrawal || +ncw_min_withdrawal_amount);
    return isNcw
      ? checkE(currentCurrencyObj?.min_withdrawal || +ncw_min_withdrawal_amount)
      : checkE(+min_withdrawal_amount);
  }, [
    min_withdrawal_amount,
    ncw_min_withdrawal_amount,
    currentCurrencyObj?.min_withdrawal,
    hsh_currency?.min_withdrawal,
    isNcw,
    isMining,
  ]);

  const isLessMinBalance = useMemo(() => {
    return isMining ? +pool_balance.total < +currentMinimumAmount : +hsh_balance.total < +currentMinimumAmount;
  }, [isMining, currentMinimumAmount, pool_balance.total, hsh_balance.total]);

  useEffect(() => {
    if (isLessMinBalance) setErrorAmount('NoFunds.State');
    else setErrorAmount('');
  }, [isLessMinBalance]);

  const handleChange = useCallback(
    (value: string) => {
      setActiveShortcut(null);
      const formattedValue = value.replace(',', '.');
      const unit = `${(isMining ? currentCurrencyObj : hsh_currency)?.unit}` || '1';

      const { isValid, isFormattedUnit } = getValidationRules(formattedValue, unit);

      if (+unit >= 1 && !isFormattedUnit) {
        setErrorAmount('Amount_Error.WholeNumbers');
      }

      if (isValid) {
        setAmount(formattedValue);
        setErrorAmount('');
      }
    },
    [isMining, currentCurrencyObj, hsh_currency]
  );

  const validationCreate = useMemo(
    () => [
      {
        isError: !address,
        getError: () => {
          setErrorAddress('Connect NC Wallet Account first');
        },
      },
      {
        isError: +amount > +currentBalance,
        getError: () => setErrorAmount('Withdr.Max_error'),
      },
      {
        isError: +amount < +currentMinimumAmount,
        getError: () => {
          setErrorAmount('Withdr.Min_error');
          setErrorTextValues({ min: currentMinimumAmount });
        },
      },
      {
        isError: limitTransaction,
        getError: () => openModal('MODAL_LIMIT_TRANSITION', { isServices: true }),
      },
      {
        isError: hasWaitTransaction,
        getError: () => openModal('MODAL_WAIT_TRANSITION', { isServices: true }),
      },
    ],
    [address, amount, currentMinimumAmount, currentBalance, openModal, limitTransaction, hasWaitTransaction]
  );

  const isSomeError = useMemo(() => validationCreate?.some((item) => item.isError), [validationCreate]);

  const handleCreate = async () => {
    validationCreate?.forEach((item) => item.isError && item.getError());

    if (isSomeError) return;

    try {
      // Check if the address looks like an email
      if (isValidEmail(address)) {
        try {
          const r = await rpc.transmit('ncw.find_account', { value: address });
          if (r) {
            dispatch(
              createWithdrawal({
                address: r?.account_id,
                amount: +amount,
                type: isMining ? WithdrawalCreateType.Regular : WithdrawalCreateType.Hsh,
                openModal,
                navigate,
              })
            );
          } else {
            // Handle the case where ncw.find_account does not return an account
            openModal('MODAL_NC_WALLET_REJECT', { email: address, isServices: true });
          }
        } catch (error) {
          console.error(error);
          // If ncw.find_account RPC call fails, open the rejection modal
          openModal('MODAL_NC_WALLET_REJECT', { email: address, isServices: true });
        }
      } else {
        dispatch(
          createWithdrawal({
            address,
            amount: +amount,
            type: isMining ? WithdrawalCreateType.Regular : WithdrawalCreateType.Hsh,
            openModal,
            navigate,
          })
        );
      }
    } catch (e) {
      console.log('Operation error', e);
    }
  };

  const onAddressClick = () => {
    openModal('MODAL_ADDRESS_INFO', {
      addresses: filteredAddresses,
      currentAddress: address,
      onClick: handleSetAddress,
      setIsNcw: setIsNcw,
      userAccountEmail: email,
      isServices: true,
    });
  };

  const handleSetAddress = async (addr: string) => {
    setAddress(addr);
  };

  const setShortCutValue = useCallback(
    (value: number) => {
      setActiveShortcut(value);
      const unit = (isMining ? currentCurrencyObj : hsh_currency)?.unit || '0.00';
      const balance = (+currentBalance / 100) * value;

      if (value === 0 || +balance < +currentMinimumAmount) {
        setAmount(currentMinimumAmount);
      } else if (value === 100) {
        setAmount(formatBalance(currentBalance, unit));
      } else {
        setAmount(formatBalance(balance, unit));
      }
      setErrorAmount('');
    },
    [currentMinimumAmount, currentBalance, currentCurrencyObj, hsh_currency, isMining]
  );

  return (
    <>
      {isMining ? (
        <>
          <div className={styles.balance}>
            <div className={styles.sectionHeader}>
              <span>{t('Balance')}</span>
            </div>
            <div className={cn(styles.balanceContent, mining_currencies?.length <= 1 && styles.balanceContent_indent)}>
              <BalanceInfo />
              <BalanceAdditionalInfo className={styles.last} />
              {mining_currencies?.length > 1 && (
                <SwitchBalance userCurrency={userCurrency} handleClick={() => navigate(SWITCH_BALANCE_PATH)} />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.balance}>
          <BonusBalance
            balance={hsh_balance.total}
            income={hsh_balance.income}
            accrued={hsh_balance.accrued}
            nextPayment={hsh_balance.nextPayment}
          />
        </div>
      )}

      <div className={styles.root}>
        <div className={cn(styles.sectionHeader, styles.sectionHeader_border)}>
          <span>{t('Withdraw_pg.Main_Title')}</span>
          {email_verified && (
            <Button
              as={Link}
              to={HISTORY_PATH}
              className={styles.sectionHeaderButton}
              variant="text"
              color="blue"
              data-test-id="link_history"
            >
              <Icon iconName="history" width="21" height="20" />
            </Button>
          )}
        </div>
        {!email_verified ? (
          <EmailVerify />
        ) : (
          <>
            <div className={styles.block}>
              <div className={styles.block__header}>
                <span className={styles.label__wrapTitle}>
                  {isNcw || !isMining ? t('Withdrawal.NCW_account.Title') : t('Bitcoin Address')}
                  <CustomTippy
                    content={
                      <div className={styles.tooltip__content}>
                        <Trans
                          i18nKey={t('Withdrawal.NCW_Account.Tip')}
                          values={{ currency: isMining ? userCurrency : 'HSH' }}
                        />
                        <Icon
                          iconName="close"
                          width={16}
                          height={16}
                          className={styles.tooltip__close}
                          onClick={hideTooltip}
                        />
                      </div>
                    }
                    visibleTooltip={tooltipVisible}
                    hideTooltip={hideTooltip}
                  >
                    <div className={styles.tooltip} onClick={tooltipVisible ? hideTooltip : showTooltip}>
                      <Icon iconName="qa" width={16} height={16} />
                    </div>
                  </CustomTippy>
                </span>
              </div>

              <div
                className={styles.labelWrap}
                onClick={(e) => {
                  e.preventDefault();
                  onAddressClick();
                }}
              >
                <label className={styles.label}>
                  <div className={cn(styles.label__container, errorAddress && styles.label__container_error)}>
                    <input
                      className={cn(styles.label__input, styles.small, styles.not_focus)}
                      placeholder={t('Add New Address')}
                      readOnly={true}
                      value={address}
                      data-test-id="btn_add_new_address"
                    />
                  </div>
                </label>
                <span className={cn(styles.label__openAddress, errorAddress && styles.label__openAddress_error)}>
                  <Icon iconName="arrowDown" width="12" height="12" />
                </span>
              </div>
              {errorAddress && <ErrorText iconVariant={IconVariant.circle} text={t(errorAddress)} />}
            </div>
            {(!isNcw || !address) && <NcwalletBanner email={email} />}
            {hasWaitTransaction && isMining && (
              <div className={cn(styles.block, styles.unconfirmedWithdrawBlock)}>
                <UnconfirmedTransactionInfo withdrawal={waitTransactions[0]} currencies={currencies} />
              </div>
            )}
            <div className={styles.block}>
              <label className={styles.label}>
                <div className={styles.label__titleItems}>
                  <div className={styles.label__wrapTitle}>{t('Withdrawal.Amount.Title')}</div>
                </div>
                <div className={cn(styles.label__container, errorAmount != '' && styles.label__container_error)}>
                  <input
                    className={cn(styles.label__input, styles.label__input_with_currency)}
                    placeholder={t('Amount_PlaceHolder')}
                    type="text"
                    inputMode="decimal"
                    value={amount}
                    onChange={(e) => handleChange(e.target.value)}
                    ref={amountInputRef}
                    data-test-id="input_amount"
                  />
                  <span className={styles.label__currency}>
                    {isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol}
                  </span>
                </div>
              </label>
              <div>
                {errorAmount && <ErrorText iconVariant={IconVariant.circle} text={t(errorAmount, errorTextValues)} />}
                <div className={styles.short_cuts}>
                  {SHORT_CUTS.map((short_cut) => {
                    const isActive = activeShortcut === short_cut.value;
                    return (
                      <Button
                        key={short_cut.text}
                        size="xs"
                        variant="filled"
                        color="grey"
                        onClick={() => setShortCutValue(short_cut.value)}
                        className={cn(styles.short_cuts__item, isActive && styles.short_cuts__item_active)}
                        data-test-id={`btn_${short_cut.text}`}
                      >
                        {t(short_cut.text)}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                color="blue"
                fullWidth
                className={styles.btn}
                onClick={handleCreate}
                loading={status === 'loading'}
                data-test-id="btn_create_withdraw"
              >
                {t('BB_Hsh.Withdraw')}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
