import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { NC_WALLET } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-increasing-hsh.module.scss';

export const BALANCE_HSH = 'BALANCE_HSH';

export const ModalIncreasingHsh = ({ onClose, isOpen }: ModalCommonProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const [balance, setBalance] = useState('0');
  const { t } = useTranslation();

  useEffect(() => {
    const localDate = localStorage.getItem(BALANCE_HSH) || '{}';
    const lastBalance = JSON.parse(localDate);

    if (+lastBalance.lastBalance) {
      setBalance(lastBalance.lastBalance);
    }
  }, []);

  const handleDontShow = useCallback(() => {
    const localDate = localStorage.getItem(BALANCE_HSH) || '{}';
    const lastBalance = JSON.parse(localDate);
    const visit = { ...lastBalance, isShow: !lastBalance.isShow };
    localStorage.setItem(BALANCE_HSH, JSON.stringify(visit));
  }, []);

  return (
    <ModalLayout className={styles.modal} onClose={() => onClose(false)} isOpen={isOpen} id="increasing-hsh-modal">
      <IconContainer>
        <Icon iconName="hsh" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>
            <Trans
              i18nKey="BB_Hsh.Topup.popup.Title"
              components={{ b: <b /> }}
              values={{ HSH: getLocalAmount(balance) }}
            />
          </Title>
          <Description>
            <Trans
              i18nKey="BB_Hsh.Topup.popup.Description"
              components={{
                b: <b />,
                a: <a href={NC_WALLET} className={styles.link} target="_blank" rel="noreferrer" />,
              }}
            />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={() => onClose(false)} data-test-id="btn_confirm_modal">
            {t('BTN_OK')}
          </Button>
        </Footer>
        <div className={styles.dont_show}>
          <input id="modalHSHCheckbox" type="checkbox" onClick={handleDontShow} />
          <label htmlFor={'modalHSHCheckbox'}>{t('DontShowThisWindowAgain')}</label>
        </div>
      </Container>
    </ModalLayout>
  );
};
