import { Trans, useTranslation } from 'react-i18next';

import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

interface SuccessWithdrawalProps extends ModalCommonProps {
  amount: string;
  currency: string;
}

export const SuccessWithdrawalModal = ({ onClose, isOpen, amount, currency }: SuccessWithdrawalProps) => {
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="success-withdrawal">
      <IconContainer>
        <Icon iconName="confirmed" width={80} height={80} style={{ color: '#37CC33' }} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('WithdrawalCancelSuccess.Title')}</Title>
          <Description>
            {
              <Trans
                i18nKey={t('WithdrawalCancelSuccess.Description')}
                values={{ amount: getLocalAmount(amount), currency }}
                components={{ b: <strong /> }}
              />
            }
          </Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            borderRadius={false}
            fullWidth
            onClick={() => onClose(false)}
            data-test-id="btn_confirm_modal"
          >
            {t('BTN_OK')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
