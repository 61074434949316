import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { CreateWithdraw } from '@/page/withdraw/ui/create-withdraw/create-withdraw';
import { BONUS, MINING, SwitchWithdrawalTabs } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { Tag } from '@/shared/lib/constants/tags';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { setIsMining } from '@/shared/store/balance/balance.slice';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';

export const WithdrawPage = () => {
  const balance = useSelector((store: RootState) => store.balance);
  const { tags } = useSelector(selectUser);
  const { currentCurrencyObj, hsh_currency, formattedTotalBalance, userCurrency } = useBalanceInfo();
  const dispatch = useDispatch();
  const { openModal } = useModalSet();
  const { MiningOrBonus } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tags?.includes(Tag.WITHDRAW_SUSPEND) || tags?.includes(Tag.WITHDRAW_ACCESS)) return;

    openModal('MODAL_WITHDRAW_SUSPENDED', { isServices: true });
  }, [tags, openModal]);

  useEffect(() => {
    if (MiningOrBonus) {
      dispatch(setIsMining(MiningOrBonus === MINING));
    } else {
      navigate(`${WITHDRAW_PATH}/${balance.is_mining ? MINING : BONUS}`);
    }
  }, [dispatch, MiningOrBonus]);

  return (
    <div>
      <SwitchWithdrawalTabs isMining={balance.is_mining} />
      <div>
        <CreateWithdraw
          isMining={balance.is_mining}
          currentCurrencyObj={currentCurrencyObj}
          hsh_currency={hsh_currency}
          formattedBalance={formattedTotalBalance}
          userCurrency={userCurrency}
        />
      </div>
    </div>
  );
};
