import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MenuAccount } from '@/features/menu/ui/menu-account/menu-account';
import { MenuFamily } from '@/features/menu/ui/menu-family/menu-family';
import { MenuNavigation } from '@/features/menu/ui/menu-navigation/menu-navigation';
import { MenuSocial } from '@/features/menu/ui/menu-social/menu-social';
import { changeTheme, selectCurrentTheme } from '@/features/theme';
import { AppTheme } from '@/features/theme/model/slice';
import { STORAGE_THEME, STORAGE_TUTORIAL, STORAGE_TUTORIAL_ONBOARDING } from '@/shared/lib/constants/storage-key';
import { build, version } from '@/shared/lib/constants/version';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useSupportLink } from '@/shared/lib/hooks/useSupportLink';
import { HOME_PATH, POOL_LANDING, POOL_PROMO } from '@/shared/lib/utils/links';
import { AppDispatch } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import styles from './menu.module.scss';

export const Menu = () => {
  const { email } = useSelector(selectUser);
  const currentTheme = useSelector(selectCurrentTheme);
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const dispatch: AppDispatch = useDispatch();
  const { isTelegramMiniApp, envVer, isIos } = useDevice();
  const linkSupport = useSupportLink();
  const [verClick, setVerClick] = useState(0);

  const handleChange = useCallback(() => {
    const setTheme = currentTheme === AppTheme.DARK ? AppTheme.LIGHT : AppTheme.DARK;
    localStorage.setItem(STORAGE_THEME, setTheme);
    dispatch(changeTheme(setTheme));
  }, [currentTheme, dispatch]);

  const handleOpenDeleteModal = () => {
    openModal('MODAL_DELETE_ACCOUNT', { isServices: true });
  };

  const handleVerClick = () => {
    setVerClick(verClick + 1);
    if (verClick > 10) {
      setVerClick(0);
      fetch('https://api.canary.ctpool.net/api/v2/me')
        .then((res) => res.json())
        .then(() => {
          openModal('MODAL_DEBUG', { isServices: true });
        })
        .catch(() => {});
    } else if (
      verClick === 1 &&
      (window.app_config.baseAPIURL.indexOf('canary') > 0 || window.app_config.baseAPIURL.indexOf('dvlt') > 0)
    ) {
      openModal('MODAL_DEBUG', { isServices: true });
    }
  };

  return (
    <div className={styles.root}>
      <MenuAccount email={email} />

      {!isTelegramMiniApp && !isIos ? (
        <div className={styles.box}>
          <a
            href={window.app_config.affiliateSiteURL}
            target="_blank"
            rel="noreferrer"
            className={styles.box__link}
            data-test-id="link_affilate_dashboard"
          >
            <Icon iconName="promo-up" width={20} height={20} className={styles.box__icon} />
            <span className={styles.box__text}>{t('Affiliate Dashboard')}</span>
            <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
          </a>
        </div>
      ) : null}

      {!isIos ? (
        <div className={styles.box}>
          <a
            href={`${window.app_config.affiliateSiteURL}${POOL_PROMO}`}
            target="_blank"
            rel="noreferrer"
            className={styles.box__link}
            data-test-id="link_pool_promo"
          >
            <Icon iconName="materials-up" width={20} height={20} className={styles.box__icon} />
            <span className={styles.box__text}>{t('MTU.Menu.Promo')}</span>
            <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
          </a>
        </div>
      ) : null}

      <div className={styles.box}>
        <a
          href={POOL_LANDING}
          target="_blank"
          rel="noreferrer"
          className={styles.box__link}
          data-test-id="link_pool_landing"
        >
          <Icon iconName="info" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('MTU.Menu.About')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </a>
      </div>

      <div className={styles.box}>
        <a
          href={HOME_PATH}
          className={styles.box__link}
          onClick={() => {
            const regex = new RegExp(`^(${STORAGE_TUTORIAL}(${STORAGE_TUTORIAL_ONBOARDING}))`);
            Object.keys(localStorage).forEach((i) => {
              regex.test(i) && localStorage.removeItem(i);
            });
          }}
          data-test-id="link_tutorial"
        >
          <Icon iconName="tutorial" width={21} height={17} className={styles.box__icon} />
          <span className={styles.box__text}>{t('MTU.Menu.Tutorial')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </a>
      </div>

      <div className={styles.box}>
        <a href={linkSupport} target="_blank" rel="noreferrer" className={styles.box__link} data-test-id="link_support">
          <Icon iconName="help-fill" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('Support')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </a>
      </div>

      <div className={styles.box}>
        <span className={styles.box__link}>
          <Icon iconName="dark-mode" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('MTU.Menu.Theme')}</span>
          <label className={styles.switch} data-test-id="checkbox_change_theme">
            <input
              type="checkbox"
              className={styles.switchInput}
              onChange={handleChange}
              checked={currentTheme === AppTheme.DARK}
            />
            <span className={styles.switchSlider} />
          </label>
        </span>
      </div>

      <div className={styles.box}>
        <Button
          fullWidth
          variant="outline"
          onClick={() => openModal('MODAL_SET_BACKGROUND', { isServices: true })}
          className={styles.box__link}
          data-test-id="btn_open_background"
        >
          <Icon iconName="paint" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('Background.Title')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </Button>
      </div>

      <div className={styles.box}>
        <Button
          fullWidth
          variant="outline"
          onClick={() => openModal('MODAL_SET_LANGUAGE', { isServices: true })}
          className={styles.box__link}
          data-test-id="btn_open_language"
        >
          <Icon iconName="globe" width={20} height={20} className={styles.box__icon} />
          <span className={styles.box__text}>{t('Language')}</span>
          <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
        </Button>
      </div>

      <MenuSocial />

      {!isIos && <MenuFamily />}

      <div className={styles.box}>
        <MenuNavigation isTelegramMiniApp={isTelegramMiniApp} />

        <div className={styles.version} onClick={handleVerClick} data-test-id="btn_version">
          <strong className={styles.version__title}>App Version:</strong> {envVer && `${envVer} / `} {version}.{build}
          {window.app_config.baseAPIURL.indexOf('canary') > 0 && ' (canary)'}
          {window.app_config.baseAPIURL.indexOf('dvlt') > 0 && ' (dvlt)'}
        </div>
      </div>
      {isIos && (
        <div className={styles.box}>
          <Button
            fullWidth
            variant="outline"
            onClick={handleOpenDeleteModal}
            className={styles.box__link}
            data-test-id="btn_open_delete_modal"
          >
            <Icon iconName="trash" width={20} height={20} className={styles.box__icon} />
            <span className={styles.box__text}>{t('Delete_acc.Btn')}</span>
            <Icon iconName="arrow" width="20" height="20" className={styles.box__arrow} />
          </Button>
        </div>
      )}
    </div>
  );
};
