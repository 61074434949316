import cn from 'classnames';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { Icon } from '@/shared/ui/icons';
import { ModalBottomSheet } from '@/shared/ui/modal/modal-bottom-sheet/modal-bottom-sheet';

import { ModalDefault } from './modal-default/modal-default';
import { ModalPage } from './modal-page/modal-page';

import styles from './modal.module.scss';

export enum ModalLayoutType {
  Default = 'Default',
  Page = 'Page',
  BottomSheet = 'BottomSheet',
}

export interface ModalCommonProps {
  onClose: (e: boolean) => void;
  isOpen: boolean;
}

interface ClassNameProps {
  className?: string;
}

const ModalLayoutContainer = ({ className, children }: PropsWithChildren<ClassNameProps>) => (
  <div className={cn(className, styles.container)}>{children}</div>
);

const ModalLayoutClose = ({ className, onClick }: { className?: string; onClick: () => void }) => (
  <span className={cn(styles.close, className)} onClick={onClick} data-test-id="btn_close_modal">
    <Icon iconName="close" width="18" height="18" />
  </span>
);

const ModalLayoutIcon = ({ children, className }: PropsWithChildren<ClassNameProps>) => (
  <div className={cn(className, styles.icon)}>{children}</div>
);

interface TitleProps extends ClassNameProps {
  align?: 'left' | 'center';
  size?: 's' | 'm';
}

const ModalLayoutTitle = ({ children, className, align = 'center', size = 'm' }: PropsWithChildren<TitleProps>) => (
  <h3 className={cn(className, styles[align], styles[size], styles.title)}>{children}</h3>
);

const ModalLayoutDescription = ({ children, className, ...rest }: PropsWithChildren<ClassNameProps>) => (
  <p className={cn(className, styles.description)} {...rest}>
    {children}
  </p>
);

const ModalLayoutContent = ({ children, className }: PropsWithChildren<ClassNameProps>) => (
  <div className={cn(className, styles.content)}>{children}</div>
);

interface FooterProps {
  flexDirection?: 'row' | 'column';
}

const ModalLayoutFooter = ({ children, flexDirection = 'column' }: PropsWithChildren<FooterProps>) => (
  <div className={cn(styles.footer, styles[flexDirection])}>{children}</div>
);

interface ModalLayoutProps {
  type?: ModalLayoutType;
  onClose: () => void;
  isOpen?: boolean;
  id: string;
  className?: string;
}

const ModalLayout = ({
  type = ModalLayoutType.BottomSheet,
  children,
  isOpen,
  onClose,
  id,
  className,
}: PropsWithChildren<ModalLayoutProps>) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow');
    } else {
      document.body.classList.remove('overflow');
    }

    return () => document.body.classList.remove('overflow');
  }, [isOpen]);

  return {
    Default: (
      <ModalDefault isOpen={isOpen} onClose={onClose} id={id} className={className}>
        {children}
      </ModalDefault>
    ),
    Page: (
      <ModalPage isOpen={isOpen} onClose={onClose} id={id} className={className}>
        {children}
      </ModalPage>
    ),
    BottomSheet: (
      <ModalBottomSheet isOpen={isOpen} onClose={onClose} id={id} className={className}>
        {children}
      </ModalBottomSheet>
    ),
  }[type];
};

ModalLayout.Container = ModalLayoutContainer;
ModalLayout.Title = ModalLayoutTitle;
ModalLayout.Close = ModalLayoutClose;
ModalLayout.Description = ModalLayoutDescription;
ModalLayout.IconContainer = ModalLayoutIcon;
ModalLayout.Content = ModalLayoutContent;
ModalLayout.Footer = ModalLayoutFooter;
ModalLayout.Type = ModalLayoutType;

export default ModalLayout;
