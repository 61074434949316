import { useTranslation } from 'react-i18next';

import { useLogout } from '@/shared/hooks';
import { useSupportLink } from '@/shared/lib/hooks/useSupportLink';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

export const AccountBlockedView = ({ isOpen, onClose }: ModalCommonProps) => {
  const { IconContainer, Container, Content, Title, Description, Footer, Type } = ModalLayout;
  const linkSupport = useSupportLink();
  const { t } = useTranslation();
  const { logout } = useLogout();

  return (
    <ModalLayout type={Type.Page} onClose={() => onClose(false)} isOpen={isOpen} id="account-blocked-modal">
      <IconContainer>
        <Icon iconName="user-block" width={80} height={80} />
      </IconContainer>
      <Container>
        <Content>
          <Title>{t('Acc_susp.Title')}</Title>
          <Description>{t('Acc_susp.Description')}</Description>
        </Content>
        <Footer>
          <Button as="a" href={linkSupport} target="_blank" color="blue" fullWidth>
            {t('Contact support')}
          </Button>
          <Button color="grey" fullWidth onClick={logout}>
            {t('Logout')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
