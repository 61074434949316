import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

interface ModalGoToPaymentProps extends ModalCommonProps {
  paymentUrl: string;
}

export const ModalGoToPayment = ({ onClose, isOpen, paymentUrl }: ModalGoToPaymentProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="go-to-payment-modal">
      <IconContainer>
        <Icon iconName="confirmed" width={80} height={80} style={{ color: '#37CC33' }} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('OrdrConf.Title')}</Title>
          <Description>{t('OrdrConf.Description')}</Description>
        </Content>
        <Footer>
          <Button as="a" href={paymentUrl} target="_blank" color="blue" fullWidth onClick={() => onClose(false)}>
            {t('OrdrConf.Btn')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
