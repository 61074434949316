import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCurrentTheme } from '@/features/theme';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import styles from './email-verify.module.scss';

export const EmailVerify = () => {
  const theme = useSelector(selectCurrentTheme);
  const { openModal } = useModalSet();
  const { t } = useTranslation();

  const handleClick = () => {
    openModal('MODAL_EMAIL_VERIFY', { isServices: true });
  };

  return (
    <div className={styles.root}>
      <div className={styles.block}>
        <Icon
          iconName={theme === 'light' ? 'email-verify-connect' : 'email-verify-connect-dark'}
          width={80}
          height={80}
        />
        <h6 className={styles.title}>{t('Confirm_mail.Small.Title')}</h6>
        <p className={styles.text}>{t('Confirm_mail.Small.Descr')}</p>
      </div>
      <div className={styles.footer}>
        <Button color="blue" fullWidth onClick={handleClick} data-test-id="btn_open_modal_verify">
          <Icon iconName="email" width="24" height="24" />
          {t('Mail_conf.Conf.Btn')}
        </Button>
      </div>
    </div>
  );
};
