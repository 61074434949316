import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Conversion } from '@/page/switch-balance/ui/conversion/conversion';
import { ErrorTimer } from '@/page/switch-balance/ui/error-timer/error-timer';
import { SwitchList } from '@/page/switch-balance/ui/switch-list/switch-list';
import { ChangeCurrencyResponse } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { isEffectivelyZero } from '@/shared/lib/utils/isEffectivelyZero';
import { setHistory, setRate, setRateBtcToCurrentUserCurrency } from '@/shared/store/rate/rate.slice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser, userAction } from '@/shared/store/user/user.slice';
import { Currencies } from '@/shared/ui/currency-icon';
import { toaster } from '@/shared/ui/custom-toast/toaster';

import styles from './switch-balance-page.module.scss';

export const SwitchBalancePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { currency_changed_at, timestamp, currency: userCurrency } = useSelector(selectUser);
  const { mining_currencies, pool_balance } = useSelector((store: RootState) => store.balance);
  const [error, setError] = useState(false);
  const [conversionData, setConversionData] = useState<ChangeCurrencyResponse | null>(null);
  const defaultCurrency = mining_currencies?.find((curr) => curr?.symbol === userCurrency);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState(
    defaultCurrency?.symbol || mining_currencies?.[0]?.symbol || userCurrency || 'BTC'
  );
  useEffect(() => {
    const currencyChangedAtDate = currency_changed_at * 1000;
    const currentDate = timestamp * 1000;
    const diffHours = (currentDate - currencyChangedAtDate) / (1000 * 3600);
    // Set error if less than 24 hours
    // Change time for less time in developers mode to change currency. Backend tag required
    if (diffHours < 24) {
      setError(true);
    } else {
      setError(false);
    }
  }, [currency_changed_at, timestamp]);

  useEffect(() => {
    rpc.transmit('timestamp', {}).then((result) => {
      dispatch(userAction.setTimestamp(result));
    });
  }, [dispatch]);

  const isValidCurrency = (currency: string): currency is Currencies => {
    return mining_currencies?.some((c) => c.symbol === currency);
  };

  const changeCurrency = () => {
    if (isValidCurrency(activeCurrency) && userCurrency !== activeCurrency) {
      setIsLoading(true);
      rpc
        .transmit('users.me.change_currency', { currency: activeCurrency })
        .then(async (response) => {
          // Check if the response is APIUser and has the 'currency' property
          if ('currency' in response && response.currency && isValidCurrency(response.currency)) {
            setActiveCurrency(response.currency);
            const rateParams = {
              from_currency: isEffectivelyZero(pool_balance?.total)
                ? activeCurrency || 'BTC'
                : response?.currency || 'BTC',
              to_currency: activeCurrency || userCurrency === 'USDT' ? 'USD' : 'USDT',
            };
            const rateParamsBtc = {
              from_currency: 'BTC',
              to_currency: activeCurrency,
            };
            const historyParams = {
              ...rateParams,
              interval: 'daily',
              time_start: dayjs().add(-1, 'months').toISOString(),
              time_end: dayjs().toISOString(),
            };
            await rpc.transmit('currencies.history', historyParams).then((r) => {
              dispatch(setHistory(r));
            });
            await rpc.transmit('currencies.rate', rateParams).then((r) => {
              dispatch(setRate(r));
            });
            await rpc.transmit('currencies.rate', rateParamsBtc).then((r) => {
              dispatch(setRateBtcToCurrentUserCurrency(r));
            });
            dispatch(userAction.checkUserNew(response));
            navigate('/');
          }
          // If it's a ChangeCurrencyResponse, check for 'to_currency'
          else if ('rate' in response && 'to_currency' in response && isValidCurrency(response.to_currency)) {
            setConversionData(response);
            setActiveCurrency(response?.to_currency);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toast.dismiss();
          toaster.error('Error', { toastProps: { autoClose: 1000 } });
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (pool_balance?.exact_total !== '0') {
      changeCurrency();
    }
  }, [activeCurrency]);

  return (
    <div className={cn(styles.root, { [styles.error]: error })}>
      <div className={styles.block}>
        {error && <ErrorTimer currency_changed_at={currency_changed_at} timestamp={timestamp} />}
        <SwitchList
          activeCurrency={activeCurrency}
          setActiveCurrency={setActiveCurrency}
          error={error}
          userCurrency={userCurrency}
          currencies={mining_currencies}
        />
        {!error && userCurrency !== activeCurrency ? (
          <Conversion
            activeCurrency={activeCurrency}
            conversionData={conversionData}
            changeCurrency={changeCurrency}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};
