import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { rpc } from '@/shared/lib/backend/Rpc';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { NC_WALLET } from '@/shared/lib/utils/links';
import { isValidWithdrawalAddress } from '@/shared/lib/utils/units';
import { RootState } from '@/shared/store/types';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import styles from './ncwallet-connect-page.module.scss';

export const NcwalletConnectPage = () => {
  const { is_mining } = useSelector((store: RootState) => store.balance);
  const location = useLocation();
  const emailFromState = location.state?.email || '';
  const [email, setEmail] = useState(emailFromState);
  const [isLoading, setIsLoading] = useState(false);
  const { userCurrency } = useBalanceInfo();
  const { t } = useTranslation();
  const { openModal } = useModalSet();

  useEffect(() => {
    if (emailFromState) {
      setEmail(emailFromState);
    }
  }, [emailFromState]);

  const isValid = useMemo(() => isValidWithdrawalAddress(email), [email]);

  const checkEmailInNcWallet = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await rpc.transmit('ncw.find_account', { value: email });

      if (!res) {
        throw new Error('Account not found');
      }
      openModal('MODAL_NC_WALLET_CONFIRM', {
        email: res.email || res.account_id,
        userId: res?.account_id,
        isServices: true,
      });
    } catch (e) {
      openModal('MODAL_NC_WALLET_REJECT', { email, isServices: true });
      console.log('find account error', e);
    } finally {
      setIsLoading(false);
    }
  }, [email, openModal]);

  const openBtcModal = useCallback(() => openModal('MODAL_BTC_CONNECT', { isServices: true }), [openModal]);

  return (
    <div className={styles.root}>
      <div className={styles.block}>
        <div className={styles.top}>
          <NCWalletIcon width={80} height={80} className={styles.icon} />
          <h4 className={styles.title}>{t('Connect_NCW.Title')}</h4>
          <p className={styles.text}>
            <Trans i18nKey={t('Connect_NCW.Description')} components={{ b: <strong /> }} />
          </p>

          <label htmlFor={'email'} className={styles.label}>
            <NCWalletIcon width={23} height={23} className={styles.label__icon} />
            {t('NC Wallet Account')}
          </label>
          <input
            id={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            className={cn(styles.input, isLoading && styles.input_loading)}
            placeholder={t('NCW_holder')}
          />
          {userCurrency === 'BTC' && is_mining && (
            <Button variant={'text'} color="blue" className={styles.btc} onClick={openBtcModal}>
              <Icon iconName={'close'} width={18} height={18} />
              {t('Use_BTC_address')}
            </Button>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            color="blue"
            fullWidth
            disabled={!isValid}
            onClick={() => checkEmailInNcWallet()}
            loading={isLoading}
            borderRadius={false}
          >
            {t('CONNECT')}
            <Icon iconName="arrow" width={20} height={20} />
          </Button>
          <Button
            fullWidth
            color="white-blue"
            variant="filled"
            as="a"
            href={NC_WALLET}
            target="_blank"
            borderRadius={false}
          >
            <Icon iconName="walletPlus" width={20} height={20} />
            {t('Connect_NCW.Create.Btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};
