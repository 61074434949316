export function checkE(num: number | string): string {
  if (!num || isNaN(+num)) {
    return '0';
  }
  const numStr = num.toString();
  const downFixedNumber = 0.00000000006;

  const fixedValue = (+num).toFixed(10);
  const fixedToDownValue = (+num - downFixedNumber).toFixed(10).replace(/-/, '');

  const isRounded = numStr.length > `${+num}`.length && !`${+num}`.includes('e');
  const correctValue = +fixedValue > +num || isRounded ? fixedToDownValue : fixedValue;

  return +correctValue < 1 ? correctValue.replace(/\.?0+$/, '') : parseFloat(correctValue).toString();
}
