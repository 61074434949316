import { Button, Slider, Text } from '@wcode/ui-kit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setHashrate, useHashrate, useHashrates } from '@/page/cart/store';
import {
  setGoogleHashrate,
  setGoogleInfo,
  useGoogleHashrate,
  useGoogleHashrates,
} from '@/page/cart/store/cart-google-store';
import { formatHashrate } from '@/page/cart/ui/hashrate';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { Card } from '@/shared/ui/kit/card';
import { CurrencySwitcherType } from '@/shared/ui/kit/currency-switcher/currency-switcher';
import { getSpeedOptions } from '@/shared/ui/kit/hashrate-speed/helpers';

import s from './hashrate-speed.module.scss';

interface HashrateSpeedProps {
  activeTab: CurrencySwitcherType;
  onSwitchToCrypto: () => void;
}

export const HashrateSpeed = ({ activeTab, onSwitchToCrypto }: HashrateSpeedProps) => {
  const isCryptoTab = activeTab === 'crypto';
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const { t } = useTranslation();
  const hashratesMarket = useSelector(useGoogleHashrates);
  const hashratesCrypto = useSelector(useHashrates);
  const hashrates = isCryptoTab ? hashratesCrypto : hashratesMarket;
  const activeMonth = useSelector((state: RootState) => state.googleCart.miningDuration);
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const hashrateMarket = useSelector(useGoogleHashrate);
  const hashrateCrypto = useSelector(useHashrate);
  const hashrate = isCryptoTab ? hashrateCrypto : hashrateMarket;
  const formattedHashrate = formatHashrate(hashrate);
  const currentValue = hashrates?.indexOf(hashrate);
  const minValue = 0;
  const maxValue = hashrates?.length - 1;
  const SPEED_OPTIONS = getSpeedOptions(hashrates);

  const handleChange = (value: number) => {
    if (isCryptoTab) {
      dispatch(setHashrate({ hashrate: hashrates[value] }));
    } else {
      const currentPlan = plans?.find((i) => i?.hps === hashrates?.[value] && i?.month === activeMonth);
      if (currentPlan && !isCryptoTab) {
        dispatch(
          setGoogleInfo({
            productId: currentPlan?.productId,
            offerToken: currentPlan?.offerToken,
            basePlanId: currentPlan?.basePlanId,
            trial: currentPlan?.trial,
          })
        );
      }
      dispatch(setGoogleHashrate({ hashrate: hashrates?.[value] }));
    }
  };

  const handleInfoIconClick = () => {
    openModal('MODAL_WHAT_IS_MINING_SPEED');
  };

  const onSwitchToCryptoButtonClick = () => {
    if (activeTab === 'google-play') {
      openModal('MODAL_SPEED_UP_WITH_CRYPTO', { onSwitchToCrypto });
    }
  };

  return (
    <Card gap={'var(--number-012)'}>
      <div className={s.header}>
        <div className={s.title}>
          <Text type="heading" size="h5" text={t('Buy_scr.Speed')} />
          <Button
            variant={'flat'}
            onClick={handleInfoIconClick}
            label={<Icon iconName="info-speed" width={16} height={16} />}
          />
        </div>
        <Text
          type="heading"
          size="h5"
          text={`${formattedHashrate}`}
          className={isCryptoTab ? s.header__hashrate_violet : s.header__hashrate_blue}
        />
      </div>

      <Slider
        value={currentValue}
        onChange={handleChange}
        min={minValue}
        max={maxValue}
        step={1}
        color={isCryptoTab ? 'var(--color-violet-400)' : ''}
        className={isCryptoTab ? s.slider__thumb_violet : ''}
      />

      <Text type="body" size="s" text={t('Buy_scr.Speed.Scr')} />

      <div className={s.buttons}>
        <div className={s.speed__buttons}>
          {SPEED_OPTIONS.map((option) => (
            <Button
              key={option}
              type="button"
              variant="secondary"
              size="m"
              onClick={() => {
                const index = hashrates?.findIndex((h) => h >= option) ?? 0;
                handleChange(index);
              }}
              label={<Text type={'label'} size={'m'} text={`${formatHashrate(option)}`} className={s.buttons__text} />}
            />
          ))}
        </div>
        {activeTab === 'google-play' ? (
          <Button
            type="button"
            variant="secondary"
            size="m"
            label={<Text type={'label'} size={'m'} text={t('Buy_scr.HigherSpeed')} className={s.buttons__text} />}
            onClick={onSwitchToCryptoButtonClick}
          />
        ) : null}
      </div>
    </Card>
  );
};
