import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setHashrate, setMiningDuration, useHashrates, useIsCryptoEnable, useMiningDurations } from '@/page/cart/store';
import {
  checkPurchase,
  setGoogleHashrate,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleAvailablePurchase,
  useGoogleHashrate,
  useGoogleHashrates,
  useGoogleMiningDuration,
  useGoogleMiningDurations,
  useGooglePlans,
  useLastReceiptIOS,
} from '@/page/cart/store/cart-google-store';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AppDispatch } from '@/shared/store/types';

export enum UrlCartTab {
  Crypto = 'c',
  Telegram = 't',
  Fiat = 'f',
}

export const useInitUrlParams = ({ isDataReady }: { isDataReady: boolean }) => {
  const miningDurations = useSelector(useMiningDurations);
  const hashrates = useSelector(useHashrates);
  const plans = useSelector(useGooglePlans);
  const googleHashrates = useSelector(useGoogleHashrates);
  const googleMiningDurations = useSelector(useGoogleMiningDurations);
  const googleHashrate = useSelector(useGoogleHashrate);
  const googleMiningDuration = useSelector(useGoogleMiningDuration);
  const lastReceiptIOS = useSelector(useLastReceiptIOS);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const isCryptoEnable = useSelector(useIsCryptoEnable);

  const { isTelegramMiniApp, isIos } = useDevice();
  const dispatch: AppDispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const currentTabParam = params.get('p') as string; // p -> panel
  const durationParam = params.get('m') as string; // m -> mining duration
  const hashrateParam = params.get('r') as string; // r -> rate

  useEffect(() => {
    const urlHashrate = parseFloat(hashrateParam) * 1000;
    const urlDuration = parseFloat(durationParam);

    if (!isDataReady || isNaN(urlDuration) || isNaN(urlHashrate)) return;

    if (
      (currentTabParam === UrlCartTab.Crypto && isCryptoEnable) ||
      (currentTabParam === UrlCartTab.Telegram && isTelegramMiniApp)
    ) {
      if (hashrates?.includes(urlHashrate)) dispatch(setHashrate({ hashrate: urlHashrate }));
      if (miningDurations?.includes(urlDuration)) dispatch(setMiningDuration({ miningDuration: urlDuration }));
    } else if (currentTabParam === UrlCartTab.Fiat && !isTelegramMiniApp) {
      const activeHashrate = googleHashrates?.includes(urlHashrate) ? urlHashrate : googleHashrate;
      const activeMiningDuration = googleMiningDurations?.includes(urlDuration) ? urlDuration : googleMiningDuration;
      const plan = plans?.find((plan) => plan?.hps === activeHashrate && plan?.month === activeMiningDuration);
      let isActive;

      if (isIos) {
        const purchasedPlan = lastReceiptIOS?.latest_receipt_info?.find((i) => i?.product_id === plan?.productId);
        const pendingRenewalInfo = lastReceiptIOS?.pending_renewal_info?.find(
          (i) => i.auto_renew_product_id === plan?.productId && i.product_id !== plan?.productId
        );

        isActive = !!purchasedPlan || !!pendingRenewalInfo;
      } else {
        isActive = !!availablePurchase?.some((i) => i?.productId === plan?.productId);
      }

      dispatch(checkPurchase(isActive));
      dispatch(setGoogleHashrate({ hashrate: activeHashrate }));
      dispatch(setGoogleMiningDuration({ miningDuration: activeMiningDuration }));
      dispatch(
        setGoogleInfo({
          productId: plan?.productId || '',
          offerToken: plan?.offerToken || '',
          basePlanId: plan?.basePlanId || '',
          trial: !!plan?.trial,
        })
      );
    }
  }, [isDataReady, isTelegramMiniApp, isCryptoEnable]);

  return { currentTabParam };
};
