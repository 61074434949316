import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { rpc } from '@/shared/lib/backend/Rpc';
import { RootState } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

export const ModalErrorConnection = ({ isOpen, onClose }: ModalCommonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { status: rpcStatus } = useSelector((state: RootState) => state.rpc);
  const { IconContainer, Container, Content, Title, Description, Footer, Type } = ModalLayout;
  const { t } = useTranslation();

  const reload = async () => {
    await rpc.reopen();
  };

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 5000);
    reload();
  };

  useEffect(() => {
    const statusCheck = rpcStatus !== 'open' && rpcStatus !== '';

    if (!statusCheck) {
      onClose(false);
    }
  }, [rpcStatus]);

  const visibilitychange = async () => {
    if (document.visibilityState === 'visible') {
      return reload();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilitychange);
    window.addEventListener('focus', reload);
    return () => {
      document.removeEventListener('visibilitychange', visibilitychange);
      window.removeEventListener('focus', reload);
    };
  });

  return (
    <ModalLayout type={Type.Page} onClose={() => onClose(false)} isOpen={isOpen} id="error-connection-modal">
      <IconContainer>
        <Icon iconName="connection-error" width={80} height={80} />
      </IconContainer>
      <Container>
        <Content>
          <Title>{t('Socket_unreachable.Title')}</Title>
          <Description>{t('Socket_unreachable.Description')}</Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={handleClick} loading={isLoading}>
            {t('RETRY')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
