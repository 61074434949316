import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IS_VERIFY_EMAIL_NOTIFICATION_SHOWN } from '@/shared/lib/constants/storage-key';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { selectUser } from '@/shared/store/user/user.slice';
import { toaster } from '@/shared/ui/custom-toast/toaster';

export const useVerifyEmailNotification = () => {
  const { email } = useSelector(selectUser);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const isNotificationShown = localStorage.getItem(IS_VERIFY_EMAIL_NOTIFICATION_SHOWN);

    if (isNotificationShown || email) {
      return;
    }

    toaster.error(t('PlsConfirmEmail'), { onClick: () => navigate(WITHDRAW_PATH) });
    localStorage.setItem(IS_VERIFY_EMAIL_NOTIFICATION_SHOWN, 'true');
  }, [email, navigate, t]);
};
