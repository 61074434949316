import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  checkPurchase,
  purchaseSubscription,
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
  useGoogleIsAvailable,
  useGooglePurchaseStatus,
} from '@/page/cart/store/cart-google-store';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import s from './purchase-subs-button.module.scss';

export const PurchaseSubsButton = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const status = useSelector(useGooglePurchaseStatus);
  const isAvailable = useSelector(useGoogleIsAvailable);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const activeId = useSelector((state: RootState) => state.googleCart?.productId);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const { openModal } = useModalSet();
  const isLoading = status === 'loading';

  useEffect(() => {
    dispatch(checkPurchase(availablePurchase?.some((i) => i?.productId === activeId)));
  }, [activeId, availablePurchase, dispatch]);

  const handleClick = () => {
    dispatch(purchaseSubscription({ openModal, navigate }));
  };

  return (
    <>
      <Button
        fullWidth
        color="green"
        onClick={handleClick}
        disabled={isLoading || isAvailable}
        loading={isLoading}
        className={s.button}
        data-test-id="btn_subscribe"
      >
        {isAvailable ? (
          <>
            <Icon iconName="checked" width={20} height={20} />
            {t('workerDetails.activated')}
          </>
        ) : (
          <>
            {`${t('SUBSCRIBE')} / ${getLocalAmount(currentPlan?.total_price, currentPlan?.currentCurrency)}`}
            <Icon iconName="arrow" width={20} height={20} />
          </>
        )}
      </Button>
    </>
  );
};
