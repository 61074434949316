import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { rpc } from '@/shared/lib/backend/Rpc';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useRating } from '@/shared/lib/hooks/useRating';
import { RATING_APPLE_STORE, RATING_GOOGLE_STORE } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import { Rating } from '@/shared/ui/rating/rating';

import AppleImg from './img/apple.png';
import GoogleImg from './img/google.png';

import styles from './market-block.module.scss';

type MarketBlockProps = {
  starCount: number;
  onClose: (e: boolean) => void;
};

export const MarketBlock = ({ starCount, onClose }: MarketBlockProps) => {
  const { Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const hasOpenUrl = window.ct && Object.hasOwn(window.ct, 'openUrl');
  const { sendRatingDetails } = useRating();
  const { isIos } = useDevice();
  const marketImg = isIos ? AppleImg : GoogleImg;
  const storeName = isIos ? 'App Store' : 'Google Play';
  const storeLink = isIos ? RATING_APPLE_STORE : RATING_GOOGLE_STORE;

  const handleClose = async () => {
    try {
      await sendRatingDetails({
        summary: `${storeName} rating closed from CT-POOL Mobile`,
        rating: 0,
        message: 'Rating window closed',
      });
    } catch (e) {
      onClose(false);
      console.log('Failed to transmit rating:', e);
    } finally {
      onClose(false);
    }
  };

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await rpc.transmit('rate.send', {
        summary: `${storeName} rating sent from CT-POOL Mobile`,
        rating: starCount,
        message: 'Rating submitted via button',
      });
      if (hasOpenUrl) {
        await window.ct.openUrl(storeLink);
      } else {
        window.open(storeLink, '_blank');
      }
      setLoading(false);
      onClose(false);
    } catch (e) {
      onClose(false);
      setLoading(false);
      console.log('Failed to transmit rating:', e);
    }
  };

  return (
    <>
      <Close onClick={handleClose} />
      <div className={styles.header}>
        <img src={marketImg} alt="market icon" />
        <div className={styles.rating}>
          <Rating value={starCount} color="white" size={24} noNumbers />
        </div>
      </div>
      <Container>
        <Content>
          <Title>
            <Trans i18nKey={t('MTU.Modal.Feedback_Stores.Title')} values={{ store: storeName }} />
          </Title>
          <Description>
            <Trans
              i18nKey={t('MTU.Modal.Feedback_Stores.Description')}
              values={{
                link: storeLink,
                store: storeName,
              }}
              components={{ a: <a href={storeLink} target="_blank" rel="noreferrer" />, b: <strong /> }}
            />
          </Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            borderRadius={false}
            onClick={handleButtonClick}
            loading={loading}
            data-test-id="btn_confirm_modal"
          >
            {t('RateCompany.Btn')}
          </Button>
        </Footer>
      </Container>
    </>
  );
};
