import * as Sentry from '@sentry/browser';
import React, { Component, ReactNode } from 'react';

import { ModalError } from '@/page/withdraw/ui/modal-error/modal-error';
import { MetricsService } from '@/shared/lib/metrics';

interface Props {
  children: ReactNode;
}

export interface customError extends Error {
  code?: number;
}

interface State {
  hasError: boolean;
  error: customError | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error: Error): State {
    Sentry.captureException(error);
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    MetricsService.sendEvent('frontend_error', {
      stack: error.stack,
      message: error.message,
      domain: 'unexpected_error',
    });
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ModalError isOpen={this.state.hasError} error={this.state.error} onClose={() => console.log('ERROR')} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
