import { useSelector } from 'react-redux';

import { ContentSwitcher } from '@/page/cart/ui/content-switcher';
import { ContentSwitcherVariant1 } from '@/page/cart/ui/content-switcher-ab';
import { Tag } from '@/shared/lib/constants/tags';
import { selectUser } from '@/shared/store/user/user.slice';

export const AddPoolMinerPage = () => {
  const { tags } = useSelector(selectUser);

  if (tags?.includes(Tag.TEST_CART_1_B)) {
    return <ContentSwitcherVariant1 />;
  }

  return <ContentSwitcher />;
};
