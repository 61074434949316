import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeBackground, changeBgToRandom, selectCurrentBg } from '@/features/theme/model/slice';
import { SelectableColors, selectableColorsSolid } from '@/features/theme/model/types';
import { STORAGE_RANDOM_BACKGROUND } from '@/shared/lib/constants/storage-key';
import { AppDispatch } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-background.module.scss';

export const ModalBackground = ({ onClose, isOpen }: ModalCommonProps) => {
  const currentBg = useSelector(selectCurrentBg);
  const userRandomBg = localStorage.getItem(STORAGE_RANDOM_BACKGROUND);
  const [selectedBackground, setSelectedBackground] = useState(currentBg);
  const [checked, setChecked] = useState(() => userRandomBg === 'true');
  const { t } = useTranslation();
  const { Container, Title, Footer } = ModalLayout;
  const dispatch: AppDispatch = useDispatch();

  const handleChange = (bg: SelectableColors) => {
    setSelectedBackground(bg);
    setChecked(false);
  };

  const handleChangeBackground = () => {
    if (checked) {
      dispatch(changeBgToRandom());
      localStorage.setItem(STORAGE_RANDOM_BACKGROUND, 'true');
    } else {
      dispatch(changeBackground(selectedBackground));
      localStorage.setItem(STORAGE_RANDOM_BACKGROUND, 'false');
    }
    onClose(false);
  };

  const handleCheckbox = () => {
    if (checked) {
      setChecked(false);
      setSelectedBackground(currentBg);
    } else {
      setChecked(true);
    }
  };

  const handleClose = () => {
    setSelectedBackground(currentBg);
    setChecked(userRandomBg === 'true');
    onClose(false);
  };

  return (
    <ModalLayout onClose={handleClose} isOpen={isOpen} id="background-modal">
      <Container>
        <Title size="s" align="left">
          {t('Background.Title')}
        </Title>
        <div className={styles.block__content}>
          <ul className={styles.list}>
            {selectableColorsSolid.map((color, idx) => (
              <button
                className={cn(styles.item, color === selectedBackground && !checked && styles.active)}
                key={idx}
                style={{ background: color }}
                onClick={() => handleChange(color)}
                data-test-id={`btn_select_${color}`}
              >
                {!checked && (
                  <span className={styles.check}>
                    <Icon iconName="checked" width={10} height={10} />
                  </span>
                )}
              </button>
            ))}
          </ul>
        </div>
        <div className={styles.switch}>
          <span className={styles.switch__text}>{t('Background.Random')}</span>
          <label className={styles.switch__label}>
            <input
              type="checkbox"
              className={styles.switch__input}
              checked={checked}
              onChange={handleCheckbox}
              data-test-id="checkbox_random_color"
            />
            <span className={styles.switch__slider} />
          </label>
        </div>
        <Footer>
          <Button color="blue" size="m" fullWidth onClick={handleChangeBackground} data-test-id="btn_confirm">
            {t('Modal.Save.BTN')}
          </Button>
          <Button variant="filled" color="grey" size="m" fullWidth onClick={handleClose} data-test-id="btn_cancel">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
