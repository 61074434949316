import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChangeCurrencyResponse } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { initUserThunk } from '@/shared/store/rpc/rpc.slice';
import { AppDispatch } from '@/shared/store/types';
import { userAction } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { CircleProgress } from '@/shared/ui/circle-progress/circle-progress';
import { Currencies, CurrencyIcon } from '@/shared/ui/currency-icon';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-change-currency-confirm.module.scss';

interface ModalConfirmProps extends ModalCommonProps {
  activeCurrency: Currencies;
  conversionData: ChangeCurrencyResponse | null;
  currencyBalance: string;
}

export const ModalChangeCurrencyConfirm = ({
  onClose,
  isOpen,
  activeCurrency,
  conversionData,
  currencyBalance,
}: ModalConfirmProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;

  const confirmCurrencyChange = () => {
    if (conversionData?.fernet) {
      setDisabled(true);
      rpc
        .transmit('users.me.change_currency_confirm', { fernet: conversionData?.fernet })
        .then((response) => {
          dispatch(userAction.checkUserNew(response));
          dispatch(initUserThunk());
          onClose(false);
          setDisabled(false);
          toaster.success(t('balance.currency.changed'));
          navigate(-1);
        })
        .catch(() => {
          setDisabled(false);
        });
    }
  };

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="change-currency-modal">
      <IconContainer>
        <CurrencyIcon currency={activeCurrency} width={80} height={80} />
      </IconContainer>
      <span className={styles.icon__circle}>
        <CircleProgress variant="dot" width={20} duration={60000} activeCurrency={activeCurrency} />
      </span>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Balance_currency.Conversion.Confirm.Title')}</Title>
          <Description>
            <Trans
              i18nKey={t('Balance_currency.Conversion.Confirm.Descr')}
              components={{ b: <strong /> }}
              values={{ currency: activeCurrency, rate: currencyBalance }}
            />
            <span className={styles.text_important}>{t('Balance_currency.Uavailable.Title')}</span>
          </Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            onClick={confirmCurrencyChange}
            disabled={disabled}
            data-test-id="btn_confirm_modal"
          >
            {t('confirm')}
          </Button>
          <Button fullWidth color="grey" onClick={() => onClose(false)} data-test-id="btn_close_footer_modal">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
