import { mobile } from './device';
import { build, version } from './version';
// import getUtmParamsString from '@/shared/lib/utils/getUtmParamsString';

export function assembleAppParameters() {
  const av = version + '.' + build;
  return `av=${av}&v=${window?.ct?.appVersion}&app_id=${window?.ct?.bundleId}&platform=${window?.ct?.platform}`;
}
export function assembleTelegramAppParameters() {
  const av = version + '.' + build;
  const v = window.Telegram?.WebApp?.version || '0.0.0';
  return `av=${av}&v=${v}&app_id=pool.telegram.ct&platform=telegram`;
}

const app = assembleAppParameters();
const telegram = assembleTelegramAppParameters();

export function getApiUrl(path: string): string {
  const origin = window.app_config.baseAPIURL;
  return `${origin}${path}`;
}

export function getFcmUrl(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'fcm/register';
  const device = did ? '&did=' + did : '';
  return `${origin}${path}?${app}${device}`;
}

export function getOAuthLogin(platform: string, did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  let path;
  if (platform === 'apple') {
    path = 'auth/providers/apple-oauth2';
  } else {
    path = 'auth/providers/google-oauth2';
  }
  const device = did ? '&bid=' + did : '';
  return `${origin}${path}?${app}${device}`;
}

// /devices/register?platform=telegram&app_id=telegram.ct&v=1&av=2
export function getTelegramDeviceRegisterPathname(): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'devices/register';
  return `${origin}${path}?${telegram}`;
}
export function getDemoUserPathname(): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/providers/demo';
  return `${origin}${path}`;
}
export function getRefreshTokenPathname(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/tokens/refresh';
  const device = did ? '&did=' + did : '';
  return `${origin}${path}?${app}${device}`;
}
export function getLoginTokenPathnameTelegram(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/providers/telegram';
  const device = did ? '&did=' + did : '';
  return `${origin}${path}?${telegram}${device}`;
}
export function getRestorePathname(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/tokens/restore';
  const device = did ? '&did=' + did : '';
  const app = assembleAppParameters();
  return `${origin}${path}?${app}${device}`;
}
export function getLogoutPathname(did: string | null): string {
  const origin = window.app_config.baseAPIURL;
  const path = 'auth/logout';
  const device = did ? '&did=' + did : '';
  return `${origin}${path}?${app}${device}`;
}

export function getOpenSeaLink(network: string, contract_address: string, token_id: string): string {
  const proto = mobile ? 'dapp' : 'https';
  const origin = proto + '://opensea.io';
  const nft = `/${network}/${contract_address}/${token_id}`;
  if (!nft) return origin;
  return origin + '/assets' + nft;
}

export function getOpenSeaLinkSell(network: string, contract_address: string, token_id: string): string {
  const link = getOpenSeaLink(network, contract_address, token_id);
  return link + '/sell';
}
