import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useTimerWithDeadline } from '@/shared/lib/hooks/useTimerWithDeadline';
import { Icon } from '@/shared/ui/icons';

import styles from './mega-sale-banner.module.scss';

export const MegaSaleBanner = () => {
  const { t } = useTranslation();
  const { timeRemaining, showColon, isTimeOver } = useTimerWithDeadline('2024-11-17T23:59:00Z');

  if (isTimeOver || timeRemaining === null) return null; // Don't show until calculated or if time is over

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <Icon iconName="eleven-eleven" width={64} height={30} />
      </div>
      <div className={styles.info}>
        <div className={styles.info__title}>{t('11.11.Purchase_scr.Row_1')}</div>
        <div className={styles.info__timer}>
          <Trans
            i18nKey={'11.11.Popup.TIme'}
            components={{
              b: <b />,
              span: <span />,
              div: <div />,
            }}
            values={{ days: timeRemaining.days, hours: timeRemaining.hours, colon: showColon ? ':' : ' ' }}
          />
        </div>
      </div>
    </div>
  );
};
