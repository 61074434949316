import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useGoogleStatus } from '@/page/cart/store/cart-google-store';
import { AddMinerVariant1 } from '@/shared/ui/kit/add-google-miner-kit/add-miner';
import { CurrencySwitcherType } from '@/shared/ui/kit/currency-switcher/currency-switcher';
import { LoaderMobile } from '@/shared/ui/loader/loader';

import s from './content-switcher-variant1.module.scss';

export const ContentSwitcherVariant1 = () => {
  const status = useSelector(useGoogleStatus);
  const isLoading = status === 'loading';
  const [activeTab, setActiveTab] = useState<CurrencySwitcherType>('google-play');

  if (isLoading) {
    return <LoaderMobile />;
  }

  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <AddMinerVariant1 handleSwitchTab={setActiveTab} activeTab={activeTab} />
      </div>
    </div>
  );
};
