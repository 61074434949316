import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSupportLink } from '@/shared/lib/hooks/useSupportLink';
import { Button } from '@/shared/ui/button';
import { customError } from '@/shared/ui/error-boundary/error-boundary';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

interface ModalErrorProps extends ModalCommonProps {
  error?: customError | null;
}

export const ModalError = ({ onClose, error, isOpen }: ModalErrorProps) => {
  const { IconContainer, Container, Content, Title, Description, Footer, Type } = ModalLayout;
  const linkSupport = useSupportLink();
  const { t } = useTranslation();

  const reload = useCallback(() => (window.location.pathname = '/'), [window]);

  return (
    <ModalLayout type={Type.Page} onClose={() => onClose(false)} isOpen={isOpen} id="error">
      <IconContainer>
        <Icon iconName="error" width={80} height={80} />
      </IconContainer>
      <Container>
        <Content>
          <Title>{t('Modal.Error_Unexpected.Title')}</Title>
          <Description>
            <Trans
              i18nKey={t('Modal.Error_Unexpected.Description')}
              values={{ error: error?.code || error?.message }}
            />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={() => reload()}>
            {t('Modal.Error_Unexpected.Btn')}
          </Button>
          <Button as="a" href={linkSupport} target="_blank" color="grey" fullWidth>
            <Icon iconName="link" width={24} height={24} />
            {t('Modal.Error_Unexpected.BtnSupport')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
