import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ChangeCurrencyResponse } from '@/shared/lib/backend/JsonRpcApi';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { checkE } from '@/shared/lib/utils/checE';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { isEffectivelyZero } from '@/shared/lib/utils/isEffectivelyZero';
import { NC_WALLET } from '@/shared/lib/utils/links';
import { formatBalance } from '@/shared/lib/utils/units';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Button } from '@/shared/ui/button';
import { CircleProgress } from '@/shared/ui/circle-progress/circle-progress';
import { Icon } from '@/shared/ui/icons';
import { FixedLoader } from '@/shared/ui/loader/loader';

import styles from './conversion.module.scss';

interface ConversionProps {
  activeCurrency: string;
  isLoading: boolean;
  conversionData: ChangeCurrencyResponse | null;
  changeCurrency: () => void;
}

export const Conversion = ({ activeCurrency, conversionData, changeCurrency, isLoading }: ConversionProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { pool_balance, mining_currencies } = useSelector((store: RootState) => store.balance);
  const { currency } = useSelector(selectUser);
  const fromAmount = pool_balance?.total;
  const toAmount =
    currency === activeCurrency ? fromAmount : conversionData ? checkE(+fromAmount * +conversionData.rate) : '';
  const currentCurrencyObj = mining_currencies?.find((cur) => cur?.symbol === currency);
  const activeCurrencyObj = mining_currencies?.find((cur) => cur?.symbol === activeCurrency);
  const formattedBalanceFrom = currentCurrencyObj ? formatBalance(fromAmount, currentCurrencyObj?.unit) : '0.00';
  const formattedBalanceTo = activeCurrencyObj ? formatBalance(toAmount, activeCurrencyObj?.unit) : '0.00';
  const [circleKey, setCircleKey] = useState(0); // Key to force re-render

  const handleButtonClick = () => {
    setCircleKey((prevKey) => prevKey + 1); // Increment the key to restart the animation
    if (conversionData?.rate) {
      openModal('MODAL_SWITCH_BALANCE_CONFIRM', {
        activeCurrency,
        conversionData,
        currencyBalance: getLocalAmount(formattedBalanceTo),
        isServices: true,
      });
    } else {
      changeCurrency();
    }
  };

  return (
    <div className={styles.root}>
      {isLoading && isEffectivelyZero(pool_balance?.total) && <FixedLoader />}
      {!isEffectivelyZero(pool_balance?.total) ? (
        <div className={styles.top}>
          <h4 className={styles.title}>{t('Balance_currency.Conversion.Title')}</h4>
          <div className={styles.currency}>
            {/*from currency*/}
            <img src={currentCurrencyObj?.img} alt="title" width={30} height={30} />
            <strong>{getLocalAmount(formattedBalanceFrom)}</strong> {currency}
          </div>
          <div className={styles.switch}>
            <Icon className={styles.arrow} iconName="arrow" width={20} height={20} />
            <strong>
              {currency} / {activeCurrency}
            </strong>
            {/*<span className={styles.rate}>{conversionData?.rate}</span>*/}
            {activeCurrency !== currency ? (
              <CircleProgress
                key={circleKey}
                variant="dot"
                width={16}
                onDurationEnds={changeCurrency}
                activeCurrency={activeCurrency}
              />
            ) : null}
          </div>
          <div className={styles.currency}>
            <img src={activeCurrencyObj?.img} alt="title" width={30} height={30} />
            {/*to currency*/}
            <strong>{getLocalAmount(formattedBalanceTo)}</strong>
            {activeCurrency}
          </div>
          <p className={styles.text}>{t('Balance_currency.Uavailable.Title')}</p>
          <div className={styles.wallet}>
            <NCWalletIcon width={40} height={40} className={styles.icon} />
            <p>
              <Trans
                i18nKey={t('Balance_currency.NCW_block.Description')}
                components={{ a: <a href={NC_WALLET} target="_blank" rel="noreferrer" /> }}
              />
            </p>
          </div>
        </div>
      ) : null}
      <div className={styles.footer}>
        <Button
          fullWidth
          color="blue"
          onClick={handleButtonClick}
          data-test-id="btn_currency_continue"
          disabled={activeCurrency === currency || isLoading}
        >
          {t('CONTINUE')}
          <Icon iconName="arrow" width={20} height={20} />
        </Button>
      </div>
    </div>
  );
};
