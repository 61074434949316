import React from 'react';

import { NotFoundView } from './view/NotFoundView';

export const NotFound = () => {
  return (
    <>
      <NotFoundView />
    </>
  );
};
