import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCurrentUtmSource } from '@/features/crypto-tab-products/hooks/use-current-utm-source';
import { rpc } from '@/shared/lib/backend/Rpc';
import { generatePageRefLink, generatePoolRefLink } from '@/shared/lib/utils/generatedRefLinks';
import { selectUser } from '@/shared/store/user/user.slice';

import { ShortUrls } from '../backend/JsonRpcApi';

const isShort = Math.random() > 0.5;

export const useShareProduct = () => {
  const { id } = useSelector(selectUser);
  const { t } = useTranslation();
  const utm_source = useCurrentUtmSource();
  const generatedPageRefLink = useMemo(() => generatePageRefLink(id, isShort, utm_source), [id, utm_source]);
  const generatedPoolRefLink = useMemo(() => generatePoolRefLink(id, isShort, utm_source), [id, utm_source]);

  const [pageRefLink, setPageRefLink] = useState<string>(generatedPageRefLink);
  const [poolRefLink, setPoolRefLink] = useState<string>(generatedPoolRefLink);

  useEffect(() => {
    const fetchShortUrls = async () => {
      try {
        const res: ShortUrls = await rpc.transmit('short_urls.get', {
          urls: [generatedPageRefLink, generatedPoolRefLink],
        });

        setPageRefLink(res.urls[0]);
        setPoolRefLink(res.urls[1]);
      } catch (e) {
        console.warn('Error fetching short URLs:', e);
      }
    };
    if (id) {
      fetchShortUrls().then();
    }
  }, [id, generatedPageRefLink, generatedPoolRefLink]);

  const text = t(isShort ? 'AfP.Sharing_textShort' : 'AfP.SharingText', {
    page_link: pageRefLink,
    ref_link: poolRefLink,
    interpolation: { escapeValue: false },
  });

  return { pageRefLink, poolRefLink, text };
};
