import { createContext, PropsWithChildren, useCallback, useRef, useState } from 'react';

import { ModalName } from '@/shared/lib/context/modal/modal-render';
import { MetricsService } from '@/shared/lib/metrics';

export type TModalContext = {
  modal: Modal | null;
};

export type TModalSetContext = {
  // eslint-disable-next-line
  openModal: (name: ModalName, options?: any) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<TModalContext | null>(null);
export const ModalSetContext = createContext<TModalSetContext | null>(null);

export type Modal = {
  name: ModalName;
  // eslint-disable-next-line
  options?: any;
};

export const ModalContextProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const nameRef = useRef<string | null>(null);
  const startTimeRef = useRef<number | null>(null);

  // eslint-disable-next-line
  const openModal = useCallback((name: ModalName, options?: any) => {
    setModal({
      name,
      options: options,
    });
    MetricsService.sendEvent('modal_open', {
      name,
      url: window.location.href,
      options,
    });

    nameRef.current = name;
    startTimeRef.current = Date.now();
  }, []);

  const closeModal = useCallback(() => {
    const timeSpent = startTimeRef.current ? Date.now() - startTimeRef.current : 0;

    setModal(null);

    if (nameRef.current) {
      MetricsService.sendEvent('modal_close', {
        name: nameRef.current,
        url: window.location.href,
        timeSpent,
      });
    }
  }, []);

  return (
    <ModalContext.Provider value={{ modal }}>
      <ModalSetContext.Provider value={{ openModal, closeModal }}>{children}</ModalSetContext.Provider>
    </ModalContext.Provider>
  );
};
