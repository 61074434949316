import { useCallback, useState } from 'react';

import { FailBlock } from '@/features/rate-us/ui/fail-block/fail-block';
import { Feedback } from '@/features/rate-us/ui/feedback/feedback';
import { MarketBlock } from '@/features/rate-us/ui/market-block/market-block';
import { RatingBlock } from '@/features/rate-us/ui/rating-block/rating-block';
import { SuccessBlock } from '@/features/rate-us/ui/success-block/success-block';
import { RATING } from '@/shared/lib/constants/storage-key';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useRating } from '@/shared/lib/hooks/useRating';
import { delay } from '@/shared/lib/utils/delay';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

const SUMMARY_TEXT = 'Feedback rating from CT-POOL Mobile';

export const RateUsModal = ({ onClose, isOpen }: ModalCommonProps) => {
  const [rating, setRating] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState<'rating' | 'feedback' | 'market' | 'success' | 'fail'>('rating');
  const { isTelegramMiniApp } = useDevice();
  const { sendRatingDetails, ratingIcons } = useRating();

  const handleOnRate = useCallback(async (rating: number) => {
    setRating(rating);
    await delay(200);
    setLoading(true);
    await delay(2000);
    setLoading(false);
    localStorage.setItem(RATING, rating.toString());
    if (rating <= 3) {
      setNext('feedback');
    } else {
      setNext('market');
    }
  }, []);

  const clickFeedback = async (text: string) => {
    setLoading(true);
    try {
      await sendRatingDetails({
        summary: SUMMARY_TEXT,
        rating: rating,
        message: text,
      });
      setLoading(false);
      setNext('success');
    } catch (e) {
      if (e instanceof Error) {
        setLoading(false);
        setNext('fail');
        console.log(e.message);
      }
    }
  };

  const handleClose = async () => {
    try {
      await sendRatingDetails({
        summary: SUMMARY_TEXT,
        rating: 0,
        message: 'Rating window closed',
      });
    } catch (e) {
      console.log('Failed to transmit rating:', e);
    }
    onClose(false);
  };

  return (
    <ModalLayout onClose={handleClose} isOpen={isOpen} id="rate-us">
      {next === 'rating' && !isTelegramMiniApp && (
        <RatingBlock
          ratingIcons={ratingIcons}
          onClose={handleClose}
          handleOnRate={handleOnRate}
          rating={rating}
          loading={loading}
        />
      )}
      {next === 'feedback' && <Feedback onClose={handleClose} onClick={clickFeedback} loading={loading} />}
      {next === 'market' && <MarketBlock starCount={rating} onClose={onClose} />}
      {next === 'success' && <SuccessBlock onClose={onClose} />}
      {next === 'fail' && <FailBlock onClose={onClose} next={setNext} />}
    </ModalLayout>
  );
};
