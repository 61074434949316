import cn from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import Modal from 'react-modal';

import uniq from './modal-default.module.scss';
import common from '../modal.module.scss';

interface ModalDefaultProps {
  onClose: () => void;
  isOpen?: boolean;
  id: string;
  className?: string;
  removeCloseButton?: boolean;
  children: ReactNode;
}

export const ModalDefault = ({ onClose, isOpen, id, children, className }: ModalDefaultProps) => (
  <Modal
    className={cn(uniq.modal, className)}
    isOpen={isOpen}
    overlayClassName={common.overlay}
    onRequestClose={onClose}
    id={id}
  >
    {children}
  </Modal>
);
