import { useMemo } from 'react';

import { useDevice } from '@/shared/lib/hooks/useDevice';

// Pool Telegram - utm_source=plt
// Pool Android - utm_source=pla
// Pool iOS - utm_source=pli

export type utmSource = 'plt' | 'pli' | 'pla';

export const useCurrentUtmSource = (): utmSource => {
  const { isIos, isTelegramMiniApp } = useDevice();
  return useMemo(() => (isTelegramMiniApp ? 'plt' : isIos ? 'pli' : 'pla'), [isIos, isTelegramMiniApp]);
};
