import cn from 'classnames';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { rpc } from '@/shared/lib/backend/Rpc';
import { CONFIRMED_EMAIL } from '@/shared/lib/constants/storage-key';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { AppDispatch } from '@/shared/store/types';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { ErrorText } from '@/shared/ui/error-text/error-text';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './btc-connect-modal.module.scss';

export const BtcConnectModal = ({ onClose, isOpen }: ModalCommonProps) => {
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const handleConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await rpc.transmit('wallets.check_address', { address });

      if (res) {
        let addressToLocal;
        if ('email' in res) {
          addressToLocal = { account_id: res.account_id, email: res.email };
        } else {
          addressToLocal = { address };
        }

        localStorage.setItem(CONFIRMED_EMAIL, JSON.stringify(addressToLocal));
        dispatch(withdrawalAction.addNewAddress(addressToLocal));
        toaster.success(t('Successfully_Connected'));
        navigate(WITHDRAW_PATH);
      }
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, address, t, navigate]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setAddress(e.target.value.trim());
  }, []);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="connect-btc">
      <IconContainer>
        <Icon iconName="btc" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Enter_BTC.Title')}</Title>
          <Description>{t('Enter_BTC.Descr')}</Description>
        </Content>
        <label htmlFor="address" className={styles.label}>
          <input
            id={'address'}
            value={address}
            onChange={(e) => handleChange(e)}
            className={cn(styles.input, isLoading && styles.input_loading, isError && styles.input_error)}
            placeholder={t('Enter_BTC.Placehold')}
          />
          {isError && <ErrorText text={t('Enter_BTC.Error')} />}
        </label>
        <Footer>
          <Button color="blue" fullWidth onClick={handleConfirm} loading={isLoading} data-test-id="btn_confirm_modal">
            {t('confirm')}
          </Button>
          <Button color="grey" fullWidth onClick={() => onClose(false)} data-test-id="btn_close_footer_modal">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
