import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/shared/store/types';

export type TutorialState = {
  tutorialName?: string;
  step: number;
  clientRect: DOMRect | null;
  isActive: boolean;
};

const initialState: TutorialState = {
  tutorialName: undefined,
  step: -1,
  clientRect: null,
  isActive: false,
};

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setClientRect(state, action) {
      state.clientRect = action.payload;
    },
    startTutorial(state, action: PayloadAction<string>) {
      state.step = 0;
      state.tutorialName = action.payload;
      state.isActive = true;
    },
    nextTutorialStep(state) {
      state.step += 1;
    },
    endTutorial(state) {
      state.tutorialName = undefined;
      state.step = -1;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      state.isActive = false;
    },
  },
});

export const tutorialReducer = tutorialSlice.reducer;

export const selectTutorial = (state: RootState) => state.tutorial;

export const { startTutorial, nextTutorialStep, endTutorial, setClientRect } = tutorialSlice.actions;
