import { useEffect, useState } from 'react';

export function useWindowSize(breakpoint: number | string) {
  const [isWider, setIsWider] = useState(() => window.innerWidth < +breakpoint);
  useEffect(() => {
    function handleResize() {
      setIsWider(window.innerWidth < +breakpoint);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);
  return isWider;
}
