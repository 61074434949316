import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { MenuNavItem } from '@/features/menu/ui/menu-nav-item/menu-nav-item';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useSupportLink } from '@/shared/lib/hooks/useSupportLink';
import { POOL_TERMS } from '@/shared/lib/utils/links';

import styles from './menu.navigation.module.scss';

export const MenuNavigation = ({ isTelegramMiniApp }: { isTelegramMiniApp?: boolean }) => {
  const linkSupport = useSupportLink();
  const { t } = useTranslation();
  const { openModal } = useModalSet();

  return (
    <ul className={styles.root}>
      {!isTelegramMiniApp && (
        <MenuNavItem onClick={() => openModal('MODAL_RATE_US', { isServices: true })} data-test-id="btn_open_rate_us">
          <Trans i18nKey="rate_ctpool_title" components={{ b: <b /> }} />
        </MenuNavItem>
      )}
      <MenuNavItem link={linkSupport} data-test-id="link_support">
        {t('Contact Us')}
      </MenuNavItem>
      <MenuNavItem link={POOL_TERMS} data-test-id="link_terms">
        {t('Terms of Use')}
      </MenuNavItem>
    </ul>
  );
};
