export const HOME_PATH = '/';
export const POOL_MINING = '/pool-mining';
export const CART_PATH = '/cart';
export const WITHDRAW_PATH = '/withdraw';
export const MENU_PATH = '/menu';
export const LOGIN_PATH = '/login';
export const MODAL_DEBUG = '/modal-debug';
export const HISTORY_PATH = '/history';
export const TUTORIAL_PATH = '/tutorial';
export const SWITCH_BALANCE_PATH = '/switch-balance';
export const NC_WALLET_CONNECT = '/ncw-connect';
export const CRYPTO_TAB_PRODUCTS_PATH = '/crypto-tab-products';
export const CRYPTO_TAB_REVIEW = '/crypto-tab-review';
export const BONUS_PROGRAM = '/bonus-program';

export const GOOGLE_SUBSCRIPTIONS = 'https://play.google.com/store/account/subscriptions';
export const APPLE_SUBSCRIPTIONS = 'https://apps.apple.com/account/subscriptions';
export const BROWSER_PROMO = '/pool/promo';
export const FARM_PROMO = '/farm/promo';
export const POOL_PROMO = '/pool/promo';

export const POOL_FEEDBACK = 'https://app.ctpool.net/support';
export const SUPPORT_SUSPENDED = 'https://cryptobrowser.site/app/suspend';
export const BROWSER_BANNED = 'https://cryptobrowser.site/app/banned';
export const BROWSER_SETTINGS = 'https://cryptobrowser.site/app/settings';
export const BROWSER_JOURNAL = 'https://cryptobrowser.site/journal/';
export const POOL_TERMS = 'https://ctpool.net/terms/';
export const POOL_PRIVACY_POLICY = 'https://ctpool.net/privacy/';
export const BROWSER_NEWS = '/news/';

export const CT_STORE = 'https://market.ctnft.net/';
export const CT_NFT = 'https://cryptotabnft.com/';
export const CT_CLICK = 'https://cb.click';
export const CT_START = 'https://start.cryptobrowser.site/';

export const PLAY_GOOGLE_NC_WALLET =
  'https://ncwallet.page.link/?link=https%3A%2F%2Fapp.ncwallet.net%3Fref%3Dpla.products..&apn=com.ncwallet&amv=0&ibi=com.ncwallet&imv=0&isi=1615381976';
export const PLAY_APPLE_NC_WALLET =
  'https://apps.apple.com/app/ncwallet/id1615381976?utm_source%3Dpli%26utm_medium%3Dproducts';

export const POOL_LANDING = 'https://ctpool.net/';

export const CONNECT = '/connect/';

export const AGE_EGGS_LANDING = '/landing/age-of-eggs/#features';

export const TRANSACTION_DETAILS = 'https://polygonscan.com/tx';

export const NC_WALLET = 'https://ncwallet.net/try';
export const LINK_TO_FEE = 'https://jochen-hoenicke.de/queue/#BTC%20(default%20mempool),2w,fee';
export const RATING_GOOGLE_STORE = 'https://play.google.com/store/apps/details?id=net.ctpool';
export const RATING_APPLE_STORE = 'https://apps.apple.com/app/id6503949346?action=write-review';
export const CRYPTO_COMPANY_GOOGLE_RATE = 'https://g.page/r/CUrO2uwA_dmiEAE/review';
export const POOL_REF_LINK_BASE = 'https://ctpool.net/';
export const PAGE_REF_LINK_BASE = 'https://cryptotabbrowser.com/try-products/';

export const CT_LITE = 'https://cryptobrowser.site/page-link/lite?utm_source=pla&utm_medium=products';
export const CT_PRO = 'https://cryptobrowser.site/page-link/pro?utm_source=pla&utm_medium=products';
export const CT_MAX = 'https://cryptobrowser.site/page-link/max?utm_source=pla&utm_medium=products';
export const CT_FARM =
  'https://cryptofarm.page.link/?link=https%3A%2F%2Fapp.cryptotab.farm%2F%3Fref%3Dpla.products...UEeG-S7YFAohGJcVdhg%253AOPTBsl2dHx4fGO9LQQ0&apn=com.cryptofarm&amv=0';
export const CT_FARM_PRO =
  'https://cryptofarm.page.link/?link=https%3A%2F%2Fapp.cryptotabfarm.com%2F%3Fref%3Dpla.products...UEeG-S7YFAohGJcVdhg%253AOPTBsl2dHx4fGO9LQQ0&apn=com.ctfarm&amv=0';
export const CT_VPN =
  'https://ctvpn.page.link/?link=https%3A%2F%2Fct-vpn.com%2F%3Fref%3Dpla.products...5mOCsO0vs20fGHLaeeg%253AOPTBsl2dHx4fGO9LQQ0&apn=com.ctvpn.android&amv=0';
export const CT_TAGS =
  'https://play.google.com/store/apps/details?id=com.cryptotabtags&referrer=utm_source%3Dpla%26utm_medium%3Dproducts';
