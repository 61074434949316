export const errors = {
  token_invalid: { code: 1000 },
  token_expired: { code: 1001 },
  connection_closed: { code: -32300 },
  user_banned: { code: 2002 },
  user_not_found: { code: 2001 },
  unauthorized: { code: 2000 },
  insufficient_funds: { code: 7004 },
  low_amount: { code: 7002 },
  wallet_not_found: { code: 16000 },
  invalid_address: { code: 1006 },
  failed_fetch: { code: 429 },
  withdraw_suspended: { code: 7001 },
  sku_not_found: { code: 13001 },
  trial_not_available: { code: 13002 },
};
