import get from 'lodash/get';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getDeviceId, searchParameters } from '@/app/init';
import { changeIsTelegramMiniApp, selectCurrentBg, selectCurrentTheme } from '@/features/theme/model/slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { Language, languages } from '@/shared/lib/constants/languages';
import { I18_NEXT_LNG, TOKENS } from '@/shared/lib/constants/storage-key';
import useCloudStorage from '@/shared/lib/hooks/useCloudStorage';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import useJsonRpc from '@/shared/lib/hooks/useJsonRpc';
import { useTelegram } from '@/shared/lib/hooks/useTelegram';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { MetricsService } from '@/shared/lib/metrics';
import { HOME_PATH } from '@/shared/lib/utils/links';
import { clearBalance } from '@/shared/store/balance/balance.slice';
import { getLanguage, isValidCode, setLanguage } from '@/shared/store/language';
import { initApp, selectRpcInitiated, setRpcAuthed, setRpcInitiated, setRpcStatus } from '@/shared/store/rpc/rpc.slice';
import { AppDispatch } from '@/shared/store/types';
import { getTelegramAccessTokens, logoutToken, selectUser, userAction } from '@/shared/store/user/user.slice';
import { setUtmObg, utmObjType } from '@/shared/store/utm/utm.slice';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import { FixedLoader } from '@/shared/ui/loader/loader';

export const EffectAppContainer = ({ children }: PropsWithChildren) => {
  const rpcInited = useSelector(selectRpcInitiated);
  const { isAuthChecked, access_token, refresh_token } = useSelector(selectUser);
  const currentTheme = useSelector(selectCurrentTheme);
  const currentBg = useSelector(selectCurrentBg);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { tg, initData, initDataUnsafe, startParam, decodedStartParam } = useTelegram();
  const { isReactNativeApp, isTelegramMiniApp } = useDevice();
  const { setItem, getItem } = useCloudStorage();
  const navigation = useNavigate();
  const userLanguage = isTelegramMiniApp ? initDataUnsafe?.user?.language_code : undefined;
  const currentLocalLang = localStorage?.getItem(I18_NEXT_LNG);
  const code = getLanguage();
  const { markTutorialSeen } = useTutorialStorage();

  useEffect(() => {
    const initMetricsService = async () => {
      if (!isReactNativeApp && !isTelegramMiniApp) {
        return;
      }

      let dsp = null;

      if (isTelegramMiniApp) {
        dsp =
          decodedStartParam && decodedStartParam.startsWith('R:') ? decodedStartParam : startParam ? startParam : null;
      }

      //init value DID in localstorage
      const did = await getDeviceId({
        isReactNativeApp,
        isTelegramMiniApp,
        setItem,
        getItem,
        decodedStartParam: dsp,
      });

      const params = searchParameters({ isReactNativeApp, isTelegramMiniApp, setItem, getItem });

      MetricsService.setParam('av', params.av);
      MetricsService.setParam('v', params.v);
      MetricsService.setParam('app_id', params.appId);
      MetricsService.setParam('platform', params.platform);
      MetricsService.setParam('did', did);

      if (isReactNativeApp) {
        const gaid = await window.ct.getGaid();
        const gsid = await window.ct.getGsid();
        MetricsService.setParam('gaid', gaid);
        MetricsService.setParam('gsid', gsid);
      }

      MetricsService.setSessionMeta('window', {
        height: get(window, 'screen.height'),
        width: get(window, 'screen.width'),
        pixelDepth: get(window, 'screen.pixelDepth'),
      });

      MetricsService.setSessionMeta('theme', currentTheme);
      MetricsService.setSessionMeta('bg_color', currentBg);

      MetricsService.initialize();
    };
    initMetricsService().catch(console.error);
  }, [isReactNativeApp, isTelegramMiniApp]);

  useEffect(() => {
    const handleLogout = async () => {
      await dispatch(logoutToken({ refresh_token: refresh_token || '' }));
      dispatch(clearBalance());
      dispatch(withdrawalAction.clearWithdrawalsToShow());
      dispatch(userAction.setBanned(false));
      await rpc.close();
      dispatch(setRpcStatus(''));
      dispatch(setRpcInitiated(false));
      dispatch(setRpcAuthed(false));
      dispatch(userAction.unAuth());
      dispatch(userAction.clearToken());
      setIsLoading(false);
      navigation(HOME_PATH);
    };

    const handleLoginOrLogout = async () => {
      if (isTelegramMiniApp) {
        if (!rpcInited) {
          markTutorialSeen();
          //for init value in localstorage
          const dsp =
            decodedStartParam && decodedStartParam.startsWith('R:')
              ? decodedStartParam
              : startParam
                ? startParam
                : null;
          // DID in localstorage inited in MetricsService
          await dispatch(
            getTelegramAccessTokens({
              telegramToken: initData,
              telegramUserId: initDataUnsafe?.user?.id.toString() || '',
            })
          );
          await dispatch(
            initApp({
              isReactNativeApp,
              isTelegramMiniApp,
              setItem,
              getItem,
              decodedStartParam: dsp,
            })
          );
        } else if (isAuthChecked) {
          const tokens = localStorage.getItem(TOKENS);
          const tokensParsed = tokens ? JSON.parse(tokens) : null;
          if (tokensParsed?.telegramId && initDataUnsafe?.user?.id.toString() !== tokensParsed?.telegramId) {
            setIsLoading(true);
            await handleLogout();
          }
        }
      }
      if (isReactNativeApp) {
        if (!rpcInited) {
          if (access_token || refresh_token) {
            await dispatch(initApp({ isReactNativeApp, isTelegramMiniApp, setItem, getItem }));
          } else {
            dispatch(userAction.setLoading(false));
            dispatch(userAction.checkAuth());
          }
        }
      }
    };
    handleLoginOrLogout().catch(console.error);
  }, [
    dispatch,
    rpcInited,
    isAuthChecked,
    isTelegramMiniApp,
    isReactNativeApp,
    initData,
    initDataUnsafe?.user?.id,
    navigation,
  ]);

  // Resize to full Mini App window on open
  useEffect(() => {
    if (isTelegramMiniApp) {
      tg?.expand();
    }
  }, [isTelegramMiniApp, tg]);

  // Effect to check if the app is running inside Telegram and update Redux state
  useEffect(() => {
    dispatch(changeIsTelegramMiniApp(isTelegramMiniApp));
  }, [isTelegramMiniApp, dispatch]);

  const [langObj, setLangObj] = useState<Language>(() => {
    if (isTelegramMiniApp && isValidCode(userLanguage)) {
      return languages[userLanguage];
    }
    return languages[code] ?? languages.en;
  });

  useEffect(() => {
    if (isTelegramMiniApp && isValidCode(userLanguage) && !currentLocalLang) {
      setLangObj(languages[userLanguage]);
    } else if (isValidCode(currentLocalLang)) {
      setLangObj(languages[currentLocalLang]);
    } else {
      setLangObj(languages[code] ?? languages.en);
    }
  }, [code, userLanguage, isTelegramMiniApp, currentLocalLang]);

  useEffect(() => {
    setLanguage(langObj?.code || 'en');
  }, [langObj]);

  useJsonRpc();

  useEffect(() => {
    const utm_obj: utmObjType = {};
    const search = new URLSearchParams(window.location.search);
    search.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utm_obj[key] = value;
      }
    });
    if (decodedStartParam) {
      utm_obj['ref'] = decodedStartParam;
    }
    if (Object.keys(utm_obj).length > 0) {
      dispatch(setUtmObg(utm_obj));
    }
  }, [dispatch]);

  if (isLoading) {
    return <FixedLoader />;
  }

  return children;
};
