import { Social } from '@/shared/ui/social/social';

import styles from './menu-social.module.scss';

export const MenuSocial = () => {
  return (
    <div className={styles.root}>
      <Social />
    </div>
  );
};
