import { useMemo } from 'react';

const tg = window.Telegram && Telegram?.WebApp;

type invoiceEventsType = {
  [key: string]: (status: string) => void;
};

const invoiceEvents: invoiceEventsType = {};

const onInvoice = (url: string, callback: (status: string) => void) => {
  invoiceEvents[url] = callback;
};

if (tg) {
  tg.onEvent('invoiceClosed', (res) => {
    if (invoiceEvents[res.url]) {
      invoiceEvents[res.url](res.status);
    }
  });
}

export function useTelegram() {
  const onClose = () => {
    tg?.close();
  };

  const isReady = tg?.ready();
  const cloudStorage = tg?.CloudStorage;
  const platform = tg?.platform;
  const initData = tg?.initData;
  const initDataUnsafe = tg?.initDataUnsafe;
  const user = initDataUnsafe?.user;
  const queryId = initDataUnsafe?.query_id;

  const startParam = useMemo(() => {
    // Check if the start_param in telegram initDataUnsafe
    let start: string | undefined = initDataUnsafe?.start_param;
    if (!start) {
      // Check if the start param is in the URL
      const urlParams = new URLSearchParams(window.location.search);
      console.log('urlParams', urlParams.toString());
      start = urlParams.get('start') || undefined;
    }
    return start;
  }, [initDataUnsafe?.start_param]);

  // Decode the start params using Base64
  const decodedStartParam = useMemo(() => {
    if (!startParam) {
      return null;
    }
    try {
      // Decode as Base64
      console.log('atob(startParam) decoded', atob(startParam));
      return atob(startParam);
    } catch (error) {
      console.error('Failed to decode Base64 start_param:', error);
      return null;
    }
  }, [startParam]);

  return {
    onClose,
    tg,
    isReady,
    platform,
    cloudStorage,
    initData,
    initDataUnsafe,
    user,
    queryId,
    startParam,
    decodedStartParam,
    onInvoice,
  };
}
