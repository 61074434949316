import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppStoreIcon, GooglePlayIcon, TelegramStar } from '@/page/cart/assets';
import { UrlCartTab, useInitUrlParams } from '@/page/cart/hooks/use-init-url-params';
import {
  useGoogleCurrentPlan,
  useGoogleHashrate,
  useGoogleIsAvailable,
  useGoogleMiningDurations,
  useGoogleStatus,
} from '@/page/cart/store/cart-google-store';
import { setHashrate, useHashrate, useHashrates, useIsCryptoEnable } from '@/page/cart/store/cart-store';
import { AddGoogleMiner } from '@/page/cart/ui/add-google-miner';
import { AddIosMiner } from '@/page/cart/ui/add-ios-miner/add-ios-miner';
import { AddMiner } from '@/page/cart/ui/add-miner';
import { AddMinerTelegram } from '@/page/cart/ui/add-miner-telegram';
import { MakeOrderButton } from '@/page/cart/ui/make-order-button';
import { MakeOrderTelegramButton } from '@/page/cart/ui/make-order-telegram-button';
import { PurchaseSubsButton } from '@/page/cart/ui/purchase-subs-button/purchase-subs-button';
import { PurchaseSubsButtonIos } from '@/page/cart/ui/purchase-subs-button-ios/purchase-subs-button-ios';
import { RestoreSubsButton } from '@/page/cart/ui/restore-subs-button/restore-subs-button';
import { PURCHASE_INFO_BLOCK_SHOWED } from '@/shared/lib/constants/storage-key';
import { Tag } from '@/shared/lib/constants/tags';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { CurrencyIcon } from '@/shared/ui/currency-icon';
import { Icon } from '@/shared/ui/icons';
import { InfoContentBlock } from '@/shared/ui/info-block/info-block';
import { LoaderMobile } from '@/shared/ui/loader/loader';
import { Tabs } from '@/shared/ui/tabs';

import s from './content-switcher.module.scss';

export const ContentSwitcher = () => {
  const { t } = useTranslation();
  const { platformCt } = useDevice();
  const { Tab, List, Panel } = Tabs;
  const miningDurations = useSelector(useGoogleMiningDurations);
  const status = useSelector(useGoogleStatus);
  const isLoading = status === 'loading';
  const isAvailable = useSelector(useGoogleIsAvailable);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const isCryptoEnable = useSelector(useIsCryptoEnable);
  const { tags } = useSelector(selectUser);
  const { isTelegramMiniApp } = useDevice();
  const mobileHashrate = useSelector(useGoogleHashrate);
  const cryptoHashrate = useSelector(useHashrate);
  const cryptoHashrates = useSelector(useHashrates);
  const isSubscription = useSelector((state: RootState) => state.googleCart.isSubscriptionPurchase);
  const dispatch: AppDispatch = useDispatch();
  const hasGoogleMining = miningDurations ? miningDurations.length > 0 : false;
  const [currentTab, setCurrentTab] = useState<string>('');
  const [isSticky, setIsSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { currentTabParam } = useInitUrlParams({ isDataReady: !isLoading });

  // Default panel logic
  useEffect(() => {
    if (isLoading) return;

    let panel = 'crypto'; // Fallback to 'crypto' only if neither google nor ios is selected

    if (isTelegramMiniApp && currentTabParam === UrlCartTab.Telegram) {
      panel = 'telegram';
    } else if (platformCt === 'ios' && (currentTabParam === UrlCartTab.Fiat || !isCryptoEnable)) {
      panel = 'ios';
    } else if (hasGoogleMining && (currentTabParam === UrlCartTab.Fiat || !isCryptoEnable)) {
      panel = 'google';
    }

    setCurrentTab(panel);
  }, [isLoading, isCryptoEnable, isTelegramMiniApp, platformCt, currentTabParam]);

  const handleScroll = () => {
    if (containerRef.current) {
      const buttonTop = containerRef.current.getBoundingClientRect().top + 50;
      const windowHeight = window.innerHeight;

      if (buttonTop >= windowHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSwitchTab = useCallback(() => {
    if (cryptoHashrate < mobileHashrate || isTelegramMiniApp) {
      const hashrate = cryptoHashrates.find((h) => h > mobileHashrate);
      hashrate && dispatch(setHashrate({ hashrate }));
    }
    setCurrentTab('crypto');
  }, [dispatch, cryptoHashrate, mobileHashrate, isTelegramMiniApp, cryptoHashrates, setHashrate]);

  const purchaseInfoBlochShowed = localStorage.getItem(PURCHASE_INFO_BLOCK_SHOWED) === 'hide';
  if (isLoading) {
    return <LoaderMobile />;
  }

  return (
    <div className={s.container}>
      {!purchaseInfoBlochShowed && platformCt !== 'ios' && <InfoContentBlock text={'Add_PM.Tip.Description'} />}
      <div className={s.contentContainer}>
        <Tabs orientation="vertical" tab={currentTab} variant="reset" onChange={setCurrentTab} keepMounted={false}>
          {(hasGoogleMining && isCryptoEnable) || (isTelegramMiniApp && tags?.includes(Tag.XTR_ON)) ? (
            <>
              <div className={s.methodTitle}>
                <Icon iconName="walletColor" width={20} height={20} />
                {t('N.PaymentMethod.Title')}
              </div>
              <List className={s.tabList}>
                {isTelegramMiniApp ? (
                  <Tab value={'telegram'} className={s.tab} dataTestId={'btn_telegram_tab'}>
                    <div className={s.tab__container}>
                      Telegram Stars <TelegramStar width={20} height={20} className={s.tab__right} />
                    </div>
                  </Tab>
                ) : (
                  <>
                    {platformCt === 'ios' && (
                      <Tab value={'ios'} className={s.tab} dataTestId={'btn_ios_tab'}>
                        <div className={s.tab__container}>
                          App Store / {isSubscription ? t('Rent') : t('BUY')}
                          <AppStoreIcon width={20} height={20} />
                        </div>
                      </Tab>
                    )}
                    {platformCt === 'android' && (
                      <Tab value={'google'} className={s.tab} dataTestId={'btn_google_tab'}>
                        <div className={s.tab__container}>
                          Google Play / {t('Rent')}
                          <span className={s.tab__right}>
                            <GooglePlayIcon width={20} height={20} />
                          </span>
                        </div>
                      </Tab>
                    )}
                  </>
                )}

                {isCryptoEnable && ( // Only render Crypto tab if isCryptoEnable is true
                  <Tab value="crypto" className={s.tab} dataTestId={'btn_crypto_tab'}>
                    <div className={s.tab__container}>
                      {isTelegramMiniApp ? (
                        <>
                          Crypto
                          <div className={s.tab__right}>
                            <span className={s.tab__icons}>
                              <CurrencyIcon currency="POL" />
                              <CurrencyIcon currency="BNB" />
                              <CurrencyIcon currency="SOL" />
                              <CurrencyIcon currency="TRX" />
                              <CurrencyIcon currency="USDT" />
                              <CurrencyIcon currency="ETH" />
                              <CurrencyIcon currency="BTC" />
                              <CurrencyIcon currency="TON" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          Crypto / {t('BUY')}
                          <span className={s.tab__icons}>
                            <CurrencyIcon currency="POL" />
                            <CurrencyIcon currency="BNB" />
                            <CurrencyIcon currency="SOL" />
                            <CurrencyIcon currency="TON" />
                            <CurrencyIcon currency="TRX" />
                            <CurrencyIcon currency="USDT" />
                            <CurrencyIcon currency="ETH" />
                            <CurrencyIcon currency="BTC" />
                          </span>
                        </>
                      )}
                    </div>
                  </Tab>
                )}
              </List>
            </>
          ) : null}
          <Panel value="google">
            <div className={s.main}>
              <AddGoogleMiner handleSwitchTab={handleSwitchTab} isCryptoEnable={isCryptoEnable} />
            </div>
            <div ref={containerRef}>
              <div className={cn(s.footer, isSticky && s.fixed)}>
                <PurchaseSubsButton />
                <span className={s.footerDuration}>
                  <Trans i18nKey={t('Purchase_scr.Billing.Description')} values={{ duration: currentPlan?.month }} />{' '}
                  {t('Months_abrv.Subtitle')}
                </span>
              </div>
            </div>
          </Panel>
          <Panel value="ios">
            <div className={s.main}>
              <AddIosMiner handleSwitchTab={handleSwitchTab} isCryptoEnable={isCryptoEnable} />
            </div>
            {isAvailable ? null : (
              <div className={s.footer}>
                <PurchaseSubsButtonIos />
                {isSubscription && (
                  <span className={s.footerDuration}>
                    <Trans i18nKey={t('Purchase_scr.Billing.Description')} values={{ duration: currentPlan?.month }} />{' '}
                    {t('Months_abrv.Subtitle')}
                  </span>
                )}
              </div>
            )}
          </Panel>
          <Panel value="telegram">
            <div className={s.main}>
              <AddMinerTelegram handleSwitchTab={handleSwitchTab} />
            </div>
            <div ref={containerRef}>
              <div className={cn(s.footer, isSticky && s.fixed)}>
                <MakeOrderTelegramButton />
              </div>
            </div>
          </Panel>
          {isCryptoEnable && ( // Only render Crypto panel if isCryptoEnable is true
            <Panel value="crypto">
              <div className={s.main}>
                <AddMiner />
              </div>
              <div ref={containerRef}>
                <div className={cn(s.footer, isSticky && s.fixed)}>
                  <MakeOrderButton />
                </div>
              </div>
            </Panel>
          )}
        </Tabs>
      </div>
      {isCryptoEnable &&
        currentTab === 'crypto' && ( // Only render currency section if isCryptoEnable is true
          <div className={s.currency}>
            <div className={s.currency__triangle}></div>
            <p className={s.currency__text}>{t('HSH_Upd.Add_CB.Сard')}</p>
            <div className={s.currency__icons}>
              <CurrencyIcon currency="CTC" />
              <CurrencyIcon currency="SOL" />
              <CurrencyIcon currency="BNB" />
              <CurrencyIcon currency="TRX" />
              <CurrencyIcon currency="TON" />
              <CurrencyIcon currency="USDT" />
              <CurrencyIcon currency="ETH" />
              <CurrencyIcon currency="BTC" />
            </div>
          </div>
        )}
      {platformCt === 'ios' && (
        <div className={s.restore}>
          <RestoreSubsButton />
        </div>
      )}
    </div>
  );
};
