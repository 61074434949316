import cn from 'classnames';
import { CSSProperties, useState, useEffect } from 'react';

import { Icon } from '@/shared/ui/icons';

import styles from './circle-progress.module.scss';

type CircleProgressProps = {
  variant: 'dot' | 'close';
  width: number;
  activeCurrency?: string;
  duration?: number;
  onDurationEnds?: () => void;
};

const STROKE_WIDTH = 2;

export const CircleProgress = ({
  variant,
  width,
  activeCurrency,
  duration = 1000 * 60,
  onDurationEnds,
}: CircleProgressProps) => {
  const [animationEnd, setAnimationEnd] = useState(false);
  const [key, setKey] = useState(0); // Key to force re-render and restart the animation

  useEffect(() => {
    // Reset animationEnd and increment key to restart animation on activeCurrency change
    setAnimationEnd(false);
    setKey((prevKey) => prevKey + 1);
  }, [activeCurrency]);

  useEffect(() => {
    if (animationEnd) {
      if (onDurationEnds) {
        onDurationEnds();
      }
      setAnimationEnd(false);
      setKey((prevKey) => prevKey + 1); // Also restart the animation when it naturally ends
    }
  }, [animationEnd, onDurationEnds]);

  const cx = width / 2;
  const radius = cx - STROKE_WIDTH / 2;
  const strokeDasharray = 3.14 * radius * 2;

  const style = {
    '--circle-width': `${width}`,
    '--stroke-dasharray': `${strokeDasharray}`,
    '--anim-duration': `${duration}ms`,
  } as CSSProperties;

  return (
    <div key={key} className={cn(styles.loader, animationEnd && styles.animationEnd)} style={style}>
      {variant === 'dot' && <span className={styles.circle} />}
      {variant === 'close' && <Icon iconName="close" width={12} height={12} className={styles.icon} />}
      <svg className={styles.svg}>
        <circle className={cn(styles.inner, styles[variant])} cx={cx} cy={cx} r={radius} />
        <circle
          className={cn(styles.clock, styles.animate, styles[variant])}
          cx={cx}
          cy={cx}
          r={radius}
          strokeLinecap="round"
          onAnimationEnd={() => setAnimationEnd(true)}
        />
      </svg>
    </div>
  );
};
