import cn from 'classnames';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { Balance } from '@/page/home/ui/mining-block/ui/balance/balance';
import { PoolMinersList } from '@/page/home/ui/mining-block/ui/pool-miners-list';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import useMobileDetect from '@/shared/lib/hooks/useMobileDetect';
import { CART_PATH, POOL_MINING, SWITCH_BALANCE_PATH } from '@/shared/lib/utils/links';
import { log } from '@/shared/lib/utils/log';
import numberWithSpaces from '@/shared/lib/utils/numberWithSpaces';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { SwitchBalance } from '@/shared/ui/switch-balance/switch-balance';
import { Tutorial } from '@/shared/ui/tutorial/tutorial';

import { useMiningBlock } from '../mining-block';

import styles from '../mining-block.module.scss';

const MiningComponent = ({
  hps,
  mining,
  checked = false,
  onStartMiner,
  miners,
  className,
  minersClassName,
}: {
  mining: { end_time: number | string }[];
  hps: number;
  checked?: boolean;
  onStartMiner: () => void;
  miners: TMiner[];
  className?: string;
  minersClassName?: string;
  isMining?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [timeStr, setTimeStr] = useState('');
  const [width, setWidth] = useState(0);
  const { userCurrency, mining_currencies } = useBalanceInfo();
  const { isMobile } = useMobileDetect();
  const { isTelegramMiniApp, isIos } = useDevice();
  const numOfMiners = miners.length;
  const miningBlock = useMiningBlock().current;
  const refDiv = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLParagraphElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (mining.length > 0) {
      const interval = setInterval(() => {
        if (ref.current && width < ref.current?.offsetWidth - 10) {
          setWidth(ref.current?.offsetWidth + 4.2);
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setWidth(0);
    }
  }, [mining, width]);

  useEffect(() => {
    const setTimer = (ts: number) => {
      const time = ts - Date.now();
      let hours = 0;
      let minutes = 0;
      if (time > 0) {
        hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        minutes = Math.floor((time / 1000 / 60) % 60);
      }
      setHours(hours);
      setMinutes(minutes);
      if (time > 86400000) {
        setTimeStr('');
      } else {
        const hours_str = hours < 10 ? '0' + hours : hours;
        const minutes_str = minutes < 10 ? '0' + minutes : minutes;
        setTimeStr(` / ${hours_str + t('H')}: ${minutes_str + t('M')}`);
      }
    };
    if (mining.length > 0) {
      const deadline_ts: number = findMinTime(mining) * 1000;
      log('deadline', deadline_ts);
      const interval = setInterval(() => setTimer(deadline_ts), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [t, mining]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        !isMobile() &&
        !isTelegramMiniApp &&
        isActive &&
        refDiv.current &&
        !refDiv.current.contains(e.target as HTMLDivElement)
      ) {
        setIsActive(false);
        document.removeEventListener('mousedown', checkIfClickedOutside);
      }
    };
    if (isActive) {
      document.addEventListener('mousedown', checkIfClickedOutside);
    }

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMobile, isActive, isTelegramMiniApp]);

  const findMinTime = (array: { end_time: number | string }[]): number => {
    let minTime = array?.[0]?.end_time;
    array.map((item) => {
      if (item.end_time < minTime) {
        minTime = item.end_time;
      }
      return item;
    });
    return +minTime;
  };

  const handleChange = useCallback(async () => {
    if (numOfMiners === 0) {
      navigate(CART_PATH);
    } else {
      onStartMiner();
    }
  }, [numOfMiners, navigate, onStartMiner]);

  const hasMining = numOfMiners > 0;
  const miningOverDay = timeStr.length > 0;

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.block}>
        <div className={cn(styles.flex, styles.header)}>
          <div className={cn(styles.selectBlock, { [styles.active]: isActive })} ref={refDiv}>
            <Button
              variant="text"
              borderRadius={false}
              className={cn(styles.title, styles.btnSelect, minersClassName)}
              as={Link}
              to={POOL_MINING}
              data-test-id="link_pool_mining"
            >
              {t('Pool Mining')}
              <span data-test-id="text_count_miners">({numOfMiners})</span>
              <Icon iconName="arrow" width="20" height="20" className={styles.arrow} />
            </Button>
          </div>
        </div>
        <div className={cn(styles.flex, styles.flex__second)}>
          <div className={styles.flexWrap}>
            <Tutorial step="hashrate" arrow="top" position="bottom" viewBlock={miningBlock}>
              <div className={styles.speed}>
                <Icon iconName="miningSpeed" width={40} height={40} />
                <span className={styles.speedTitle}>
                  <p ref={ref} style={{ minWidth: width, fontWeight: '700' }}>
                    {numberWithSpaces(hps)}
                  </p>
                  <p>H/s</p>
                </span>
              </div>
            </Tutorial>
            <div className={cn(styles.flexRight, hasMining && styles.hasActiveMiners)}>
              <div className={styles.lineWrap}>
                <div
                  className={cn(styles.line, {
                    [styles.orange]: checked,
                  })}
                >
                  <div
                    className={cn(styles.progress2, {
                      [styles.active]: checked,
                    })}
                  />
                </div>
                <div
                  className={cn(styles.line, {
                    [styles.blue]: checked,
                  })}
                >
                  <div
                    className={cn(styles.progress, {
                      [styles.active]: checked,
                    })}
                  />
                </div>
              </div>
              <div>
                <label className={styles.switch} data-test-id="checkbox_enable_mining">
                  <input
                    type="checkbox"
                    className={styles.switchInput}
                    onChange={handleChange}
                    checked={checked}
                    disabled={!isIos && mining.length !== 0}
                  />
                  <span className={styles.switchSlider} />
                </label>
              </div>
            </div>
            {hasMining && <PoolMinersList miners={miners} />}
          </div>
          <Balance />
          {mining_currencies?.length > 1 && (
            <Tutorial step="currency" arrow="bot" position="top-start" viewBlock={miningBlock}>
              <div className={styles.container}>
                <SwitchBalance userCurrency={userCurrency} handleClick={() => navigate(SWITCH_BALANCE_PATH)} />
              </div>
            </Tutorial>
          )}
          <Buttons
            checked={checked}
            handleChange={handleChange}
            hasMining={hasMining}
            hours={hours}
            miningOverDay={miningOverDay}
            minutes={minutes}
          />
        </div>
      </div>
    </div>
  );
};

export const Mining = memo(MiningComponent);

type ButtonsProps = {
  hasMining: boolean;
  checked: boolean;
  handleChange: () => Promise<void>;
  miningOverDay: boolean;
  hours: number;
  minutes: number;
};

const Buttons = ({ hasMining, checked, handleChange, miningOverDay, hours, minutes }: ButtonsProps) => {
  const { t } = useTranslation();
  const miningBlockRef = useMiningBlock();

  return (
    <div className={cn(styles.selectBottom, styles.selectBottom_second)}>
      {!hasMining && (
        <>
          <Tutorial step="addPoolButton" arrow="bot" position="top-end" viewBlock={miningBlockRef.current}>
            <Button
              borderRadius={false}
              fullWidth
              color="blue"
              as={Link}
              to={CART_PATH}
              data-test-id="btn_add_pool_miner"
            >
              <Icon iconName="plus" width="20" height="20" />
              {t('ADD POOL MINER')}
            </Button>
          </Tutorial>
          <Button
            borderRadius={false}
            as={Link}
            to={POOL_MINING}
            color="white-blue"
            variant="filled"
            data-test-id="btn_miner_info"
          >
            <Icon iconName="info" width="20" height="20" />
          </Button>
        </>
      )}
      {hasMining && !checked && (
        <>
          <Tutorial step="startButton" arrow="bot" position="top" viewBlock={miningBlockRef.current}>
            <Button
              borderRadius={false}
              fullWidth
              className={styles.buttonDisabled}
              color="blue"
              onClick={handleChange}
              data-test-id="btn_enable_mining"
            >
              <Icon iconName="lightning" width="20" height="20" />
              {t('titleOnButton')}
            </Button>
          </Tutorial>
          <Tutorial step="addPoolButton" arrow="right" position="top-start" viewBlock={miningBlockRef.current}>
            <Button borderRadius={false} color="blue" as={Link} to={CART_PATH} data-test-id="btn_add_pool_miner">
              <Icon iconName="plus" width={20} height={20} />
            </Button>
          </Tutorial>
        </>
      )}
      {hasMining && checked && miningOverDay && (
        <>
          <Tutorial step="startButton" arrow="bot" position="top" viewBlock={miningBlockRef.current}>
            <Button
              borderRadius={false}
              disabled={checked}
              fullWidth
              className={styles.buttonDisabled}
              color="blue"
              onClick={handleChange}
              data-test-id="btn_add_pool_miner"
            >
              <Icon iconName="timer" width={20} height={20} />
              {hours + t('H')} : {minutes < 10 ? '0' + minutes + t('M') : minutes + t('M')}
            </Button>
          </Tutorial>
          <Button
            borderRadius={false}
            as={Link}
            to={POOL_MINING}
            color="white-blue"
            variant="filled"
            data-test-id="btn_miner_info"
          >
            <Icon iconName="info" width="20" height="20" />
          </Button>
          <Tutorial step="addPoolButton" arrow="right" position="top-end" viewBlock={miningBlockRef.current} needFix>
            <Button borderRadius={false} color="blue" as={Link} to={CART_PATH} data-test-id="btn_add_pool_miner">
              <Icon iconName="plus" width="20" height="20" />
            </Button>
          </Tutorial>
        </>
      )}
      {hasMining && checked && !miningOverDay && (
        <>
          <Tutorial step="addPoolButton" arrow="bot" position="top-end" viewBlock={miningBlockRef.current}>
            <Button
              borderRadius={false}
              fullWidth
              color="blue"
              as={Link}
              to={CART_PATH}
              data-test-id="btn_add_pool_miner"
            >
              <Icon iconName="plus" width="20" height="20" />
              {t('ADD POOL MINER')}
            </Button>
          </Tutorial>
          <Button
            borderRadius={false}
            as={Link}
            to={POOL_MINING}
            color="white-blue"
            variant="filled"
            data-test-id="btn_miner_info"
          >
            <Icon iconName="info" width="20" height="20" />
          </Button>
        </>
      )}
    </div>
  );
};
