import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { NC_WALLET } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-info-bonus-hsh.module.scss';

export const PURCHASE_HSH = 'PURCHASE_HSH';
export const ONE_WEEK = 604_800_000;

export const ModalInfoBonusHsh = ({ onClose, isOpen }: ModalCommonProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const location = useLocation();

  const handleDontShow = useCallback(() => {
    const localDate = localStorage.getItem(PURCHASE_HSH) || '{}';
    const lastBalance = JSON.parse(localDate);
    const visit = { ...lastBalance, isShow: !lastBalance.isShow };
    localStorage.setItem(PURCHASE_HSH, JSON.stringify(visit));
  }, []);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="info-hsh-modal">
      <IconContainer>
        <Icon iconName="hsh" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('HSH_Upd.HSH_popup.Title')}</Title>
          <Description>
            <Trans
              i18nKey="HSH_Upd.HSH_popup.Description"
              components={{
                b: <b />,
                a: <a href={NC_WALLET} className={styles.link} target="_blank" rel="noreferrer" />,
              }}
            />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={() => onClose(false)} data-test-id="btn_confirm_modal">
            {t('BTN_OK')}
          </Button>
        </Footer>
        {location.pathname === '/' && (
          <div className={styles.dont_show}>
            <input id="modalHSHCheckbox" type="checkbox" onClick={handleDontShow} />
            <label htmlFor={'modalHSHCheckbox'}>{t('DontShowThisWindowAgain')}</label>
          </div>
        )}
      </Container>
    </ModalLayout>
  );
};
