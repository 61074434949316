import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout from '@/shared/ui/modal/modal';

type FailBlockProps = {
  onClose: (e: boolean) => void;
  next: (e: 'feedback') => void;
};

export const FailBlock = ({ onClose, next }: FailBlockProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <>
      <IconContainer>
        <Icon iconName="attention" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title> {t('Something Went Wrong')}</Title>
          <Description>{t('errors.unknown.description')}</Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            borderRadius={false}
            onClick={() => next('feedback')}
            data-test-id="btn_refresh_modal"
          >
            {t('RETRY')}
          </Button>
          <Button variant="filled" color="grey" fullWidth onClick={() => onClose(false)} data-test-id="btn_close_modal">
            {t('CANCEL')}
          </Button>
        </Footer>
      </Container>
    </>
  );
};
