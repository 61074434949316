import { useContext } from 'react';

import { ModalSetContext } from '@/shared/lib/context/modal/modal-context';

export const useModalSet = () => {
  const modalRenderer = useContext(ModalSetContext);

  if (!modalRenderer) {
    throw new Error('`useCore` has to be used within <ModalSetContext.Provider>');
  }

  return modalRenderer;
};
