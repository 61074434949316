import React from 'react';
import { useSelector } from 'react-redux';

import { BonusBalanceCard } from '@/page/bonus-program/ui/bonus-balance-card';
import { ShareToEarnInfo } from '@/page/bonus-program/ui/share-to-earn-info';
import { RootState } from '@/shared/store/types';

import styles from './crypto-tab-products-page.module.scss';

export const BonusProgramPage = () => {
  const { bonusTransactions, hsh_balance } = useSelector((store: RootState) => store.balance);
  return (
    <div className={styles.container}>
      <ShareToEarnInfo />
      <BonusBalanceCard withdrawal items={bonusTransactions} balance={hsh_balance?.total} />
    </div>
  );
};
