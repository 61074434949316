import { getDecimalPlaces } from '@/shared/lib/utils/units';

export const getValidationRules = (value: string, unit: string) => {
  const isDecimalNumber = value.match(/^([0-9]+)?(\.)?([0-9]+)?$/);
  const isFirstZerosRow = /^0[0-9]/.test(value);
  const isFirstDotted = /^\./.test(value);

  const unitAfterDot = getDecimalPlaces(unit);
  const valueAfterDot = getDecimalPlaces(value);
  const isFormattedUnit = unitAfterDot >= valueAfterDot && (unitAfterDot > 0 || !value?.includes('.'));

  const isValid = isDecimalNumber && !isFirstZerosRow && !isFirstDotted && isFormattedUnit;

  return { isValid, isDecimalNumber, isFirstZerosRow, isFirstDotted, isFormattedUnit };
};
