import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { MetricsService } from '../services';

export const usePageTracking = (): void => {
  const startTimeRef = useRef<number | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    startTimeRef.current = Date.now();
    MetricsService.sendEvent('page_open', {
      domain: 'navigation',
      url: pathname,
    });

    return () => {
      if (startTimeRef.current !== null) {
        const timeSpent = Date.now() - startTimeRef.current;
        MetricsService.sendEvent('page_leave', {
          domain: 'navigation',
          time_spend: timeSpent,
          url: pathname,
        });
      }
    };
  }, [pathname]);
};
