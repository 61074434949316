import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon as IconName } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

export const LimitAlertModal = ({ isOpen, onClose }: ModalCommonProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="limit-alert">
      <IconContainer>
        <IconName iconName="alert" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Token_Limit_Exceeded')}</Title>
          <Description>
            <Trans i18nKey="Please_note_that_you_can_only_use_100_tokens" components={{ b: <strong /> }} />
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={() => onClose(false)}>
            {t('BTN_OK')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
