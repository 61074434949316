import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORAGE_BACKGROUND, STORAGE_RANDOM_BACKGROUND, STORAGE_THEME } from '@/shared/lib/constants/storage-key';
import { MetricsService } from '@/shared/lib/metrics';
import { RootState } from '@/shared/store/types';

import { SelectableColors, selectableColorsSolid, Theme } from './types';

export type ThemeSliceState = {
  currentTheme: Theme;
  currentBg: SelectableColors | undefined;
  isTelegramMiniAppState?: boolean;
};

const theme = localStorage.getItem(STORAGE_THEME) as Theme;

export const getUserThemeStatic = () => {
  return (
    theme || (window.matchMedia(`(prefers-color-scheme: ${AppTheme.DARK})`).matches ? AppTheme.DARK : AppTheme.LIGHT)
  );
};

const userBg = localStorage.getItem(STORAGE_BACKGROUND) as SelectableColors;
const userRandomBg = localStorage.getItem(STORAGE_RANDOM_BACKGROUND);

function getRandomColor() {
  const randomNumber = Math.floor(Math.random() * selectableColorsSolid.length);
  return selectableColorsSolid[randomNumber];
}

function getBg() {
  if (userRandomBg === 'true') {
    return getRandomColor();
  }
  if (userBg) {
    return userBg;
  }

  return undefined;
}

export enum AppTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

const initialState: ThemeSliceState = {
  currentTheme: getUserThemeStatic(),
  currentBg: getBg(),
  isTelegramMiniAppState: undefined,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<Theme>) => {
      MetricsService.sendEvent('preference', { theme: action.payload });
      state.currentTheme = action.payload;
    },
    changeBackground: (state, action: PayloadAction<SelectableColors | undefined>) => {
      localStorage.setItem(STORAGE_BACKGROUND, action.payload || '');
      MetricsService.sendEvent('preference', { bg_color: action.payload });
      state.currentBg = action.payload;
    },
    changeBgToRandom: (state) => {
      MetricsService.sendEvent('preference', { bg_color: 'random' });
      state.currentBg = getRandomColor();
    },
    changeIsTelegramMiniApp: (state, action: PayloadAction<boolean | undefined>) => {
      state.isTelegramMiniAppState = action.payload;
    },
  },
});

export const selectCurrentTheme = (state: RootState) => state.theme.currentTheme;
export const selectCurrentBg = (state: RootState) => state.theme.currentBg;
export const selectIsMobile = (state: RootState) => state.theme.isTelegramMiniAppState;

export const { changeTheme, changeBackground, changeBgToRandom, changeIsTelegramMiniApp } = themeSlice.actions;
