import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { getTransactionBorderColor, getTransactionChipColor, mapStatus } from '@/features/balance';
import { WithdrawalActions } from '@/page/withdrawal-details/ui/actions/actions';
import { WithdrawalNote } from '@/page/withdrawal-details/ui/note/note';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { formatDate } from '@/shared/lib/utils/getFormatDate';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { formatBalance } from '@/shared/lib/utils/units';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { cancelWithdrawal, selectWithdrawal } from '@/shared/store/withdrawal/withdrawal.slice';
import NCWalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg?react';
import { Icon } from '@/shared/ui/icons';
import { LoaderMobile } from '@/shared/ui/loader/loader';

import styles from './withdrawal-details.page.module.scss';

export const WithdrawalDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const withdrawal = useSelector(selectWithdrawal);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const transaction = withdrawal?.withdrawalsToShow.concat(withdrawal?.withdrawals).find((w) => w?.id === id);
  const { loading } = useSelector(selectUser);
  const { openModal } = useModalSet();
  const dispatch: AppDispatch = useDispatch();
  const currency = transaction?.currency;

  const formattedBalance = useMemo(() => {
    if (transaction) {
      const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === currency);

      if (currency === 'HSH') {
        return getLocalAmount(transaction?.amount);
      }

      const res = currentCurrencyObj ? formatBalance(transaction?.amount, currentCurrencyObj?.unit) : '0.00';
      return getLocalAmount(res);
    }
    return '0';
  }, [transaction, currency, currencies]);

  if (loading) {
    return <LoaderMobile />;
  }

  if (!transaction) {
    return <Navigate to={WITHDRAW_PATH} />;
  }

  const addressInfo = getWithdrawalAddressInfo(transaction?.meta);
  const [statusLabel] = mapStatus(transaction?.status, t);
  const labelColor = getTransactionBorderColor(transaction?.status);
  const chipColor = getTransactionChipColor(transaction?.status);

  const handleCancelWithdrawal = () => {
    dispatch(cancelWithdrawal({ id: transaction?.id, openModal }));
  };

  const date = new Date(transaction?.created_at);
  const timestampInSeconds = Math.floor(date.getTime() / 1000);
  return (
    <div>
      <div className={styles.block}>
        <div className={styles.block__top}>
          <div className={cn(styles.label, styles[labelColor!])}>{statusLabel}</div>
          <div className={cn(styles.info, styles[chipColor!])}>
            <p className={styles.info__text}>
              <strong>{t('Amount')}: </strong> {`${formattedBalance} ${transaction?.currency}`}
            </p>
            <p className={styles.info__text}>
              <strong>ID:</strong>{' '}
              {addressInfo?.isNcw ? addressInfo?.email || addressInfo?.account_id : addressInfo?.address}{' '}
              {addressInfo?.isNcw ? (
                <NCWalletIcon width={20} height={20} className={styles.info__icon} />
              ) : (
                <Icon iconName={'wallet'} width={20} height={20} className={styles.info__icon} />
              )}
            </p>
            <p className={styles.info__text}>
              <strong>{t('Requested at:')}</strong> {formatDate(timestampInSeconds)}
            </p>
            <p className={styles.info__text}>
              <strong>{t('Status')}:</strong> {statusLabel}
            </p>
          </div>

          <div className={styles.note}>
            <WithdrawalNote status={transaction?.status} />
          </div>
        </div>
        <div className={styles.block__footer}>
          <WithdrawalActions
            status={transaction?.status}
            onCancel={() =>
              openModal('MODAL_CANCEL_WITHDRAWAL', {
                amount: transaction?.amount,
                currency: transaction?.currency,
                onCancel: handleCancelWithdrawal,
                isServices: true,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
