export const CURRENT_PROMO_BANNER_ID = 'CampfirePromoBannerClosedAt';
export const CURRENT_PROMO_END_DATE = '2025-04-15T00:00:00.000Z';

export const getIsCurrentPromo = (): boolean => {
  const currentPromoClosedAt = localStorage.getItem(CURRENT_PROMO_BANNER_ID);

  if (currentPromoClosedAt) return false;

  const currentDate = new Date();
  const currentPromoDate = new Date(CURRENT_PROMO_END_DATE);
  const isCurrentPromo = currentDate < currentPromoDate;

  if (!isCurrentPromo) {
    console.warn('Current promo is over, please remove the Current promo banner', 'color: red; font-size: 20px;');
  }

  return isCurrentPromo;
};
