import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

export const ModalWhatIsMiningSpeed = ({ onClose, isOpen }: ModalCommonProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="go-to-payment-modal">
      <IconContainer>
        <Icon iconName="boosts-info" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('What is mining speed?')}</Title>
          <Description>
            {t(
              'Mining speed is measured in hashrate (H/s)  — the number of calculations your miner performs per second.'
            )}
          </Description>
          <Description>
            {t('Higher hashrate makes your miner complete tasks faster and increase your chances to earn rewards.')}
          </Description>
        </Content>
        <Footer>
          <Button color="grey" fullWidth onClick={() => onClose(false)}>
            {t('Close')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
