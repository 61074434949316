import cn from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCurrentPlan } from '@/page/cart/store';
import { Tag } from '@/shared/lib/constants/tags';
import { Alert } from '@/shared/ui/alert';
import { Icon } from '@/shared/ui/icons';

import s from './special-offer.module.scss';

const variantsBonus = {
  hps: {
    local: 'bonusHashrateOfferBannerLastClosed',
    title: 'Seasonal_Offer.Hashrate',
    iconName: 'orange-gift',
    iconSize: 40,
  },
  hsh: {
    local: 'bonusHshOfferBannerLastClosed',
    title: 'Seasonal_Offer.HSH',
    iconName: 'hsh',
    iconSize: 30,
  },
};

export enum variantBonus {
  hps = 'hps',
  hsh = 'hsh',
}

type TChristmasProps = {
  main?: boolean;
  onClick?: () => void;
  variant?: variantBonus;
};

export const BonusOfferBanner = ({ main = false, onClick, variant = variantBonus.hps }: TChristmasProps) => {
  const currentPlan = useSelector(useCurrentPlan);
  const [close, setClose] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const lastClosed = localStorage.getItem(variantsBonus[variant].local);
    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      const now = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // 1 day
      if (now.getTime() - lastClosedDate.getTime() < oneDay) {
        setClose(true);
      }
    }
  }, []);

  const handleClose = () => {
    setClose(true);
    localStorage.setItem(variantsBonus[variant].local, new Date().toString());
  };

  if (close) {
    return null;
  }

  if (currentPlan && !currentPlan?.tags?.includes(Tag.BONUS_TAG)) return null;

  return (
    <Alert
      variant="warning"
      className={cn(s.personalDiscountAlert, s.christmasAlert, main && s.christmasAlert_second)}
      dashed
    >
      {main && <Icon iconName="close-notification" width={16} height={16} className={s.close} onClick={handleClose} />}
      <div className={cn(s.personalDiscountContainer)} onClick={onClick}>
        <div className={s.titleContainer}>
          <Icon
            iconName={variantsBonus[variant].iconName}
            width={variantsBonus[variant].iconSize}
            height={variantsBonus[variant].iconSize}
          />
          <div className={s.christmasTitle}>
            <strong>{t('Seasonal_Offer.Title')}</strong> {t(variantsBonus[variant].title)}
          </div>
        </div>
      </div>
    </Alert>
  );
};
