import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { toast } from 'react-toastify';

import {
  NcwWithdrawalMeta,
  WalletsWithdrawalsChangeRequest,
  WalletsWithdrawalsCreateRequest,
  WalletsWithdrawalsLastAddressesResponse,
  WalletsWithdrawalsLastAddressesResponseUnion,
  Withdrawal,
  WithdrawalCreateType,
  WithdrawalStatus,
} from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { CONFIRMED_EMAIL } from '@/shared/lib/constants/storage-key';
import { Tag } from '@/shared/lib/constants/tags';
import { ModalName } from '@/shared/lib/context/modal/modal-render';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { HISTORY_PATH } from '@/shared/lib/utils/links';
import { RootState } from '@/shared/store/types';
import { toaster } from '@/shared/ui/custom-toast/toaster';

type TCreateWithdrawal = WalletsWithdrawalsCreateRequest & {
  openModal: TOpenModal;
  navigate: NavigateFunction;
};

type TConfirmEmail = {
  email: string;
  t: TFunction<'translation', undefined>;
  toastId: string | number;
  newToastId: (id: string | number) => void;
  countdown?: number | null;
  newCountdown: (n: number) => void;
  // eslint-disable-next-line
  openModal?: (name: ModalName, options?: any) => void;
};

type TOpenModal = (
  name: ModalName,
  // eslint-disable-next-line
  options?: any
) => void;

// // Type for the confirmed email object stored in localStorage
// interface ConfirmedEmail {
//   email: string;
//   userId: string;
// }

export const cancelWithdrawal = createAsyncThunk(
  'withdrawal/cancelWithdrawal',
  async function ({ id, openModal }: { id: string; openModal: TOpenModal }, { dispatch, getState }) {
    try {
      const state = getState() as RootState;
      const withdrawals = state.withdrawal.withdrawals;
      const transaction = withdrawals.find((w) => w.id === id);
      const result = await rpc.transmit('wallets.withdrawals.cancel', {
        withdrawal_id: id,
      });
      openModal('MODAL_SUCCESS_CANCEL_WITHDRAWAL', {
        amount: transaction?.amount,
        currency: transaction?.currency,
        isServices: true,
      });
      dispatch(withdrawalAction.updateWithdrawal(result));
    } catch (e) {
      if (e instanceof Error) {
        openModal('MODAL_ERROR', { isServices: true, error: e });
        console.log(e.message);
      }
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'withdrawal/verifyEmail',
  async function (props: TConfirmEmail, { dispatch }) {
    const { email, t, newToastId, toastId, countdown, newCountdown, openModal } = props;
    try {
      await rpc.transmit('users.me.set_email', { email: email });
      dispatch(withdrawalAction.setVerifyEmail());
      openModal && openModal('MODAL_EMAIL_CHECK', { isServices: true });
      newCountdown(60);
      // eslint-disable-next-line
    } catch (e: any) {
      toast.dismiss(toastId);
      setTimeout(
        () =>
          newToastId(
            toaster.warning(t('Processing.Toast'), {
              toastProps: { autoClose: countdown ? countdown * 1000 : 60000 },
            })
          ),
        toastId !== '' ? 1000 : 0
      );
    }
  }
);

export const createWithdrawal = createAsyncThunk<
  void,
  TCreateWithdrawal,
  {
    state: RootState;
  }
>('withdrawal/create', async function (props: TCreateWithdrawal, { dispatch, getState }) {
  const { address, amount, type, openModal, navigate } = props;
  const state = getState();
  const tags = state?.user?.tags;

  try {
    const result = await rpc.transmit('wallets.withdrawals.create', {
      address,
      amount,
      type,
    });

    await rpc.transmit('wallets.withdrawals.last_addresses', {}).then((r) => {
      dispatch(withdrawalAction.setAddressesList(r));
    });

    await rpc
      .transmit('wallets.withdrawals.history', {
        status: [WithdrawalStatus.Created],
      })
      .then((r) => {
        dispatch(withdrawalAction.setWithdrawalsCreated(r));
      });

    dispatch(withdrawalAction.updateWithdrawal(result));

    if (type === WithdrawalCreateType.Regular) {
      const localAddress = localStorage.getItem(CONFIRMED_EMAIL);

      if (localAddress?.includes('address')) {
        localStorage.removeItem(CONFIRMED_EMAIL);
        dispatch(withdrawalAction.deleteBtcAddresses());
      }
    }
    if (result.status === WithdrawalStatus.Created) {
      openModal('MODAL_CONFIRM_WITHDRAWAL', { isServices: true });
    } else {
      navigate(HISTORY_PATH);
    }

    // eslint-disable-next-line
  } catch (e: any) {
    if (
      e.code === 7004 ||
      e.message === 'Insufficient funds' ||
      e.code === 7002 ||
      e.message === 'Low withdrawal amount'
    ) {
      openModal('MODAL_ERROR_FUNDS_WITHDRAWAL', {
        isMining: type === WithdrawalCreateType.Regular,
        isServices: true,
      });
      return;
    }

    // handle Wallet not found or Invalid address errors
    if (e.code === 16000 || e.code === 1006) {
      openModal('MODAL_ERROR_WITHDRAWAL_ADDRESS', { isServices: true });
      return;
    }

    // Use tags from the state to handle specific error case
    if (e.message === 'Withdraw suspended' && !tags?.includes(Tag.WITHDRAW_ACCESS)) {
      openModal('MODAL_WITHDRAW_SUSPENDED', { isServices: true });
      return;
    }

    openModal('MODAL_ERROR', { isServices: true, error: e });
  }
});

const LIMIT = 6;
const OFFSET = 6;

export const getWithdrawals = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('withdrawal/get', async function (_, { getState, dispatch }) {
  try {
    const state = getState();
    const offset = state.withdrawal.offset;
    const total = state.withdrawal.total;
    const withdrawalsToShow = state.withdrawal.withdrawalsToShow;
    const res = await rpc.transmit('wallets.withdrawals.history', { limit: LIMIT, offset: offset });

    const ids = withdrawalsToShow.map((withdrawal) => withdrawal.id);

    const items = res?.items?.reduce(
      (acc: Withdrawal[], item) =>
        ids.includes(item.id) ? acc : [...acc, { ...item, request_date: item?.request_date }],
      []
    );

    const nextOffset = offset + OFFSET;

    if (total < res.total) {
      dispatch(withdrawalAction.setTotal(res.total));
    }

    dispatch(
      withdrawalAction.setWithdrawalsNext({
        withdrawalsToShow: [...withdrawalsToShow, ...items],
        offset: nextOffset,
      })
    );
    // eslint-disable-next-line
  } catch (e: any) {
    console.log(e.message);
  }
});

type TStatus = 'error' | 'success' | 'loading' | '';

export type rootWithdrawal = {
  withdrawalsAddresses: WalletsWithdrawalsLastAddressesResponseUnion[];
  isLoading: boolean;
  isFirstLoading: boolean;
  isNextWithdrawalsLoading: boolean;
  forceRequest: boolean;
  hasRequested: boolean;

  createWithdrawalStatus: TStatus;

  sendEmail: boolean;
  sendEmailStatus: TStatus;

  offset: number;
  limit: number;
  total: number;

  withdrawals: Withdrawal[];
  withdrawalsCreated: Withdrawal[];
  withdrawalsConfirmed: Withdrawal[];
  withdrawalsToShow: Withdrawal[];
};

const initialState: rootWithdrawal = {
  isLoading: false,
  isFirstLoading: true,
  isNextWithdrawalsLoading: false,
  forceRequest: false,
  hasRequested: false,

  withdrawalsAddresses: [] as WalletsWithdrawalsLastAddressesResponseUnion[],
  createWithdrawalStatus: '',

  sendEmail: false,
  sendEmailStatus: '',

  offset: 0,
  limit: 0,
  total: 0,

  withdrawals: [],
  withdrawalsCreated: [],
  withdrawalsConfirmed: [],
  withdrawalsToShow: [],
};

const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    setAddressesList(state, action: PayloadAction<WalletsWithdrawalsLastAddressesResponse>) {
      const confirmedEmailData = localStorage.getItem(CONFIRMED_EMAIL);

      if (!confirmedEmailData) {
        state.withdrawalsAddresses = action.payload;
        return;
      }

      try {
        const confirmedEmail: NcwWithdrawalMeta = JSON.parse(confirmedEmailData);

        const hasConfirmedEmail = action.payload.some((address) => {
          const addressInfo = getWithdrawalAddressInfo(address);
          return (
            addressInfo.isNcw &&
            (addressInfo.email === confirmedEmail.email || addressInfo.account_id === confirmedEmail.account_id)
          );
        });

        if (hasConfirmedEmail) {
          state.withdrawalsAddresses = action.payload;
          localStorage.removeItem(CONFIRMED_EMAIL);
        } else {
          state.withdrawalsAddresses = [confirmedEmail, ...action.payload];
        }
      } catch (error) {
        console.error('Error parsing confirmed email from localStorage:', error);
      }
    },
    deleteBtcAddresses(state) {
      state.withdrawalsAddresses = state.withdrawalsAddresses.filter((address) => {
        return !('address' in address);
      });
    },
    addNewAddress(state, action: PayloadAction<WalletsWithdrawalsLastAddressesResponseUnion>) {
      const newAddressInfo = getWithdrawalAddressInfo(action.payload);
      const index = state.withdrawalsAddresses.findIndex((item) => {
        const itemInfo = getWithdrawalAddressInfo(item);
        if (newAddressInfo.isNcw && itemInfo.isNcw) {
          return itemInfo.email === newAddressInfo.email;
        }

        return !newAddressInfo.isNcw && !itemInfo.isNcw && itemInfo.address === newAddressInfo.address;
      });
      if (index !== -1) return;

      state.withdrawalsAddresses = [action.payload, ...state.withdrawalsAddresses];
    },
    setVerifyEmail(state) {
      state.sendEmail = true;
    },
    setWithdrawals(state, action) {
      state.withdrawals = action.payload.items?.map((item: Withdrawal) => ({
        ...item,
        request_date: item.request_date,
      }));
      state.isLoading = false;
      state.forceRequest = false;
      state.hasRequested = true;
      state.isFirstLoading = false;
      state.isNextWithdrawalsLoading = false;
      state.limit = action.payload.limit;
      state.total = action.payload.total;
    },
    setWithdrawalsCreated(state, action) {
      state.withdrawalsCreated = action.payload.items;
    },
    setWithdrawalsConfirmed(state, action) {
      state.withdrawalsConfirmed = action.payload.items;
    },
    updateWithdrawalFromEvent(state, action: PayloadAction<WalletsWithdrawalsChangeRequest>) {
      const updateWithdrawalInList = <T extends Withdrawal | WalletsWithdrawalsLastAddressesResponseUnion>(
        list: T[]
      ) => {
        // eslint-disable-next-line
        const index = list.findIndex((item: any) => item.id === action.payload.id);
        if (index !== -1) {
          // Update the fields in the found withdrawal
          const updatedItem = {
            // eslint-disable-next-line
            ...(list[index] as any), // Cast to any to allow mutation
            ...action.payload,
            amount: action.payload.amount.toString(),
          };
          list[index] = updatedItem as T; // Cast back to the original type
        }
      };

      // Update in both lists if the withdrawal is found
      updateWithdrawalInList(state.withdrawalsCreated);
      updateWithdrawalInList(state.withdrawalsToShow);
      updateWithdrawalInList(state.withdrawals);
      // eslint-disable-next-line
      updateWithdrawalInList(state.withdrawalsAddresses as any);
    },
    setWithdrawalsNext(state, action) {
      state.isNextWithdrawalsLoading = true;
      state.withdrawalsToShow = action.payload.withdrawalsToShow;
      state.offset = action.payload.offset;
    },
    updateWithdrawal(state, action) {
      let withdrawalsToShow = [...current(state.withdrawalsToShow)];
      let withdrawals = [...current(state.withdrawals)];
      const index = withdrawals.concat(withdrawalsToShow).findIndex((w) => w.id === action.payload.id);

      if (index !== -1) {
        withdrawalsToShow[index] = action.payload;
        withdrawals[index] = action.payload;
      } else {
        withdrawalsToShow = [action.payload, ...withdrawalsToShow];
        withdrawals = [action.payload, ...withdrawals];
      }
      state.withdrawals = withdrawals;
      state.withdrawalsToShow = withdrawalsToShow;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    clearWithdrawalsToShow(state) {
      state.withdrawalsToShow = [];
      state.offset = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWithdrawal.pending, (state) => {
        state.createWithdrawalStatus = 'loading';
      })
      .addCase(createWithdrawal.fulfilled, (state) => {
        state.createWithdrawalStatus = 'success';
      })
      .addCase(createWithdrawal.rejected, (state) => {
        state.createWithdrawalStatus = 'error';
      })
      .addCase(confirmEmail.pending, (state) => {
        state.sendEmailStatus = 'loading';
      })
      .addCase(confirmEmail.fulfilled, (state) => {
        state.sendEmailStatus = 'success';
      })
      .addCase(confirmEmail.rejected, (state) => {
        state.sendEmailStatus = 'error';
      })
      .addCase(getWithdrawals.pending, (state) => {
        state.isNextWithdrawalsLoading = true;
      })
      .addCase(getWithdrawals.fulfilled, (state) => {
        state.isFirstLoading = false;
        state.isNextWithdrawalsLoading = false;
      })
      .addCase(getWithdrawals.rejected, (state) => {
        state.isNextWithdrawalsLoading = false;
      });
  },
});

export const withdrawalAction = withdrawalSlice.actions;

export const selectWithdrawal = (state: RootState) => state.withdrawal;
export const selectWithdrawals = (state: RootState) => state.withdrawal.withdrawals;

export const withdrawalReducer = withdrawalSlice.reducer;
