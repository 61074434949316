import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { useCurrentUtmSource } from '@/features/crypto-tab-products/hooks/use-current-utm-source';
import { updateUtmSource, UtmPlace } from '@/features/crypto-tab-products/utils/update-utm-source';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { SliderCurrency } from '@/page/tutorial/ui/slider-currency/slider-currency';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { HOME_PATH, PLAY_GOOGLE_NC_WALLET, NC_WALLET, PLAY_APPLE_NC_WALLET } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import { Bullet } from './bullet';
import { SliderItem } from '../slider-item/slider-item';
import StepOneImage from './images/imagePromo1.png';
import StepTwoImage from './images/imagePromo2.png';
import StepThreeImage from './images/imagePromo3.png';
import StepFourImage from './images/imagePromo4.png';

import styles from './slider.module.scss';

export function Slider() {
  const [showingSlide, setShowingSlide] = useState(1);
  const navigate = useNavigate();
  const { formattedTotalBalance } = useBalanceInfo();
  const { t } = useTranslation();
  const platform = window.ct?.platform || 'android';
  const ref = useRef<SwiperRef>(null);
  const { markTutorialSeen, markCurrencySeen, isCurrencySeen } = useTutorialStorage();
  const isCurrencyDisabled = isCurrencySeen();
  const [isBalanceNull, setBalanceNull] = useState<boolean>(true);
  const showCurrency = !isCurrencyDisabled && isBalanceNull;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentUtmSource = useCurrentUtmSource();

  useEffect(() => {
    setBalanceNull(!(Number(formattedTotalBalance) > 0));
  }, [formattedTotalBalance, setBalanceNull, isBalanceNull]);

  useEffect(() => {
    if (ref.current && ref.current.swiper) {
      isModalOpen ? ref.current.swiper.autoplay.pause() : ref.current.swiper.autoplay.resume();
    }
  }, [isModalOpen]);

  const disableSlider = (disable: boolean) => {
    if (ref.current && ref.current.swiper) {
      ref.current.swiper.allowSlideNext = !disable;
      ref.current.swiper.allowSlidePrev = !disable;
    }
  };

  const initialSlider = [
    {
      id: '1',
      title: 'Tutorial.St_1.Title',
      description: 'Tutorial.St_1.Description',
      src: StepOneImage,
      platform: ['android'],
    },
    {
      id: '2',
      title: platform === 'ios' ? 'Tutorial.St_1.Title' : 'Tutorial.Step_2.Title',
      description: 'Tutorial.St_2.Description',
      src: StepTwoImage,
      platform: ['android', 'ios'],
    },
    {
      id: '3',
      title: 'Tutorial.St_3.Title',
      description: 'Tutorial.St_3.Description',
      src: StepThreeImage,
      body: showCurrency ? <SliderCurrency isOpen={isModalOpen} setIsOpen={setIsModalOpen} /> : undefined,
      platform: ['android', 'ios'],
    },
    {
      id: '4',
      title: 'Tutorial.St_4.Title',
      description: 'Tutorial.St_4.Description',
      src: StepFourImage,
      link: NC_WALLET,
      platform: ['android', 'ios'],
    },
  ];

  const filteredSlides = initialSlider.filter((slide) => slide.platform.includes(platform));

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleSlideChange = (swiper: any) => {
    const newIndex = swiper.activeIndex;
    setCurrentSlideIndex(newIndex);
    // // Adjust autoplay settings based on the current slide index
    // swiper.params.autoplay.disableOnInteraction = newIndex === 2;

    // swiper.autoplay.start(); // Restart autoplay to apply the new settings
    setShowingSlide(newIndex + 1);
    window.scrollBy(0, -window.innerHeight);
  };

  const handleClose = () => {
    markTutorialSeen();
    markCurrencySeen();
    navigate(HOME_PATH);
  };

  return (
    <div className={styles.root}>
      <Swiper
        ref={ref}
        className={styles.swiper}
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Pagination, Navigation]}
        watchSlidesProgress={true}
        slideToClickedSlide={true}
        onInit={() => {
          setShowingSlide(0);
        }}
        wrapperClass={`${clsx(['swiper-wrapper', styles.swiper__wrapper])}`}
        onSlideChange={handleSlideChange}
        navigation={{
          prevEl: `.${styles.prev}`,
          nextEl: `.${styles.next}`,
        }}
        autoplay={{
          delay: 7000,
          stopOnLastSlide: true,
          disableOnInteraction: false,
        }}
        onSlideChangeTransitionStart={() => disableSlider(true)}
        onSlideChangeTransitionEnd={() => disableSlider(false)}
      >
        <div className={styles.swiper__pagination_wrapper}>
          <div className={styles.swiper__pagination}>
            {new Array(filteredSlides.length).fill(0).map((_, index) => (
              <Bullet
                key={index}
                isModalOpen={isModalOpen}
                currentSlideIndex={currentSlideIndex}
                index={index}
                bulletsRef={ref}
              ></Bullet>
            ))}
          </div>
        </div>
        <button onClick={handleClose} className={styles.close} data-test-id="btn_close_slider">
          <Icon iconName="close" width={18} height={18} />
        </button>

        {filteredSlides.map((slide, index) => (
          <SwiperSlide
            key={slide.id + 'sw'}
            className={clsx([
              styles.swiper__slide,
              ref.current?.swiper.activeIndex === index && styles.swiper__slide_active,
            ])}
          >
            <SliderItem
              id={slide.id}
              key={slide.id}
              className={styles.swiper__slide_item}
              title={t(slide.title)}
              description={slide.description}
              src={slide.src}
              link={slide.link}
              body={slide.body}
            />
            <div className={styles.prev}></div>
            <div className={styles.next}></div>
          </SwiperSlide>
        ))}
      </Swiper>
      {showingSlide === filteredSlides.length && (
        <div className={styles.button__wrapper}>
          {platform == 'ios' ? (
            <Button
              className={styles.get}
              color="black"
              as="a"
              href={updateUtmSource({ link: PLAY_APPLE_NC_WALLET, utmSource: currentUtmSource })}
              target="_blank"
            >
              <Icon className={styles.icon} iconName="apple-ios" width={20} height={20} />
              {t('GET_NC_WALLET')}
            </Button>
          ) : (
            <Button
              className={styles.get}
              color="black"
              as="a"
              href={updateUtmSource({
                link: PLAY_GOOGLE_NC_WALLET,
                utmSource: currentUtmSource,
                utmPlace: UtmPlace.Link,
              })}
              target="_blank"
            >
              <Icon className={styles.icon} iconName="mobile" width={20} height={20} />
              {t('GET_NC_WALLET')}
            </Button>
          )}
          <Button className={styles.start} onClick={handleClose} data-test-id="btn_bottom_close_slider">
            {t('Tutorial.Btn')}
            <Icon className={styles.icon} iconName="arrow" width={20} height={20} />
          </Button>
        </div>
      )}
    </div>
  );
}
