import { Text } from '@wcode/ui-kit';
import cx from 'classnames';

import { GooglePlayIcon } from '@/page/cart/assets';
import { CurrencyIcon } from '@/shared/ui/currency-icon';

import s from './currency-switcher.module.scss';

export type CurrencySwitcherType = 'google-play' | 'crypto';

interface CurrencySwitcherProps {
  onChange?: (type: CurrencySwitcherType) => void;
  activeTab: CurrencySwitcherType;
}

export const CurrencySwitcher = ({ onChange, activeTab }: CurrencySwitcherProps) => {
  const handleTypeChange = (type: CurrencySwitcherType) => {
    onChange?.(type);
  };

  return (
    <div className={s.container}>
      <button
        className={cx(s.button, { [s.active]: activeTab === 'google-play' })}
        onClick={() => handleTypeChange('google-play')}
      >
        <GooglePlayIcon width={20} height={20} />
        <Text type={'label'} size={'s'} text={'Google Play'} className={s.button__text} />
      </button>
      <button
        className={cx(s.button, { [s.active]: activeTab === 'crypto' })}
        onClick={() => handleTypeChange('crypto')}
      >
        <span className={s.button__icons}>
          <CurrencyIcon currency="TON" />
          <CurrencyIcon currency="TRX" />
          <CurrencyIcon currency="USDT" />
          <CurrencyIcon currency="BTC" />
        </span>
        <Text type={'label'} size={'s'} text={'Crypto'} className={s.button__text} />
      </button>
    </div>
  );
};
