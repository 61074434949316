import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout from '@/shared/ui/modal/modal';

type SuccessBlockProps = {
  onClose: (e: boolean) => void;
};

export const SuccessBlock = ({ onClose }: SuccessBlockProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();

  return (
    <>
      <IconContainer>
        <Icon iconName="confirmed" width={80} height={80} style={{ color: '#37CC33' }} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('MTU.Modal.Feedback_Success.Title')}</Title>
          <Description>{t('MTU.Modal.Feedback_Success.Description')}</Description>
        </Content>
        <Footer>
          <Button
            color="blue"
            fullWidth
            borderRadius={false}
            onClick={() => onClose(false)}
            data-test-id="btn_confirm_modal"
          >
            {t('DONE')}
          </Button>
        </Footer>
      </Container>
    </>
  );
};
