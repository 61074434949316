import { useSelector } from 'react-redux';

import { TNftMinerState } from '@/shared/store/ntf-miner/nft-miner.slice';

import btcGif from './img/BTC(2x).gif';

import styles from './pixel-btc.module.scss';

const COUNTER = 42;

export function PixelBtc() {
  const style = {
    gridTemplateColumns: `repeat(${COUNTER}, 60px)`,
  };
  const nftMining = useSelector((store: { nft_miner: TNftMinerState }) => store.nft_miner.mining);
  const nftTokens = useSelector((store: { nft_miner: TNftMinerState }) => store.nft_miner.miners);

  const eggsEthereum = nftTokens.length
    ? nftTokens?.[0]?.tokens?.filter((t) => t.type === 'egg')?.some((t) => t.network === 'mainnet')
    : false;

  if (!(nftMining.length > 0) || !eggsEthereum) {
    return null;
  }

  return (
    <div className={styles.pixel_btc} style={style}>
      {Array.from({ length: COUNTER }, (_, i) => (
        <div className={styles.pixel_btc__item} key={i}>
          <img src={btcGif} alt="" width={45} height={45} />
        </div>
      ))}
    </div>
  );
}
