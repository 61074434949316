import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { formatHashrate } from '@/page/cart/ui/hashrate';
import { PoolMinerMessage } from '@/shared/lib/backend/JsonRpcApi';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-pool-miner-active.module.scss';

const MONTH = 2592000;

interface ModalPoolMinerActiveProps extends ModalCommonProps {
  miner: PoolMinerMessage;
}

export const ModalPoolMinerActive = ({ onClose, isOpen, miner }: ModalPoolMinerActiveProps) => {
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const month = useMemo(() => Math.round((miner?.end_time - miner?.create_time) / MONTH), [miner]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="pool-miner-active-modal">
      <IconContainer>
        <Icon iconName="hashrate" width="80" height="81" />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Miner_activation.Title')}</Title>
          <Description data-test-id="text_pool_miner_description">
            <Trans
              i18nKey="Miner_activation.Description"
              components={{
                b: <b />,
              }}
              values={{ speed: formatHashrate(miner?.hps), duration: month }}
              shouldUnescape={true}
            />
          </Description>
        </Content>
        {miner?.hsh_reward && (
          <div className={styles.bonus}>
            <Icon iconName="hsh" width="30" height="31" />
            <p className={styles.bonus__text} data-test-id="text_pool_miner_bonus_hsh">
              <Trans
                i18nKey="Miner_activation.HSH"
                components={{ b: <b className={styles.green} /> }}
                values={{ HSH: getLocalAmount(miner?.hsh_reward) }}
              />
            </p>
          </div>
        )}
        {!miner?.hsh_reward && miner?.hps_bonus && (
          <div className={styles.bonus}>
            <Icon iconName="blue-gift" width={30} height={30} className={styles.bonus__icon} />
            <p className={styles.bonus__text} data-test-id="text_pool_miner_bonus">
              <Trans
                i18nKey="Bonus_Hps.Frame"
                components={{ b: <b className={styles.blue} /> }}
                values={{ bonus: formatHashrate(miner?.hps_bonus) }}
                shouldUnescape={true}
              />
            </p>
          </div>
        )}
        <Footer>
          <Button color="blue" fullWidth onClick={() => onClose(false)} data-test-id="btn_confirm_modal">
            {t('BTN_OK')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
