import React from 'react';
import { useTranslation } from 'react-i18next';

import { HOME_PATH } from '@/shared/lib/utils/links';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';

import styles from './NotFoundView.module.scss';

export const NotFoundView = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.notFound__card}>
          <div className={styles.notFound__body}>
            <Icon iconName="search" width={60} height={60} className={styles.notFound__icon} />
            <span className={styles.notFound__status}>{t('Error 404')}</span>
            <h1 className={styles.notFound__heading}>{t('NotFound.title')}</h1>
            <Button color="blue" as="a" href={HOME_PATH} className={styles.notFound__btn}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
