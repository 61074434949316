import cn from 'classnames';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useCurrentPlan, useHashrate } from '@/page/cart/store';
import { Tag } from '@/shared/lib/constants/tags';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { formatSpeed } from '@/shared/lib/utils/getFormatSpeed';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { Icon } from '@/shared/ui/icons';
import { Typography } from '@/shared/ui/typography';

import { Discount } from './discount';

import s from './total-price.module.scss';

type TotalPriceProps = {
  formattedPrice: string;
  userCurrency: string;
  currentPriceFiat?: string;
  formattedPriceRegular: string;
  fullSavePrice: string;
};

export const TotalPrice = ({
  formattedPrice,
  userCurrency,
  currentPriceFiat,
  formattedPriceRegular,
  fullSavePrice,
}: TotalPriceProps) => {
  const miningDuration = useSelector((state: RootState) => state.cart.miningDuration);
  const { defaultFiatCurrencyApproximately } = useSelector(selectUser);
  const hashrate = useSelector(useHashrate);
  const currentPlan = useSelector(useCurrentPlan);
  const { tags } = useSelector(selectUser);
  const { openModal } = useModalSet();
  const { t } = useTranslation();

  const openHshModal = useCallback(() => {
    openModal('MODAl_INFO_BONUS_HSH', { isServices: true });
  }, [openModal]);

  return (
    <div className={s.container}>
      <div className={s.totalPriceRows}>
        <div className={s.row}>
          <Typography
            variant="h5"
            weight="bold"
            style={{ display: 'flex', columnGap: '10px', fontSize: '16px', lineHeight: '20px' }}
          >
            <Icon iconName="cartSell" height={20} width={20} />
            {t('OrdDetails.Title')}
          </Typography>
        </div>
        <div className={s.row}>
          <div className={s.priceRow}>
            <Typography variant="h5" weight="bold">
              <span className={s.currentMiner} data-test-id="text_total_price_info">
                Pool Miner {hashrate && formatSpeed(hashrate)} ({miningDuration} {t('mo.')})
              </span>
            </Typography>
            <div className={s.priceContainer} data-test-id="text_total_price_now">
              <Typography variant="h3">
                {getLocalAmount(formattedPrice)} {userCurrency}
              </Typography>
            </div>
            {!!currentPriceFiat && (
              <div className={s.priceContainer}>
                <span className={s.priceUsd} data-test-id="text_total_price_fiat">
                  ~ {getLocalAmount(+currentPriceFiat, defaultFiatCurrencyApproximately?.code)}{' '}
                </span>
              </div>
            )}
          </div>
          <div className={s.priceContainer}>
            <Icon iconName="offer" height={20} width={20} />
            {t('Regular Price')}
            <p className={s.oldPrice} data-test-id="text_total_price_old">
              {getLocalAmount(+formattedPriceRegular)} {userCurrency}
            </p>
          </div>
        </div>
        <div className={s.plain}>
          <Icon iconName="percent" width={20} height={20} className={s.discount} />
          <span className={s.discounts}>{t('N.YourDiscount.Title')}</span>
          <Discount discount={currentPlan.discount} promoDiscount={0} data-test-id="text_total_price_discount" />
        </div>
        <div className={s.plain}>
          <Icon iconName="check-blue" width={20} height={20} />
          <span className={s.discounts}>{t('Purchase_scr.Total_Discount.Title')}</span>
          <p className={s.savePrice} data-test-id="text_total_price_save">
            {getLocalAmount(+fullSavePrice)} {userCurrency}
          </p>
        </div>
        {!currentPlan?.hsh_reward && currentPlan?.hps_bonus && currentPlan?.tags?.includes(Tag.BONUS_TAG_20) && (
          <div className={s.plain}>
            <Icon iconName="purple-gift-x2" width={20} height={20} />
            <div className={s.presentsWrapper}>
              <Trans i18nKey="hps_increment" />
              <span className={s.presentsPurple}> (x2)</span>
            </div>
            <Discount
              promoDiscount={0}
              discount={0}
              double={currentPlan?.hps_bonus}
              data-test-id="text_total_price_bonus"
            />
          </div>
        )}
      </div>
      {!currentPlan?.hsh_reward && currentPlan?.hps_bonus && currentPlan?.tags?.includes(Tag.BONUS_TAG) && (
        <div className={s.bonuses}>
          <div className={s.bonus}>
            <Icon iconName="blue-gift" width={20} height={20} />
            <span className={s.bonus__title}>{t('hps_increment')}</span>
            <Discount
              promoDiscount={0}
              discount={0}
              bonus={currentPlan?.hps_bonus}
              data-test-id="text_total_price_bonus"
            />
          </div>
        </div>
      )}
      {currentPlan?.hsh_reward && (
        <div className={s.bonuses}>
          <div className={s.bonus}>
            <Icon iconName="hsh" width={20} height={20} />
            <div>
              <span className={cn(s.bonus__title, s[`bonus__title--hsh`])} onClick={openHshModal}>
                {t('HashCoins')}
              </span>
              {currentPlan?.tags?.includes(Tag.BONUS_TAG_20) && !tags?.includes(Tag.FEB_25) && (
                <span className={s.bonus__double}> (x2)</span>
              )}
            </div>
            <Discount
              promoDiscount={0}
              discount={0}
              bonusHSH={currentPlan?.hsh_reward}
              data-test-id="text_total_price_bonus"
            />
            <Icon
              iconName={'question'}
              width={20}
              height={20}
              className={s.bonus__title_question}
              onClick={openHshModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};
