import { useContext } from 'react';

import { ModalContext } from '@/shared/lib/context/modal/modal-context';

export const useModal = () => {
  const modalRenderer = useContext(ModalContext);

  if (!modalRenderer) {
    throw new Error('`useCore` has to be used within <ModalContext.Provider>');
  }

  return modalRenderer;
};
