import React from 'react';

import { ExploreProductsNotification } from '@/features/crypto-tab-products';

import { ProductsList } from './products-list';

import styles from './crypto-tab-products-page.module.scss';

export const CryptoTabProductsPage = () => {
  return (
    <div className={styles.container}>
      <ExploreProductsNotification />
      <ProductsList />
    </div>
  );
};
