import { Menu } from '@/features/menu';

import styles from './menu-page.module.scss';

export const MenuPage = () => {
  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        <Menu />
      </div>
    </div>
  );
};
