const DAY = 24 * 60 * 60 * 1000;

export function isHidden(name: string): boolean {
  const text = localStorage.getItem(name + '.hide');
  if (!text) return false;
  if (text === 'true') return true;
  try {
    const time = parseInt(text);
    return time + DAY > Date.now();
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function setHidden(name: string): void {
  localStorage.setItem(name + '.hide', 'true');
}

export function setHiddenTemp(name: string): void {
  localStorage.setItem(name + '.hide', Date.now().toFixed());
}

export function watchLocalStorage(name: string, timeout: number): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const listener = (event: StorageEvent) => {
      if (event.storageArea === localStorage && event.key === name) {
        clearTimeout(timeout_id);
        window.removeEventListener('storage', listener);
        resolve(event.newValue);
      }
    };
    const timeout_id = setTimeout(() => {
      window.removeEventListener('storage', listener);
      reject('timeout');
    }, timeout);
    window.addEventListener('storage', listener);
    const did = localStorage.getItem(name);
    if (did) {
      clearTimeout(timeout_id);
      window.removeEventListener('storage', listener);
      resolve(did);
    }
  });
}
