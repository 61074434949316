import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { CartData } from '@/page/cart/store/cart-store.model';
import { rpc } from '@/shared/lib/backend/Rpc';
import { LAST_VISIT_RATING } from '@/shared/lib/constants/storage-key';
import { MiningPoolPlan } from '@/shared/lib/models';
import { TMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { RootState } from '@/shared/store/types';

function createResultObject(plans: MiningPoolPlan[]): CartData {
  const result: CartData = {
    hps: [],
    hsh_reward: [],
    hps_bonus: [],
    months: [],
    plans: [],
    maxAvailableHashrate: 0,
  };

  for (const item of plans) {
    const { hps, hsh_reward, hps_bonus, month } = item;

    if (!result?.hps.includes(hps)) {
      result?.hps.push(hps);
    }

    if (!result?.hsh_reward?.includes(hsh_reward || 0)) {
      result?.hsh_reward?.push(hsh_reward || 0);
    }

    if (!result?.hps_bonus?.includes(hps_bonus)) {
      result?.hps_bonus?.push(hps_bonus);
    }

    if (!result.months.includes(month)) {
      result.months.push(month);
    }

    result.plans.push(item);
  }

  result?.hps?.sort((a, b) => a - b);
  result?.hsh_reward?.sort((a, b) => a - b);
  result?.hps_bonus?.sort((a, b) => a - b);
  result?.months?.sort((a, b) => a - b);

  // // filter out hashrates that are bigger than user's hashrate sum
  // result.hps = result.hps.filter(hps => hps <= maxAvailableHashrate);

  return result;
}

export const makeOrderThunk = createAsyncThunk<
  string,
  { currency?: string },
  {
    state: RootState;
  }
>('cart/makeOrderThunk', async ({ currency }, { getState }) => {
  const state = getState();
  const hps = state.cart.hashrate;
  const month = state.cart.miningDuration;
  const utm = state.utm.utmObj;
  let meta = {};

  if (currency) {
    meta = { currency };
  }

  if (hps !== null && month !== null) {
    const response = await rpc.transmit('mining.pool.orders.create', { hps, month, utm, ...meta });
    const lastVisit = localStorage?.getItem(LAST_VISIT_RATING);
    const today = new Date();
    if (!lastVisit) {
      localStorage?.setItem(LAST_VISIT_RATING, today.toString());
    }

    return response.url;
  } else {
    Sentry.captureException(`Invalid order params: hps: ${hps}, month: ${month}`);
    throw new Error('Invalid order params');
  }
});

export interface cartStore {
  miningDuration: number | null;
  hashrate: number | null;
  data: CartData | null;
  status: 'idle' | 'loading' | 'error' | 'successful';
  errorMessage: string | null;
  list: TMiner[];
}

const initialState: cartStore = {
  miningDuration: null,
  hashrate: null,
  data: null,
  list: [],
  status: 'idle',
  errorMessage: null,
};

const cartStoreSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setMiningDuration(state, { payload }: PayloadAction<{ miningDuration: number }>) {
      state.miningDuration = payload.miningDuration;
    },
    setHashrate(state, { payload }: PayloadAction<{ hashrate: number }>) {
      state.hashrate = payload.hashrate;
    },
    setCartList(state, action) {
      state.list = action.payload;
    },
    setData(state, { payload }: PayloadAction<{ data: MiningPoolPlan[] | null | undefined }>) {
      // Fallback to an empty array if data is null or undefined
      const data = payload?.data || [];

      // Create a result (remapped) object from the data
      const updateData = createResultObject(data);

      const hashrateToUse =
        state.hashrate && updateData?.hps?.includes(state?.hashrate)
          ? state?.hashrate
          : (updateData?.hps?.[8] ?? updateData?.hps?.[0]);

      const durationToUse =
        state.miningDuration && updateData?.months?.includes(state.miningDuration)
          ? state?.miningDuration
          : (updateData?.months?.[2] ?? updateData?.months?.[0]);

      state.data = updateData;
      state.hashrate = hashrateToUse;
      state.miningDuration = durationToUse;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeOrderThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(makeOrderThunk.fulfilled, (state) => {
        state.status = 'successful';
      })
      .addCase(makeOrderThunk.rejected, (state) => {
        state.status = 'error';
      });
  },
});

// isCryptoEnable selector
export const useIsCryptoEnable = (state: RootState): boolean => {
  const data = state.cart.data?.plans;
  return Array.isArray(data) && data.length > 0;
};

export const useHashrate = (state: RootState) => state.cart.hashrate as number;

export const useHashrates = (state: RootState) => (state.cart.data?.hps as number[]) || [];

export const useMiningDurations = (state: RootState) => state.cart.data?.months as number[];

export const useCurrentPlan = (state: RootState) => {
  const hashrate = state.cart.hashrate as number;
  const duration = state.cart.miningDuration as number;
  return state.cart.data?.plans.find((plan) => plan.hps === hashrate && plan.month === duration) as MiningPoolPlan;
};

export const useMaxAvailableHashrate = (state: RootState) => state.cart.data?.maxAvailableHashrate as number;

export const useSpecialPackages = (state: RootState) => {
  return state.cart.data?.plans
    .filter((plan) => plan.tags.indexOf('special') !== -1)
    .sort((a, b) => a?.hps - b?.hps) as MiningPoolPlan[];
};

export const { setData, setHashrate, setMiningDuration, setCartList } = cartStoreSlice.actions;
export const cartReducer = cartStoreSlice.reducer;
