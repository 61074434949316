import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@/features/theme';
import { ModalContextProvider } from '@/shared/lib/context/modal';
import { ModalRender } from '@/shared/lib/context/modal/modal-render';
import { store } from '@/shared/store/store';
import ErrorBoundary from '@/shared/ui/error-boundary/error-boundary';
import { ShareSocialContextProvider } from '@/shared/ui/share-social';

export const Providers = ({ children }: PropsWithChildren) => (
  <Provider store={store}>
    <ThemeProvider>
      <ModalContextProvider>
        <BrowserRouter basename={window.app_config.basename}>
          <ErrorBoundary>
            <ShareSocialContextProvider endpoint={`${window.app_config.baseAPIURL}share`}>
              {children}
            </ShareSocialContextProvider>
          </ErrorBoundary>
          <ModalRender />
        </BrowserRouter>
      </ModalContextProvider>
    </ThemeProvider>
  </Provider>
);
