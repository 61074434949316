import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CART_PATH } from '@/shared/lib/utils/links';
import { selectUser } from '@/shared/store/user/user.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout from '@/shared/ui/modal/modal';

export const ErrorFundsWithdrawalModal = ({
  onClose,
  isOpen,
  isMining,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  isMining: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currency: userCurrency } = useSelector(selectUser);
  const { IconContainer, Container, Content, Close, Title, Description, Footer } = ModalLayout;

  return (
    <ModalLayout isOpen={isOpen} onClose={() => onClose(false)} id="error-funds-withdrawal-modal">
      <IconContainer>
        <Icon iconName="attention" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>
            {<Trans i18nKey={t('Error.NotEnoughBTC.Title')} values={{ currency: isMining ? userCurrency : 'HSH' }} />}
          </Title>
          <Description>
            {<Trans i18nKey={t('Error.NotEnoughBTC.Descr')} values={{ currency: isMining ? userCurrency : 'HSH' }} />}
          </Description>
        </Content>
        <Footer>
          <Button color="blue" fullWidth onClick={() => navigate(CART_PATH)} data-test-id="link_mining">
            <Icon iconName="plus" width="20" height="20" />
            {t('Add Miner')}
          </Button>
          <Button fullWidth variant="filled" color="grey" onClick={() => onClose(false)} data-test-id="btn_exit">
            {t('H.Skip_modal.Btn.Cancel')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
