import * as Sentry from '@sentry/browser';

import { build, version } from '@/shared/lib/constants/version';

let platform: string = 'web';
let appId: string = 'pool';
let v: string = '1.0.0';
const av: string = `${version}.${build}`;

if (window.ct) {
  platform = window?.ct?.platform;
  appId = 'net.ctpool';
  v = window.ct.appVersion || v;
} else if (window.TelegramWebviewProxy || window.Telegram?.WebView?.isIframe) {
  platform = 'telegram';
  appId = 'pool.telegram.ct';
  v = window.Telegram?.WebApp?.version || v;
}

Sentry.init({
  dsn: window.app_config?.sentryDSN as string,
  release: av,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0,
  initialScope: {
    tags: {
      platform: platform,
      appId: appId,
      version: v,
      app_version: av,
    },
  },
});

window.addEventListener('error', function (error) {
  try {
    if (error instanceof ErrorEvent) {
      Sentry.captureException(error.message);
    } else {
      Sentry.captureException(error);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
});

window.onunhandledrejection = (e) => {
  Sentry.captureException(e.reason);
};
