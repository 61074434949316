import { ResponseError } from './response-error';

export async function checkResponse(resp: Response) {
  if (resp.ok) {
    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return resp.json();
    }
    return resp.text();
  }

  let errorData;
  const text = await resp.text();
  try {
    errorData = { code: resp.status, message: resp.statusText, ...JSON.parse(text) };
  } catch (e) {
    console.error(e);
    errorData = new ResponseError(text, resp.status);
  }

  return Promise.reject(errorData);
}
