import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

interface CancelWithdrawalProps extends ModalCommonProps {
  amount: string;
  currency: string;
  onCancel: () => Promise<void>;
}

export const CancelWithdrawalModal = ({ onClose, isOpen, amount, onCancel, currency }: CancelWithdrawalProps) => {
  const { Close, IconContainer, Container, Content, Title, Description, Footer } = ModalLayout;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await onCancel();
  }, [onCancel]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="cancel-withdrawal">
      <IconContainer>
        <Icon iconName="error" width={80} height={80} />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Cancel_withdr.Title')}</Title>
          <Description>
            <Trans
              i18nKey={t('Cancel_withdr.Descr')}
              values={{ amount: getLocalAmount(amount), currency }}
              components={{ b: <strong /> }}
            />
          </Description>
        </Content>
        <Footer>
          <Button
            loading={loading}
            borderRadius={false}
            disabled={loading}
            onClick={handleClick}
            color="blue"
            fullWidth
            data-test-id="btn_confirm_modal"
          >
            {t('Cancel_withdr.BTN')}
          </Button>
          <Button
            disabled={loading}
            borderRadius={false}
            color="grey"
            fullWidth
            onClick={() => onClose(false)}
            data-test-id="btn_close_footer_modal"
          >
            {t('Cancel_withdr.BTN.NotNow')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
