import { useTranslation } from 'react-i18next';

import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import { useMakeOrderMutation } from '@/page/cart/ui/make-order-button/use-make-order-mutation';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { Button } from '@/shared/ui/button';

export const MakeOrderButton = () => {
  const { t } = useTranslation();
  const { handleClick, status } = useMakeOrderMutation();
  const isLoading = status === 'loading';
  const { userCurrency, formattedPrice } = useCurrentPlanPriceInfo();

  return (
    <Button
      fullWidth
      color="green"
      onClick={handleClick}
      disabled={isLoading}
      loading={isLoading}
      data-test-id="btn_subscribe"
    >
      {t('BUY')}
      {' / '}
      {getLocalAmount(formattedPrice)} {userCurrency}
    </Button>
  );
};
