import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import { ProtectedRoute } from '@/app/protected-route';
import { BonusProgramPage } from '@/page/bonus-program/ui/crypto-tab-products-page';
import { AddPoolMinerPage } from '@/page/cart/ui/';
import { CryptoTabProductsPage } from '@/page/crypto-tab-products/ui/crypto-tab-products-page';
import { HistoryPage } from '@/page/history';
import { Home } from '@/page/home/home';
import { LoginPage } from '@/page/login';
import { MenuPage } from '@/page/menu';
import { ModalDebug } from '@/page/modal-debug/modal-debug';
import { NcwalletConnectPage } from '@/page/ncwallet-connect';
import { NotFound } from '@/page/not-found/not-found';
import { PoolMiningPage } from '@/page/pool-mining/pool-mining-page';
import { Review } from '@/page/review/ui/review';
import { SwitchBalancePage } from '@/page/switch-balance';
import { TutorialPage } from '@/page/tutorial';
import { WithdrawPage } from '@/page/withdraw';
import { WithdrawalDetailsPage } from '@/page/withdrawal-details';
import { usePageTracking } from '@/shared/lib/metrics';
import {
  BONUS_PROGRAM,
  CART_PATH,
  CRYPTO_TAB_PRODUCTS_PATH,
  CRYPTO_TAB_REVIEW,
  HISTORY_PATH,
  HOME_PATH,
  LOGIN_PATH,
  MENU_PATH,
  MODAL_DEBUG,
  NC_WALLET_CONNECT,
  POOL_MINING,
  SWITCH_BALANCE_PATH,
  TUTORIAL_PATH,
  WITHDRAW_PATH,
} from '@/shared/lib/utils/links';
import { selectUser } from '@/shared/store/user/user.slice';
import { AccountBlockedView } from '@/shared/ui/account-blocked-view/account-blocked-view';
import { Layout } from '@/shared/ui/layout/layout';
import { Navigation } from '@/shared/ui/navigation/navigation';

export const AppRouter = () => {
  const { banned } = useSelector(selectUser);
  const location = useLocation();
  const { t } = useTranslation();

  usePageTracking();

  if (banned) {
    return <AccountBlockedView isOpen={banned} onClose={() => console.log('account banned')} />;
  }

  return (
    <Routes key={location.pathname} location={location}>
      <Route>
        <Route
          path="*"
          element={
            <Layout navigation={<Navigation />}>
              <NotFound />
            </Layout>
          }
        />
        <Route
          path={HOME_PATH}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />}>
                <Home />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={POOL_MINING}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />} title={t('Pool Mining')}>
                <PoolMiningPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={CART_PATH}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />}>
                <AddPoolMinerPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${WITHDRAW_PATH}/:MiningOrBonus?`}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />}>
                <WithdrawPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={CRYPTO_TAB_PRODUCTS_PATH}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />} title={t('Products.General_title')}>
                <CryptoTabProductsPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={BONUS_PROGRAM}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />} title={t('BB_Hsh.BonusProg.Title')}>
                <BonusProgramPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={CRYPTO_TAB_REVIEW}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />} title={t('Select_Reviews.Btn')}>
                <Review />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={MENU_PATH}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />}>
                <MenuPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={HISTORY_PATH}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />} title={t('Payment History')}>
                <HistoryPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${HISTORY_PATH}/:id`}
          element={
            <ProtectedRoute>
              <Layout title={t('TransactionDetails')} navigation={<Navigation />}>
                <WithdrawalDetailsPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={SWITCH_BALANCE_PATH}
          element={
            <ProtectedRoute>
              <Layout title={t('Balance_currency.Main_title')} navigation={<Navigation />}>
                <SwitchBalancePage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={NC_WALLET_CONNECT}
          element={
            <ProtectedRoute>
              <Layout title={t('Withdrawal.Connect_NCW.Pop_up.TItle')} navigation={<Navigation />}>
                <NcwalletConnectPage />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={MODAL_DEBUG}
          element={
            <ProtectedRoute>
              <Layout navigation={<Navigation />}>
                <ModalDebug />
              </Layout>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={LOGIN_PATH}
        element={
          <Layout>
            <LoginPage />
          </Layout>
        }
      />
      <Route path={TUTORIAL_PATH} element={<TutorialPage />} />
    </Routes>
  );
};
