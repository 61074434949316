import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { checkE } from '@/shared/lib/utils/checE';
import { formatBalance } from '@/shared/lib/utils/units';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';

export const useBalanceInfo = () => {
  const { pool_balance, currencies, mining_currencies } = useSelector((store: RootState) => store.balance);
  const { withdrawalsCreated } = useSelector((store: RootState) => store.withdrawal);
  const rate = useSelector((store: RootState) => store.rate);
  const { currency: userCurrency } = useSelector(selectUser);

  const currentCurrencyObj = useMemo(
    () => mining_currencies?.find((cur) => cur?.symbol === userCurrency),
    [mining_currencies, userCurrency]
  );

  const hsh_currency = useMemo(() => currencies?.find((cur) => cur?.symbol === 'HSH'), [currencies]);

  const activeWithdrawalsSum = useMemo(
    () =>
      withdrawalsCreated.reduce((sum, { amount, currency, status }) => {
        if (currency === userCurrency && status !== WithdrawalStatus.Canceled) {
          const parsedAmount = parseFloat(amount);
          return sum + (isNaN(parsedAmount) ? 0 : parsedAmount);
        }
        return sum;
      }, 0),
    [withdrawalsCreated, userCurrency]
  );

  const formatingBalance = useCallback(
    (balance: string | number) => {
      if (!currentCurrencyObj) return '0.00';
      return +balance >= +currentCurrencyObj.unit
        ? formatBalance(balance, currentCurrencyObj.unit)
        : currentCurrencyObj.unit.toString().replace(/1$/, '0');
    },
    [currentCurrencyObj]
  );

  const formattedTotalBalance = useMemo(
    () => formatingBalance(checkE(+pool_balance?.total - activeWithdrawalsSum)),
    [pool_balance, activeWithdrawalsSum]
  );

  const formattedPoolBalance = useMemo(() => formatingBalance(+pool_balance?.pool), [pool_balance]);

  const getUsdBalance = useCallback(
    (balance: number | string) => (balance ? (+balance * +rate.crypto2usdRate).toFixed(2) : '0.00'),
    [rate.crypto2usdRate]
  );

  const usdBalance = getUsdBalance(formattedTotalBalance);
  const usdPoolBalance = getUsdBalance(formattedPoolBalance);

  return {
    formattedTotalBalance,
    formattedPoolBalance,
    usdBalance,
    usdPoolBalance,
    currentCurrencyObj,
    hsh_currency,
    userCurrency,
    mining_currencies,
    exact_total: pool_balance?.exact_total,
  };
};
