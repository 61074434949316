import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentTheme } from '@/features/theme';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useModalVerify } from '@/shared/lib/hooks/useModalVerify';
import { isValidEmail } from '@/shared/lib/utils/units';
import { AppDispatch } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { confirmEmail, selectWithdrawal } from '@/shared/store/withdrawal/withdrawal.slice';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import ModalLayout, { ModalCommonProps } from '@/shared/ui/modal/modal';

import styles from './modal-email-verify.module.scss';

export const ModalEmailVerify = ({ onClose, isOpen }: ModalCommonProps) => {
  const { email } = useSelector(selectUser);
  const theme = useSelector(selectCurrentTheme);
  const withdrawal = useSelector(selectWithdrawal);
  const [error, setError] = useState<string | null>(null);
  const [buttonTimeout, setButtonTimeout] = useState(false);
  const isLoading = withdrawal.sendEmailStatus === 'loading';
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const { t } = useTranslation();
  const { IconContainer, Close, Container, Content, Title, Description, Footer } = ModalLayout;
  const { countdown, newCountdown, toastId, newToastId, value, changeValue } = useModalVerify();

  const handleClick = () => {
    if (isValidEmail(value)) {
      dispatch(confirmEmail({ email: value, t, toastId, newToastId, countdown, newCountdown, openModal }));
      setButtonTimeout(true);
      setTimeout(() => {
        setButtonTimeout(false);
      }, 3000);
    } else {
      setError(t('InvalidEmail'));
    }
  };

  useEffect(() => {
    changeValue(email);
  }, [changeValue]);

  return (
    <ModalLayout onClose={() => onClose(false)} isOpen={isOpen} id="mail-verify">
      <IconContainer>
        <Icon
          iconName={theme === 'light' ? 'email-verify-connect' : 'email-verify-connect-dark'}
          width={80}
          height={80}
        />
      </IconContainer>
      <Close onClick={() => onClose(false)} />
      <Container>
        <Content>
          <Title>{t('Mail_conf.Enter.Title')}</Title>
          <Description>{t('Mail_conf.Enter.Descr')}</Description>
        </Content>
        <div className={styles.container}>
          <Icon iconName="email" width={20} height={20} className={styles.container__icon} />
          <input
            id="email"
            type="email"
            className={cn(styles.container__input, error && styles.error)}
            value={value}
            placeholder={t('Pos_Vibes.Enter_mail')}
            onChange={(e) => changeValue(e.target.value)}
          />
          {error && <span className={styles.container__error}>{error}</span>}
        </div>
        <Footer>
          <Button
            color="blue"
            fullWidth
            onClick={handleClick}
            disabled={buttonTimeout || !isValidEmail(value)}
            loading={isLoading}
            data-test-id="btn_confirm_modal"
          >
            {t('Confirm')}
          </Button>
        </Footer>
      </Container>
    </ModalLayout>
  );
};
