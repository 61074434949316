import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGoogleHashrate, useGoogleIsAvailable, useGooglePlans } from '@/page/cart/store/cart-google-store';
import { SubscriptionHashrate } from '@/page/cart/ui/subscription-hashrate';
import { SubscriptionMiningDuration } from '@/page/cart/ui/subscription-mining-duration';
import { SubscriptionTotalPrice } from '@/page/cart/ui/subscription-total-price';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';

import s from './add-google-miner.module.scss';

interface AddGoogleMinerProps {
  handleSwitchTab: () => void;
  isCryptoEnable: boolean;
}

export const AddGoogleMiner = ({ handleSwitchTab, isCryptoEnable }: AddGoogleMinerProps) => {
  const { t } = useTranslation();
  const plans = useSelector(useGooglePlans);
  const hps = useSelector(useGoogleHashrate);
  const isAvailable = useSelector(useGoogleIsAvailable);
  const [isLastInRange, setIsLastInRange] = useState(false);
  const maxHpsPlan = useMemo(
    () => plans?.reduce((maxPlan, p) => (p.hps > maxPlan.hps ? p : maxPlan), plans[0]),
    [plans]
  );
  const { openModal } = useModalSet();

  useEffect(() => {
    maxHpsPlan && setIsLastInRange(!!(hps && hps === maxHpsPlan?.hps));
  }, [hps, maxHpsPlan]);

  useEffect(() => {
    if (!plans?.length) {
      openModal('MODAL_ERROR', { isServices: true, error: { message: 'Products not found' } });
    }
  }, [plans]);

  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <div className={s.hashrateContainer}>
          <SubscriptionHashrate />
          {isLastInRange && isCryptoEnable && (
            <div className={s.higher} onClick={() => handleSwitchTab()}>
              {t('HigherFasterStronger')}
            </div>
          )}
          <SubscriptionMiningDuration />
        </div>
        {!isAvailable ? <SubscriptionTotalPrice /> : null}
      </div>
    </div>
  );
};
