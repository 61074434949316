import { Text } from '@wcode/ui-kit';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setHashrate, useHashrate, useHashrates } from '@/page/cart/store';
import { useGoogleHashrate } from '@/page/cart/store/cart-google-store';
import { AppDispatch } from '@/shared/store/types';
import { Card } from '@/shared/ui/kit/card';
import { CurrencySwitcher } from '@/shared/ui/kit/currency-switcher';
import { CurrencySwitcherType } from '@/shared/ui/kit/currency-switcher/currency-switcher';
import { Duration } from '@/shared/ui/kit/duration';
import { OrderDetails } from '@/shared/ui/kit/order-details';

import { HashrateSpeed } from '../hashrate-speed';

import s from './add-miner.module.scss';

interface AddGoogleMinerProps {
  handleSwitchTab: Dispatch<SetStateAction<CurrencySwitcherType>>;
  activeTab: CurrencySwitcherType;
}

export const AddMinerVariant1 = ({ handleSwitchTab, activeTab }: AddGoogleMinerProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const cryptoHashrate = useSelector(useHashrate);
  const cryptoHashrates = useSelector(useHashrates);
  const mobileHashrate = useSelector(useGoogleHashrate);
  const onSwitchToCrypto = () => {
    if (cryptoHashrate < mobileHashrate) {
      const hashrate = cryptoHashrates.find((h) => h > mobileHashrate);
      hashrate && dispatch(setHashrate({ hashrate }));
    }
    handleSwitchTab('crypto');
  };

  return (
    <div className={s.container}>
      <Card>
        <div>
          <Text type={'heading'} size={'h5'} text={t('Buy_scr.Payment_Method.Title')} className={s.header__text} />
          <Text type={'body'} size={'s'} text={t('Buy_scr.Payment_Method.Description')} />
        </div>
        <CurrencySwitcher onChange={handleSwitchTab} activeTab={activeTab} />
      </Card>
      <HashrateSpeed activeTab={activeTab} onSwitchToCrypto={onSwitchToCrypto} />
      <Duration activeTab={activeTab} />
      <OrderDetails activeTab={activeTab} />
    </div>
  );
};
